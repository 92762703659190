import React, {useContext, useEffect} from 'react'
import AppContext from "../context/AppContext";
//
import {Empty} from "antd";


/**
 *
 * @param $app
 * @constructor
 */
const NoPage = () => {

    const $app = useContext(AppContext);

    useEffect(() => {
        $app.content.setTitle('ОШИБКА')
        $app.content.setTitle('404', true)
    }, [$app]);

    return (
        <div className="not-found-error-page content-block-gray">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
        </div>
    );
}

export default NoPage;