import Component from "@base/Component";
import CustomTabs from "@base/widgets/CustomTabs";
import SocialLinks from "@base/widgets/SocialLinks";
import {Row, Col} from "antd";
import Mission from "./mission";
import About from "./about";
import Structure from "./structure";
import Docs from "./docs";
import Tasks from "./tasks";
import Values from "./values";

class Index extends Component {


    setMap = (state = true) => {
        if (!state)
            return this.app().set({beforeFooter: ''})
        this.app().set({
            beforeFooter: (
                <Row className={'about_map_container'}>
                    <Col xs={{span: 24}} sm={{span: 0}}>
                        {this.renderImage('/about/map-sm.png', {width: '100%', className: 'map-sm'})}
                    </Col>
                    <Col xs={{span: 0}} sm={{span: 24}}>
                        <div className="map" style={{
                            backgroundImage: 'url("/img/about/map.png")'
                        }}>
                            <div className="content-container">
                                <Row>
                                    <Col span={24} style={{zIndex: 1}}>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <h2>
                                            {this.t('Контакты')}
                                        </h2>
                                        <b>
                                            {this.t(this.app().data.text.contact.address)}
                                        </b>
                                        <span className="font-slim">
                                            &nbsp; {this.t(this.app().data.text.contact.addressV)}
                                        </span>
                                        <br/>

                                        <b>
                                            {this.t(this.app().data.text.contact.tel)}
                                        </b>
                                        <span className="font-slim">
                                            &nbsp; {this.t(this.app().data.text.contact.telV)}
                                        </span>

                                        <br/>
                                        <b>
                                            {this.t(this.app().data.text.contact.email)}
                                        </b>
                                        <span className="font-slim">
                                            &nbsp; {this.t(this.app().data.text.contact.emailV)}
                                        </span>
                                        <br/>
                                        <br/>

                                        <div className={'social-links-gray'}>
                                            <SocialLinks/>
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            )
        })
    }

    data = {
        tabMenu: '',
        tabs: [
            {
                label: this.t('О фонде'),
                title: (
                    <>
                        {this.renderTitle('О фонде')}
                    </>
                ),
                content: <About/>,
            },
            {
                label: this.t('Наша Миссия'),
                title: (
                    <>
                        {this.renderTitle('Наша Миссия')}
                    </>
                ),
                content: (
                    <div className="mission">
                        <Mission/>
                    </div>
                ),
            },
            {
                label: this.t('Наши цели и задачи'),
                title: (
                    <>
                        {this.renderTitle('Наши цели и задачи')}
                    </>
                ),
                content: (
                    <div className="tasks">
                        <Tasks/>
                    </div>
                ),
            },
            {
                label: this.t('Наши Ценности'),
                title: (
                    <>
                        {this.renderTitle('Наши Ценности')}
                    </>
                ),
                content: (
                    <div className="values">
                        <br/>
                        <Values/>
                    </div>
                ),
            },
            {
                label: this.t('Уставные документы'),
                title: (
                    <>
                        {this.renderTitle('Уставные документы')}
                    </>
                ),
                content: (
                    <div className="docs">
                        <Docs/>
                    </div>
                ),
            },
            {
                label: this.t('Структура фонда'),
                title: (
                    <>
                        {this.renderTitle('Совет Фонда')}
                    </>
                ),
                content: (
                    <div className="structure">
                        <Structure/>
                    </div>
                ),
            },
        ]
    }

    componentWillUnmount() {
        this.setMap(false)
    }

    render() {

        let params = {
            title: true,
            menuClass: 'about',
            containerClass: '',
            titleClass: 'tac',
            extractMenu: {
                component: this,
                state: 'tabMenu',
            },
            onChangeTab: (tab, component) => (tab === 1 ? this.setMap() : this.setMap(false)),
            onMount: (component) => {
                if (component.data.tab === 1)
                    this.setMap()
            }
        }

        let tab = <CustomTabs data={this.data.tabs} {...params}/>

        return (
            <>
                <div className={'cb'}>
                    {this.data.tabMenu.menu}
                </div>

                {tab}
            </>
        )
    }
}

export default Index;