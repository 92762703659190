import React from 'react'
import AppContext from "../context/AppContext";
import {Row, Col} from 'antd'


import {Layout, Menu} from 'antd';
import {
    //FundProjectionScreenOutlined,
    SettingOutlined,
    UserOutlined,
} from '@ant-design/icons';

const {Sider} = Layout;
const {SubMenu} = Menu;

/**
 * main menu component
 * @returns {JSX.Element}
 * @constructor
 */
class BaseMenu extends React.Component {

    static contextType = AppContext;


    /**
     * @type {function(): AdminBase}
     */
    app = () => {
        return this.context
    }

    constructor(props) {
        super(props);
        this.state = {collapsed: (localStorage.getItem('mainmenuCollapsed') === '1')};
    }

    componentDidMount() {
        this.setContentWidth()
    }

    setContentWidth = (c = null) => {
        if (this.props.caller) {
            let $v = c !== null ? c : this.state.collapsed
            this.props.caller.setState({
                width: (!$v ? 200 : 50)
            })
        }
    }

    /**
     * toggle main menu collapsed state
     * @param c
     */
    onCollapse = (c) => {
        localStorage.removeItem('mainmenuCollapsed');
        localStorage.setItem('mainmenuCollapsed', (c ? '1' : '2'));
        this.setState({collapsed: c});
        this.setContentWidth(c)
    };

    /**
     * render available actions (retrieved from API server according to RoleBasedAccess rules)
     * @returns {unknown[]}
     */
    renderMenuItems = () => {
        let $app = this.app()
        let $items = $app.user.attributes.compartments;
        let ret = Object.keys($items).map((keyName, i) => {
                return <Menu.Item onClick={() => $app.route.to($items[keyName]['url'])}
                                  key={$items[keyName]['url']}>{$items[keyName]['label']}</Menu.Item>
            }
        )

        return ret
    }


    render = () => {
        let $app = this.app()
        let $currentAction = '/' + $app.route.controller;
        let $selected = [$currentAction];
        const $logoIcon = <SettingOutlined/>;

        return (
            // <Sider collapsible collapsed={this.state.collapsed} onCollapse={(c) => this.onCollapse(c)} style={{
            //     overflow: 'auto',
            //     height: '100vh',
            //     position: 'fixed',
            //     left: 0,
            // }}>
            // <div style={{background:'#001529'}}>
            <div className={'main-menu-content'} style={{background:'#fff'}}>
                <Row>
                    <Col {...this.props.caller.grid}>
                        <Menu theme="light" defaultOpenKeys={[]/*$selected*/} selectedKeys={$selected}
                              defaultSelectedKeys={$selected}
                              mode="horizontal">

                            {/*<div onClick={() => $app.route.to('/dashboard')} className="logo">*/}
                            {/*    {$logoIcon}*/}
                            {/*</div>*/}

                            {this.renderMenuItems()}

                            <SubMenu style={{float:'right'}} key="/profile" defaultOpenKeys={$selected}
                                     // icon={<UserOutlined/>}
                                     title={$app.user.getFullName()}>
                                <Menu.Item onClick={() => $app.route.to('/profile')}
                                           key="/profile">Профиль</Menu.Item>
                                <Menu.Item onClick={() => $app.user.logOut()} key="10">Выход</Menu.Item>
                            </SubMenu>

                        </Menu>
                    </Col>
                </Row>
                {/*</Sider>*/}
            </div>
        );

    }
}

export default BaseMenu;