import Component from "@base/Component";

class View extends Component {

    data = this.props.data;

    renderContent = () => {
        let id = this.props.id

        return ''
    }

    render() {
        return this.renderContent()
    }

}

export default View;