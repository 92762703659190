import React from 'react'
// import AppContext from "../../../context/AppContext";
import Form from "./Form";
import AppContext from "../../../context/AppContext";

class Create extends React.Component {

    static contextType = AppContext;


    constructor(props) {
        super(props);
        this.state = false
    }


    componentDidMount() {
        /** @type {AdminBase} */
        let $app = this.context;
        let $path = '/' + $app.route.controller + '/meta'
        $app.api.get($path, (r) => {
            let data = r
            data['response'] = r;
            this.setState({
                res: data
            })
        })
    }

    render() {
        return <Form caller={this}/>
    }

}

export default Create;