import Component from "@base/Component";
import Index from "@pages/about/Index"


class About extends Component {

    data = {
        text:{
            title:'О фонде'
        }
    }


    render() {
        return (
            <div className={'about-page'}>
                <Index/>
            </div>
        )
    }

}

export default About;