import Component from "@base/Component";

class Logo extends Component {


    data = {
        img: '/default/logo.png',
        text: this.t('Спасибо за ваше \n участие и помощь!'),
    }

    render() {
        return (
            <div {...this.props}>
                <div className="logo-container-w">
                    <div className="img">
                        {this.renderImage(this.props.img ? this.props.img : this.data.img)}
                    </div>
                    <div className="text">
                        {this.props.text ? this.props.text : this.data.text}
                    </div>
                </div>
            </div>
        )
    }
}

export default Logo;