import Component from "@base/Component";
import {Swiper, SwiperSlide} from 'swiper/react';

import SwiperCore, {
    Virtual,
    Keyboard,
    Mousewheel,
    Navigation,
    Pagination,
    Scrollbar,
    Parallax,
    Zoom,
    Lazy,
    Controller,
    A11y,
    History,
    HashNavigation,
    Autoplay,
    EffectFade,
    EffectCube,
    EffectFlip,
    EffectCoverflow,
    Thumbs
} from "swiper"

SwiperCore.use([Virtual, Keyboard, Mousewheel, Navigation, Pagination, Scrollbar, Parallax, Zoom, Lazy, Controller, A11y, History, HashNavigation, Autoplay, EffectFade, EffectCube, EffectFlip, EffectCoverflow, Thumbs])


// https://swiperjs.com/swiper-api

class Carousel extends Component {

    textCarouselTitle = 'Наши подопечные';

    renderItem = (nr) => {


        let footerConf = {
            xs: {span: 24}, md: {span: 24}, lg: {span: 9}, xl: {span: 8}
        }

        return (
            <SwiperSlide key={nr}>

                <div className={'carousel-item tac'}>
                    {this.renderImage('child-1.svg')}
                    <h3>
                        {this.t('Мокринская Стася')}
                    </h3>
                    <p>
                        180 000 {this.getRubSign()} / 2 571 €
                    </p>
                </div>
            </SwiperSlide>
        )
    }

    componentDidMount() {


        let between = 25;

        let defaults = {
            slidesPerView: 2,
            slidesPerColumn: 2,
            spaceBetween: between,
            slidesPerGroup: 2,
            slidesPerColumnFill: 'row'
        }

        let conf = {
            effect: 'slide', //'slide' | 'fade' | 'cube' | 'coverflow' | 'flip',
            loop: false,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            breakpoints: {
                256: {
                    slidesPerView: 1,
                    slidesPerColumn: 2,
                    spaceBetween: between,
                    slidesPerGroup: 1,
                    slidesPerColumnFill: 'row'
                },
                576: defaults,
                768: defaults,
                992: {
                    spaceBetween: between,
                    slidesPerView: 3,
                },
                1200: {
                    spaceBetween: between,
                    slidesPerView: 4,
                }
            },
            // onSlideChange: () => console.log('slide change'),
            // onSwiper: (swiper) => console.log(swiper),
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            scrollbar: false,//{draggable: true}
        }

        let items = [];

        for (let i = 0; i < 15; i++) {
            items.push(this.renderItem(i))
        }

        this.content = (
            <div className={'report-page-carousel'}>
                <h2>
                    {this.t(this.textCarouselTitle)}
                </h2>
                <Swiper {...conf}>
                    {items}
                    <div className={'navigation-container'}>
                        <div className={'navigation-block'}>
                            <div className={'swiper-pagination'}></div>
                            <div className={'swiper-button-prev'}></div>
                            <div className={'swiper-button-next'}></div>
                        </div>
                    </div>
                </Swiper>
            </div>
        );
    }

    content = ''

    render() {
        return this.content;
    }
}

export default Carousel;