import React from 'react'
//
import Table from "../../template/Table";

const Index = () => {

    let $conf = {
        optionFields: false,
        excludeFields: ['tmpPassword'],
        checkActions: ['change-permission'],
    }

    return <Table {...$conf}/>

}

export default Index;