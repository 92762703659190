import Component from "@base/Component";
import {Col, Row} from "antd";


class TableInfo extends Component {

    data = {
        r: [
            {
                label: this.t('Наименование организации'),
                value: this.t('БФ «Мир Здоровых Людей»')
            },
            {
                label: this.t('Юридический адрес и Почтовый адрес'),
                value: this.t('main_address'),
            },
            {
                label: this.t('ОГРН'),
                value: this.t('1187700021442'),
            },
            {
                label: this.t('ИНН / КПП'),
                value: this.t('9705126200 / 770501001'),
            },
            {
                label: this.t('Наименование банка'),
                value: this.t('ПАО СБЕРБАНК  г.Москва'),
            },
            {
                label: this.t('БИК банка'),
                value: this.t('044525225'),
            },
            {
                label: this.t('Корреспондентский счет'),
                value: this.t('30101810400000000225'),
            },
            {
                label: this.t('Расчетный счет организации'),
                value: this.t('40703810638000013164'),
            },
            {
                label: this.t('Ответственное лицо (Директор)'),
                value: this.t('Хитрова Наталья Ивановна'),
            },
        ]
    }

    renderTable = () => {
        let data = this.props.data
        if (!data || data.length === 0)
            data = this.data.r

        let grid = {xs: {span: 24}, sm: {span: 12}}

        let info = data.map((v, i) => {
            return (
                <Row key={i} className={'table-info-widget-item'}>
                    <Col {...grid} className={'label'}>
                        {v.label}
                    </Col>
                    <Col {...grid} className={'value'}>
                        {v.value}
                    </Col>
                </Row>
            )
        })

        return (
            <div className="table-info-widget">
                {info}
            </div>
        )
    }

    render() {
        return this.renderTable()
    }
}

export default TableInfo;