import Component from "@base/Component";
import {Row, Col} from "antd";


class Partners extends Component {

    t1 = 'Наши партнеры';

    renderItem = (nr) => {
        let conf = {
            xs: {span: 12},
            sm: {span: 6},
            key: nr
        };

        let links = {
            1: 'https://wr.market/',
            2: 'https://vertera.org/',
            3: 'https://www.detis.ru/saved/',
            4: 'https://dobro.ru/organizations/108904/info',
        }
        return (
            <Col {...conf}>
                <div className={'item item-' + nr}>
                    <div className={'image-contain'}>
                        <a target={'_blank'} href={links[nr]}>
                            {this.renderImage('/partners/' + nr + '.svg')}
                        </a>
                    </div>
                </div>
            </Col>
        )
    }

    renderHeader = () => {
        return (
            <div className={'header'}>
                <Row justify={'bottom'}>
                    <Col span={24}>
                        <h2>
                            {this.t(this.t1)}
                        </h2>
                    </Col>
                </Row>
            </div>
        )
    }

    render() {

        let $items = []

        for (let i = 3; i < 5; i++) {
            $items.push(this.renderItem(i))
        }

        return (
            <div className={'home-page-partners'}>
                <br/>
                {this.renderHeader()}
                <Row align={'middle'} justify={'center'}>
                    {$items}
                </Row>
            </div>
        )
    }
}

export default Partners;
