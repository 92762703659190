import Component from "@base/Component";

import Carousel from "@base/widgets/Carousel";
import {Row, Col, Button} from "antd";
import {
    ArrowLeftOutlined,
    ArrowRightOutlined
} from '@ant-design/icons';

class News extends Component {

    data = {};

    onMount = () => {
        this.loadNewsData(this)
    }

    render() {

        let items = this.data;
        let id = this.props.id

        if (!items || !items[this.props.type] || !items[this.props.type][id])
            return ''

        let news = items[this.props.type][id]
        if (!news)
            news = {title: 'Item not found!', text: '', images: []}
        let newsID = news.id
        let next = (newsID && newsID > 0 && this.nextId(items[this.props.type], newsID))
            ? items[this.props.type][this.nextId(items[this.props.type], newsID)] : false;
        let prev = (newsID && newsID > 0 && this.prevId(items[this.props.type], newsID))
            ? items[this.props.type][this.prevId(items[this.props.type], newsID)] : false;

        return (
            <div className={'news-view-page ' + this.props.type + '-id-' + id}>
                <div className={'tac'}>
                    <br/>
                    <Button type={'link'}
                            className={'text-muted p0 go-back-link'}
                            onClick={() => this.to(this.app().data.menuLinks.blog + (this.props.type === 'news' ? '/n' : ''))}>
                        <ArrowLeftOutlined/>
                        {
                            this.props.type === 'news' ?
                                this.t('Назад ко всем новостям') :
                                this.t('Назад к полезной информации')
                        }
                    </Button>
                </div>

                {
                    this.renderTitle(
                        news.title,
                        news.info
                    )
                }

                <div className={'font-slim fs-md'}>

                    <div className={'carousel-news-container'}>
                        <Carousel items={news.images.map((v, i) => {
                            return this.renderImage(v, {}, false, {
                                width: '100%',
                                height: '387px',
                                backgroundSize: '100% 100%'
                            })
                        })}/>
                    </div>
                    {this.renderHtml(news.text)}
                </div>

                <br clear={'both'}/>
                <br/>
                <br/>

                <Row className={'bottom-navigation-bootons'}>
                    <Col span={12} className={'tal'}>

                        <Button disabled={!prev} type={'link'}
                                className={'text-muted p0 go-back-link'}
                                onClick={() => this.prevRecord(newsID)}>
                            <ArrowLeftOutlined/> {this.t('Предыдущая запись')}
                        </Button>
                        <b>
                            {prev ? this.t(prev.title) : ''}
                        </b>
                    </Col>
                    <Col span={12} className={'tar'}>

                        <Button disabled={!next} type={'link'}
                                className={'text-muted p0 go-back-link'}
                                onClick={() => this.nextRecord(newsID)}>
                            {this.t('Следующая запись')} <ArrowRightOutlined/>
                        </Button>

                        <b>
                            {next ? this.t(next.title) : ''}
                        </b>

                    </Col>
                </Row>

            </div>
        )
    }


    prevRecord = (id) => {
        if (!id)
            return;
        return this.to('/' + this.props.type + '/' + (this.prevId(this.data[this.props.type], parseInt(id))))
    }

    nextRecord = (id) => {
        if (!id)
            return;
        return this.to('/' + this.props.type + '/' + (this.nextId(this.data[this.props.type], parseInt(id))))
    }

    nextId = (data, key) => {
        let keys = Object.keys(data).sort();
        let loc = keys.indexOf(key.toString());
        let res = data[keys[loc + 1]]
        if (res)
            return res.id
    }

    prevId = (data, key) => {
        let keys = Object.keys(data).sort();
        let loc = keys.indexOf(key.toString());
        let res = data[keys[loc - 1]]
        if (res)
            return res.id
    }
}

export default News;