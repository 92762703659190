import {Component} from "@base/Base";

import BaseHeader from "./BaseHeader";
import BaseContent from "./BaseContent";
import BaseFooter from "./BaseFooter";
import {Route} from "react-router-dom";
import PaymentResult from "@pages/PaymentResult";


class BaseLayout extends Component {

    render() {

        let body = document.getElementsByTagName('html')[0]
        body.setAttribute('class','whp-site-container');

        let st = {
            style: {
                zIndex: '2',
                position: 'relative',
            }
        };

        let style = {
            style: (window.location.pathname === '/' ? {
                backgroundImage: 'url(/img/default/hand-waves.svg.png)'
            } : {})
        }

        return (
            <div {...this.app().data.bodyParams}>
                <div {...style} className={'page-container'}>
                    <div {...st}>
                        <BaseHeader/>
                        <div className={'content-container oyv min-h'}>
                            <BaseContent/>
                        </div>
                    </div>
                </div>


                <Route path={'/payment-result/:id'} render={(r) => {
                    return <PaymentResult id={r.match.params.id}/>
                }}/>


                {this.app().data.beforeFooter}
                <div className={'footer-container'}>
                    <BaseFooter/>
                </div>
            </div>
        )
    }
}

export default BaseLayout