import React from 'react'
// import AppContext from "../../../context/AppContext";
import Form from "./Form";
import AppContext from "../../../context/AppContext";

class Create extends React.Component {

    static contextType = AppContext;


    constructor(props) {
        super(props);
        this.state = false
    }

    render() {
        return <Form caller={this}/>
    }

}

export default Create;