import Component from "@base/Component";
import Index from "@pages/wantToHelp/Index"
import Company from "@pages/wantToHelp/Company"
import CustomTabs from "@base/widgets/CustomTabs";
import {Row, Col} from "antd";


class WantToHelp extends Component {

    data = {
        text: {
            title: 'Помогать – это просто'
        },
        tabMenu: "",
        tabs: [
            {
                label: this.t('Физическое лицо'),
                content: <Index/>,
            },
            {
                label: this.t('Компания'),
                content: <Company/>,
            },
        ]
    }

    render() {

        let params = {
            title: false,
            menuClass: 'want-to-help custom-tabs',
            containerClass: '',
            titleClass: 'tar',
            extractMenu: {
                component: this,
                state: 'tabMenu',
            },
            onChangeTab: (tab, component) => true,
            onMount: (component) => true
        }

        let tab = <CustomTabs data={this.data.tabs} {...params}/>

        let titleRowConf = {
            1: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 14},
                lg: {span: 14},
            },
            2: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 10},
                lg: {span: 10},
            }
        }

        return (
            <div className={'want-to-help-page'}>
                {
                    this.renderTitle('', '', '', (
                        <Row align={'middle'} justify={'left'}>
                            <Col {...titleRowConf[1]}>
                                <h1>{this.t(this.data.text.title)}</h1>
                            </Col>
                            <Col style={{display: (this.app().data.lang === 'en' ? 'none' : '')}} {...titleRowConf[2]}>
                                {this.data.tabMenu.menu}
                            </Col>
                        </Row>
                    ))
                }
                <div className={'wth-sep'}></div>
                {tab}
            </div>
        )
    }

}

export default WantToHelp;