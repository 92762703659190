import Component from "@base/Component";
import Logo from "@base/widgets/Logo";


class PaymentResult extends Component {

    data = {
        status: ''
    }

    onMount = () => {
        this.API.get('/transaction/status?id=' + this.props.id, (res) => {
            this.set({
                status: res.status
            })
        })
    }

    render() {
        return (
            <div style={{backgroundImage: 'url(/img/h.png)'}} className={'payment-result-page'}>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Logo/>

                <div className={'tac hidden'}>
                    {this.data.status}
                </div>
            </div>
        )
    }

}

export default PaymentResult;