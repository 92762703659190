import Component from "@base/Component";
import Index from "@pages/needHelp/Index"


class NeedHelp extends Component {

    data = {
        text: {
            title: 'Нужна помощь',
            subTitle: 'Уважаемые посетители сайта!'
        }
    }

    render() {

        return (
            <div className={'need-help-page'}>
                {this.renderTitle(this.data.text.title, this.data.text.subTitle)}
                <Index/>
            </div>
        )
    }

}

export default NeedHelp;