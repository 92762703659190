import Component from "@base/Component";
import {Button, Tabs} from "antd";

const {TabPane} = Tabs;

class CustomTabs extends Component {

    data = {
        open: false,
        extractMenu: false,
        tab: '1'
    }

    changeTab = (tab, t = false) => {
        this.set({
            open: false
        })
        if (this.props.onChangeTab && typeof this.props.onChangeTab === 'function')
            this.props.onChangeTab(tab, this)
        this.set({tab: tab.toString()})
        if (this.props.extractMenu && this.props.extractMenu.component) {
            this.extractMenu(this.props.extractMenu.component, this.props.extractMenu.state)
            this.props.extractMenu.component.set({extractMenu: tab})
            if (t === true) {
                this.setTabItems(this.props.data)
                this.changeTab(tab, false)
            }
        }
    }

    setTabItems = (data = []) => {
        this.menu = []
        this.items = []
        let menu = [];
        let tab = this.data.tab;

        this.items.push(
            data.map((value, index) => {
                index++;
                let key = index.toString();
                if (index === 1 && !this.data.tab) {
                    this.set({tab: key})
                    tab = key;
                }
                menu.push(
                    <Button size={'large'} key={key} className={tab === key ? 'active' : ''}
                            onClick={() => this.changeTab(index, (this.props.extractMenu ? true : false))}>
                        {value.label}
                    </Button>
                )

                let defaultTitle = value.title ? value.title : this.renderTitle(value.label)

                let title = this.props.title ? (this.props.title === true ? defaultTitle :
                    this.props.title) : ''
                return (
                    <TabPane tab={value.label} key={key}>
                        <div className={this.props.titleClass}>
                            {title}
                        </div>
                        {value.content}
                    </TabPane>
                )
            })
        )
        this.menu.push(
            <div key={'custom-tabs-widget-menu-container'}
                 className={'custom-tabs-widget-menu-container-block'}>
                <div className={'custom-tabs-widget-menu-container '
                + this.props.menuClass + ' '
                + (this.data.open ? 'open' : '')}>
                    <button type={'link'} className={'mobile-menu-toggle ' + (this.data.open ? 'opened' : '')}
                            onClick={() => this.toggleOpen()}
                    >
                        {this.t('Меню')}
                    </button>
                    {menu}
                </div>
            </div>
        )
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        this.setTabItems(this.props.data)
        if (this.props.extractMenu)
            this.extractMenu(this.props.extractMenu.component, this.props.extractMenu.state)
        return true
    }

    onMount = () => {
        this.setTabItems(this.props.data)
        if (this.props.extractMenu)
            this.extractMenu(this.props.extractMenu.component, this.props.extractMenu.state)
        if (this.props.onMount && typeof this.props.onMount === 'function')
            this.props.onMount(this)
    }

    extractMenu = (component, state) => {
        if (!this.data.extractMenu) {
            this.set({extractMenu: true})
            let d = component.data;
            d[state] = this;
            component.set(d)
        }
    }

    items = [];
    menu = [];


    toggleOpen = () => {
        this.set({
            open: !this.data.open
        })
        this.set({extractMenu: false});
        this.setTabItems(this.props.data)
    }

    render() {
        if (this.items.length === 0)
            return ''
        return (
            <div className={'custom-tabs-widget-container ' +
            (this.data.open ? 'open' : '') + ' ' + this.props.containerClass
            }>
                {
                    !this.data.extractMenu ? (<div className="tac">
                        {this.menu}
                    </div>) : ''
                }
                <Tabs className={'custom-tabs-widget'}
                      activeKey={this.data.tab}
                      defaultActiveKey={this.data.tab}
                      onChange={(e) => this.changeTab(e)}
                >
                    {this.items}
                </Tabs>
            </div>

        )
    }

}

export default CustomTabs;