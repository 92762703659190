import Component from "@base/Component";
import {Tabs, Row, Col, Card, Button} from "antd";
import NewsItem from "@pages/home/NewsItem";

const {TabPane} = Tabs;

class Index extends Component {

    data = {
        tab: (this.props.type === 'news' ? '1' : '2'),
        text: {
            tabNews: 'Новости',
            tabInfo: 'Полезная информация',
        }
    }

    changeCallback = (e) => {
        this.set({
            tab: e
        })
    }

    test = () => {
        this.set({
            tab: (this.data.tab === '1' ? '2' : '1')
        })
    }


    render() {
        return (
            <>
                <Tabs className={'round-tabs left'}
                      activeKey={this.data.tab}
                      defaultActiveKey={this.data.tab}
                      onChange={(e) => this.changeCallback(e)}
                >
                    <TabPane tab={this.t(this.data.text.tabNews)} key={'1'}>
                        <NewsItem data={this.props.data} type={'news'}/>
                    </TabPane>
                    <TabPane tab={this.t(this.data.text.tabInfo)} key={'2'}>
                        <NewsItem data={this.props.data} type={'info'}/>
                    </TabPane>
                </Tabs>
            </>
        )

    }
}

export default Index;