import Component from "@base/Component";
import Index from "@pages/blog/Index";
import View from "@pages/blog/View";
import {Route} from "react-router-dom";


class Blog extends Component {

    data = {
        text: {
            title: 'Блог'
        }
    }

    onMount = () => this.loadNewsData(this)

    render() {
        let $app = this.app();
        return (
            <div className={'blog-page'}>
                <div className="tal">
                    {this.renderTitle(this.data.text.title)}
                </div>
                <Index data={this.data} type={this.props.type} />
                <Route path={$app.data.menuLinks.blog + '/:id'} render={(r) => {
                    return (
                        <div className="view">
                            <View id={r.match.params.id} data={this.data}/>
                        </div>
                    )
                }}/>
            </div>
        )
    }

}

export default Blog;