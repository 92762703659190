//
import React from 'react'
import AppContext from "../../../context/AppContext";
import CKeditor from "../../../widgets/CKeditor";
import moment from "moment";
import CropperWidget from "../../../app/CropperWidget";
import {Button, Checkbox} from "antd";

class Form extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {}
    }

    /**
     * toggle loading state
     */
    loadToggle = () => this.setState({loading: !this.state.loading})

    /**
     * render content
     * @return {JSX.Element}
     */
    content = () => {

        /** @type {AdminBase} */
        const $app = this.context

        /** @type {InputHelper} */
        const $form = this.context.HInput.new(this)

        let $left = [];
        let $right = [];

        let fields = this.getFieldTypes();
        let hasTranslates = false
        if (fields)
            Object.keys(fields).map((e, i) => {
                let $name = e;
                let $type = fields[e];
                if ($type === 'text' && hasTranslates === false)
                    hasTranslates = true

                let item = <div key={$name}>{this.getInputByType($form, $name, $type)}</div>;
                (i % 2 === 0 || Object.keys(fields).length == 2) ?
                    $left.push(item) :
                    $right.push(item);
            })

        let $items = $form.renderFieldsByPositions(
            hasTranslates ?
                (
                    <div>
                        <>
                            <section style={{float: 'left'}}>
                                Скрыть перевод -
                            </section>
                            <section style={{
                                float: 'left',
                                top: '-8px',
                                position: 'relative',
                                left: '5px'
                            }}>
                                {
                                    $form.checkbox('translates[hideTranslationState]', {
                                        checked: $form.attributes['translates[hideTranslationState]']
                                    }, {
                                        rules: false,
                                        label: false,
                                    })
                                }
                            </section>
                        </>
                    </div>
                ) : ''
            , $left, $right, (
                <>
                    <br/>
                    <br/>
                    <hr/>
                </>
            ))

        return (
            <>
                {
                    $form.render($items, {
                        onFinish: () => {
                            this.loadToggle()
                            let $attrs = this.state.attributes;
                            if ($attrs.date) {
                                $attrs['date'] = moment($attrs.date, 'YYYY-MM-DD').add('days', 1)
                                this.setState({attributes: $attrs})
                            }

                            let $translates = {}
                            Object.keys($attrs).map((k, v) => {
                                if (k.startsWith('translates')) {
                                    let $attrName = (k.split('[')[1]).replace(']', '')
                                    $translates[$attrName] = $attrs[k];
                                }
                            })

                            $attrs['translates'] = $translates

                            $app.record.save($attrs, (res) => {
                                this.loadToggle()
                            })
                        }
                    })
                }
            </>
        )
    }

    render = () => this.content()


    getFieldTypes = () => {
        if (!this.props.caller.state.res)
            return false;

        let $types = this.props.caller.state.res.response.fieldTypes;

        delete $types['id']

        return $types
    }

    /**
     *
     * @param $form {InputHelper}
     * @param $name {string}
     * @param $type {string}
     */
    getInputByType = ($form, $name, $type) => {
        /**
         * @type {array}
         */
        let options = this.props.caller.state.res.response.options;
        let translates = this.props.caller.state.res.response.translates;

        if ($name === 'updated_at' || $name === 'created_at')
            return;

        if ($type === 'text' || $name === 'text') {
            return (
                <>
                    <b>
                        {$form.getLabel($name)}
                    </b>
                    <br/>
                    <br/>
                    <CKeditor caller={this} placeholder={$form.getLabel($name) + ' ...'} attribute={$name}
                              form={$form}/>

                    <br/>
                    <hr/>

                    <b>
                        {$form.getLabel($name)} - EN
                    </b>
                    <br/>
                    <br/>
                    <CKeditor caller={this} placeholder={$form.getLabel($name) + ' - EN ...'}
                              attribute={'translates[' + $name + ']'}
                              form={$form}/>
                </>
            )
        }

        if ($type === 'decimal' || $type === 'integer') {
            return $form.number($name)
        }

        if ($type === 'date') {
            return $form.datePicker($name)
        }

        if (options && typeof options === 'object' && options[$name]) {
            return $form.select($name, options)
        }

        if ($name === 'image' || $name === 'images') {
            return $form.uploader($name)
        }

        if ($name === 'file' || $name === 'files') {
            return $form.uploader($name, [], '.pdf,.doc,.docx,.xml')
        }


        return (
            <>
                {$form.text($name)}
                {($name !== 'amount' && $name !== 'year') ? (
                    <>
                        <br/>
                        <hr/>
                        {$form.text('translates[' + $name + ']', {
                            placeholder: $form.getLabel($name) + ' - EN',
                        }, {
                            'label': $form.getLabel($name) + ' - EN',
                            'rules': false
                        })}
                    </>
                ) : ''}
            </>
        )
    }
}

export default Form;