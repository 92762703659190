import Component from "@base/Component";
import {Col, Row, Button} from "antd";
import TableInfo from "@base/widgets/TableInfo";
import Logo from "@base/widgets/Logo";

class Step3 extends Component {

    caller = this.props.caller

    data = {
        amount: 0,
        amounts: {
            1: 100,
            2: 500,
            3: 1000,
            4: 5000,
            5: 10000,
        },
        step: this.props.caller.data.step,
        text: {
            change: this.t('Изменить способ внесения пожертвования'),
            thx: this.t('Спасибо за ваше \n участие и помощь!'),
            warn: this.t('Внимание!'),
            warn1: this.t('Убедительная просьба при переводе средств по реквизитам в назначении платежа указывать «Благотворительное пожертвование на уставную деятельность Фонда»'),
            warn2: this.t('Если в документе не будет слов «Благотворительное пожертвование», то Фонд обязан будет выплатить государству налог на прибыль из перечисленных вами денег!'),
            warn3: this.t('Если ваша помощь носит адресный характер, обязательно указывайте конкретную программу помощи или фамилию и имя. Например: «Благотворительное пожертвование на программу «Cardio.Live» или «Благотворительное пожертвование для Иванова Пети».'),
            download: this.t('Скачать реквизиты'),
        },
    }

    render() {

        let show = this.caller.data.step === this.props.step ? true : false

        let prConf = {
            1: {
                xxl: {span: 9},
                xl: {span: 9},
                lg: {span: 24},
            },
            2: {
                xxl: {span: 14, offset: 1},
                xl: {span: 14, offset: 1},
                lg: {span: 24},
            },
        };

        return (
            <div>
                <div className={(show ? '' : 'pointer hidden')}>


                    <Row>
                        <Col xs={{span: 0}} sm={{span: 24}}>
                            {this.caller.data.selectedPaymentBg}
                        </Col>
                    </Row>
                    <div onClick={() => this.caller.setStep(0)}>
                        <Row>
                            <Col xs={{span: 7}} sm={{span: 0}}>
                                {this.caller.data.selectedPaymentBg}
                            </Col>
                            <Col xs={{span: 15, offset: 2}} sm={{span: 24, offset: 0}} className={'tal pointer'}>
                                <span className={'pointer hint font-slim l'}>
                                    {this.data.text.change}
                                </span>
                            </Col>
                        </Row>
                    </div>


                    <br/>
                    <br/>
                    <Row className={'transfer-money-block'}>
                        <Col {...prConf[1]}>
                            <div className={'gray-block'}>
                                <h2 className={'ttu'}>
                                    {this.data.text.warn}
                                </h2>

                                <div className={'font-slim'}>
                                    {this.data.text.warn1}
                                </div>
                                <br/>
                                <b>
                                    {this.data.text.warn2}
                                </b>
                                <br/>
                                <br/>
                                <div className={'font-slim'}>
                                    {this.data.text.warn3}
                                </div>
                                <br/>
                                <br/>
                                <Button className={'yellowBt'}
                                        type={'link'}
                                        href={'/files/Kartochka_BF_red.doc'}
                                        download={'Kartochka_BF_red.doc'}
                                >
                                    {this.renderImage('/want-to-help/payments/file.png')} &nbsp;&nbsp; {this.data.text.download}
                                </Button>
                            </div>
                            <Row>
                                <Col xs={{span:0}}  xl={{span:24}}>
                                    <br/>
                                    <Logo />
                                </Col>
                            </Row>
                        </Col>
                        <Col {...prConf[2]}>
                            <TableInfo/>
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <Row>
                        <Col xs={{span:24}}  xl={{span:0}}>
                            <Logo />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

}

export default Step3;