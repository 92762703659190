//
import React, {useContext, useEffect} from 'react'
import AppContext from "../../context/AppContext";
//
import Index from "./dashboard/Index";
import NoPage from "../NoPage";

const Dashboard = () => {

    const $app = useContext(AppContext);


    useEffect(() => {
        $app.content.setTitle('DASHBOARD!');
    }, [$app])

    let $pages = []

    $pages.push($app.content.showPage(<Index/>, 'Dashboard','Index'))

    return ($pages.join('') === '' ? <NoPage/> : $pages);

}

export default Dashboard;