import Component from "@base/Component";
import TableInfo from "@base/widgets/TableInfo";
import Partners from "@pages/home/Partners";
import {Row, Col, Button} from "antd";
import {FileTextOutlined} from '@ant-design/icons';

class Docs extends Component {

    /**
     * render image
     * @param i
     * @param params
     * @return {JSX.Element}
     */
    imgContent = (i, params = {}) => {
        let img = '/about/docs/' + i + '.png'
        let st = {
            style: {
                backgroundImage: 'url("/img/' + img + '")',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
            },
            className: 'bg-img'
        }
        params.style = {
            opacity: 0,
            width: '100%'
        }
        return (
            <div className={'img-container'}>
                <div {...st}>
                    {this.renderImage(img, params)}
                </div>
            </div>
        )
    }

    /**
     * @param nr 1,2,3
     * @return {JSX.Element}
     */
    renderDocDesc = (nr) => {
        let pn = {
            1: 'Устав Благотворительного фонда\n содействия укреплению здоровья\n "Мир Здоровых Людей"',
            2: 'Свидетельство о государственной\n регистрации некоммерческой\n организации',
            3: 'Свидетельство о постановке\n на налоговый учет ',
        }
        return (
            <div className={'doc-desc'}>
                <p className="font-slim">
                    {this.t(pn[nr])}
                </p>
                <br/>
            </div>
        )
    }

    renderDocAndDesc = (nr) => {
        let gridConf1 = {
            xs: {span: 16, pull: 0},
            sm: {span: 8},
            md: {span: 6},
            lg: {span: 8},
            xl: {span: 12, offset: 1}
        }
        let gridConf2 = {
            xs: {span: 24},
            sm: {span: 14, offset: 1},
            md: {span: 15, offset: 1},
            lg: {span: 15, offset: 1},
            xl: {span: 24}
        }

        let links = {
            1: '/files/about/chart.pdf',
            2: '/files/about/cert.pdf',
            3: '/files/about/inn.pdf',
        }

        return (
            <Row justify={'center'} align={'middle'}>
                <Col {...gridConf1}>
                    <a target={'_blank'} href={links[nr]}>
                        {this.imgContent(nr)}
                    </a>
                </Col>
                <Col {...gridConf2}>
                    <a target={'_blank'} style={{color: '#666'}} href={links[nr]}>
                        {this.renderDocDesc(nr)}
                    </a>
                </Col>
            </Row>
        )
    }

    getContent = () => {

        let defaultGrid = {
            sm: {span: 24},
            md: {span: 24},
            lg: {span: 12},
            xl: {span: 8,}
        }

        return (
            <>
                <Row>
                    <Col {...defaultGrid}>
                        {this.renderDocAndDesc(1)}
                        <br/>
                    </Col>
                    <Col {...defaultGrid}>
                        {this.renderDocAndDesc(2)}
                        <br/>
                    </Col>
                    <Col {...defaultGrid}>
                        {this.renderDocAndDesc(3)}
                        <br/>
                    </Col>
                </Row>
                {this.app().data.lang !== 'ru' ? '' : (
                    <>
                        <div className="tac">
                            {this.renderTitle('Реквизиты фонда')}
                        </div>
                        <Row>
                            <Col xs={{span: 24}} lg={{span: 18, offset: 3}}>
                                <TableInfo/>
                            </Col>
                            <Col xs={{span: 24}} lg={{span: 18, offset: 3}} className={'tac'}>
                                <br/>
                                <br/>
                                <Button
                                    type={'link'}
                                    href={'/files/Kartochka_BF_red.doc'}
                                    download={'Kartochka_BF_red.doc'}
                                    className={'yellowBt'}
                                >
                                    <FileTextOutlined/> {this.t('Скачать реквизиты')}
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
                <Partners/>
            </>
        )
    }

    render() {
        return this.getContent()
    }

}

export default Docs;