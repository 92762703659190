import Component from "@base/Component";
import Logo from "@base/widgets/Logo";
import {Steps, Statistic, Row, Col, Button} from 'antd';

import TableInfo from "@base/widgets/TableInfo";

class Company extends Component {

    data = {
        text: {

            thx: this.t('Спасибо за ваше \n участие и помощь!'),
            info: this.t('Корпоративные благотворители могут сделать пожертвование, переведя средства по указанным реквизитам, а также, при желании, заключив договор с Фондом.'),
            info1: this.t('Для заключения договора свяжитесь с нами по любому из указанных ниже контактов:'),


            download: this.t('Скачать реквизиты'),
        },
    }

    render() {


        let prConf = {
            1: {
                xxl: {span: 9},
                xl: {span: 9},
                lg: {span: 24},
            },
            2: {
                xxl: {span: 14, offset: 1},
                xl: {span: 14, offset: 1},
                lg: {span: 24},
            },
        };

        return (
            <div>
                <Row className={'transfer-money-block'}>
                    <Col {...prConf[1]}>
                        <div className={'gray-block'}>
                            <div className={'font-slim'}>
                                {this.data.text.info}
                            </div>
                            <br/>
                            <br/>
                            <div className={'font-slim'}>
                                {this.data.text.info1}
                            </div>
                            <br/>
                            <br/>
                            <Button className={'yellowBt'}
                                    type={'link'}
                                    href={'/files/Kartochka_BF_red.doc'}
                                    download={'Kartochka_BF_red.doc'}
                            >
                                {this.renderImage('/want-to-help/payments/file.png')} &nbsp;&nbsp; {this.data.text.download}
                            </Button>
                        </div>
                        <Row>
                            <Col xs={{span:0}}  xl={{span:24}}>
                                <br/>
                                <Logo />
                            </Col>
                        </Row>
                    </Col>
                    <Col {...prConf[2]}>
                        <TableInfo/>
                    </Col>
                </Row>

                <Row>
                    <Col xs={{span:24}}  xl={{span:0}}>
                        <br/>
                        <br/>
                        <Logo />
                    </Col>
                </Row>

            </div>
        )
    }
}

export default Company;