import Component from "@base/Component";
import Carousel from "@pages/home/Carousel";
import Report from "@pages/home/Report";
import Video from "@pages/home/Video";
import News from "@pages/home/News";
import Info from "@pages/home/Info";
import Partners from "@pages/home/Partners";


class Home extends Component {

    data = {}

    textTitle = 'Им нужна ваша помощь'
    textInfo = 'Вместе мы можем помочь взрослым и детям \n в их борьбе с тяжелыми заболеваниями!'


    onMount = () => {
        this.loadNewsData(this)
    }

    render() {

        return (
            <>
                {this.renderTitle(this.textTitle, this.textInfo)}
                <Carousel/>
                <Video/>
                <Report/>
                <News data={this.data}/>
                <Info data={this.data}/>
                <Partners/>
            </>
        )
    }

}

export default Home;
