//
import React from 'react'
import AppContext from "../context/AppContext";

//
import {Spin} from "antd";
import {SettingFilled} from '@ant-design/icons';
//
import NoPage from "./NoPage";
import User from "./pages/User";
import Profile from "./pages/Profile";
import Dashboard from "./pages/Dashboard";
import Default from "./pages/Default";
import BaseLayoutLoader from "./BaseLayoutLoader";


/**
 *
 * @param $app
 * @constructor
 */
class BaseContent extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            oldContent: '',
            load: null,
        }
    }

    /**
     * @type {function(): AdminBase}
     */
    app = () => {
        return this.context
    }

    pageItems = () => {

        /**
         * @type {AdminBase}
         */
        const $app = this.app()

        $app.content.setContentStates(this);

        let $pages = []


        let $controllers = [
            'Dashboard',
            'Profile',
            'User',
        ];

        $pages.push($app.content.showController(<Dashboard/>, 'Dashboard'))
        $pages.push($app.content.showController(<Profile/>, 'Profile'))
        $pages.push($app.content.showController(<User/>, 'User'))

        let inc = $app.route.controller.charAt(0).toUpperCase() + $app.route.controller?.slice(1);

        if (!$controllers.includes(inc)) {
            $pages.push($app.content.showController(<Default/>, $app.route.controller))
        }

        let $content = $pages.join('') === '' ? <NoPage/> : $pages
        let $c = ($app.route.action === 'index' && $app.route.controller !== 'profile') || $app.route.action === 'view' ?
            '' : 'site-base-content-container content-block-gray';

        return (
            <div className={$c}>
                {$content}
            </div>
        )
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        let $tmp = document.getElementById("main-content-container").innerHTML
        const strippedString = $tmp.replace(/(<([^>]+)>)/gi, "")
        if (strippedString !== '')
            prevState.oldContent = $tmp
        return prevState
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.load === true) {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 250)
        }
    }

    render = () => {
        const antIcon = <SettingFilled spin style={{fontSize: '1em', color: 'transparent'}}/>;
        return (
            <div>
                <BaseLayoutLoader/>
                <Spin style={{'color': 'transparent'}} spinning={this.state.load} indicator={antIcon}>
                    {this.state.load === true ? <div dangerouslySetInnerHTML={{__html: this.state.oldContent}}/> : ''}
                    <div id={'main-content-container'} style={{display: (this.state.load !== true ? 'block' : 'none')}}>
                        {this.pageItems()}
                    </div>
                </Spin>
            </div>
        )
    }
}

export default BaseContent;