//
import React from "react";
import {CKEditor, Paragraph, Bold, Italic, Essentials} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class CKeditor extends React.Component {

    render() {


        if (!this.props.caller.state || !this.props.caller.state.attributes)
            return '';
        return (

            <CKEditor
                language='ru'
                config={{
                    placeholder: (this.props.placeholder ? this.props.placeholder : ''),
                    // plugins: [ Paragraph, Bold, Italic, Essentials ],
                    toolbar: ['bold']
                }}
                editor={ClassicEditor}
                data={(this.props.value ? this.props.value : this.props.caller.state.attributes[this.props.attribute])}
                onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    // console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    this.props.form.setAttr(this.props.attribute, data)
                    // console.log({event, editor, data});
                }}
                onBlur={(event, editor) => {
                    // console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                    // console.log('Focus.', editor);
                }}
            />
        );
    }
}

export default CKeditor;