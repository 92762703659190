//
import React from 'react'
//
import {Form, Input, Button, Select, Upload, Row, Col, AutoComplete, DatePicker, Checkbox} from 'antd'
import AppContext from "../context/AppContext";
import moment from "moment";
import {
    FileTwoTone,
    UploadOutlined,
    EllipsisOutlined,
} from '@ant-design/icons';
import CropperWidget from "./CropperWidget";

/**
 * @constructor
 */
const InputHelper = function () {

    /**
     * @type {AdminBase}
     */
    this.app = null;

    /**
     * set app
     * @param $a
     * @returns {InputHelper}
     */
    this.setApp = ($a) => {
        this.app = $a;
        return this;
    }

    /**
     * field attributes
     * @type {{}}
     */
    this.attributes = {};

    /**
     * select options
     * @type {{}}
     */
    this.options = {};

    /**
     * @type {null}
     */
    this.caller = false;

    /**
     * set caller state
     * @param v
     */
    this.callerSet = (v) => {
        if (this.caller)
            this.caller.setState(v)
    }

    /**
     * set attributes value
     * @param $name
     * @param $value
     * @param $changeCallerState
     * @return {*}
     */
    this.setAttr = ($name, $value, $changeCallerState = true) => {
        this.attributes[$name] = $value;
        if ($changeCallerState)
            this.callerSet({attributes: this.attributes})
        this.setFormValues(this.attributes)
        return $value
    }

    /**
     * sync file list
     * @return {*}
     */
    this.syncFiles = () => {
        let $files = []
        if (this.files) {
            this.files.map((k) => {
                if (k.uid)
                    $files.push(k.uid)
                return false;
            })
            this.attributes['files'] = $files
        }
        this.callerSet({
            files: this.files,
            attributes: this.attributes,
        })
    }

    /**
     * set app
     * @param $caller
     * @returns {InputHelper}
     */
    this.new = ($caller = {}) => {
        let HInputs = new InputHelper().setApp(this.app);
        HInputs.caller = $caller;
        if ($caller.state.attributes) {
            HInputs.attributes = $caller.state.attributes;
        }
        if ($caller.state.files) {
            HInputs.files = $caller.state.files;
        }
        return HInputs;
    }

    /**
     * default item configs
     * @param $name
     * @param $inputParams
     */
    this.defaultInputConfig = ($name, $inputParams = {}) => {
        let $callback = null;
        if (typeof $inputParams.onChange === 'function')
            $callback = $inputParams.onChange;
        $inputParams['onChange'] = (v) => {

            if (typeof $callback === 'function')
                return $callback(v, this.caller)
            if (v || (v && v.target))
                this.setAttr($name, (v.target ? v.target.value : v))

        }
        return Object.assign({
            autoComplete: 'off',
            placeholder: this.getLabel($name) + ' ...',
            allowClear: true,
            // disabled: this.caller.state.loading,
            disabled: false,
            suffix: (true ? '' : (<EllipsisOutlined style={{display: 'none'}}/>)),
            prefix: '',
        }, $inputParams)
    }

    /**
     * get item label
     * @param $name
     * @return {*}
     */
    this.getLabel = ($name) => {
        let $default = $name.toUpperCase();
        let $labels = false;
        if (this.caller && this.caller.state && this.caller.state.labels)
            $labels = this.caller.state.labels
        if (this.caller && this.caller.state && this.caller.state.res && this.caller.state.res.labels)
            $labels = this.caller.state.res.labels
        if (!$labels)
            $labels = this.app.record.getLabels()
        if ($labels && $labels[$name])
            return $labels[$name]
        return $default;
    }

    this.files = [];
    this.deletedFiles = [];

    this.deletedFile = ($item) => {
        let $t = this

        return new Promise((resolve, reject) => {
            let $id = $item.response ? $item.response.item.uid : $item.uid;
            this.app.record.delete($id, () => {
                $t.files = $t.files.filter((item, pos) => {
                    let $p = $t.files.indexOf(item);
                    let $uid = $t.files[$p]['uid'];
                    return $uid !== $id
                })
                this.syncFiles();
                this.deletedFiles.push($id)
                resolve(true)
            }, '/file', false);
        })
    }

    /**
     * uploader
     * @param $name
     * @param $files
     * @returns {JSX.Element}
     */
    this.uploader = ($name = 'file', $files = [], accept = '.jpg,.png,.svg,.jpeg') => {

        const FormUploader = () => {
            this.files = this.files.concat($files)

            let $headers = {};

            if (this.app.api.getToken())
                $headers = Object.assign($headers, this.app.api.getTokenHeader())

            let $upConfig = {
                multiple: false,
                accept: accept,
                disabled: this.caller.state.loading,
                action: this.app.api.url() + '/file/upload?model=' + this.app.route.controller,
                headers: $headers,
                itemRender: (view, data, x) => {

                    let FileInputs = class FileInputsClass extends React.Component {
                        static contextType = AppContext

                        constructor(props) {
                            super(props);
                            let data = this.props.data;
                            let translate = this.props.translate;

                            this.state = {
                                val: (translate ? data.name_en : data.name),
                                attributes: {
                                    name: data.name,
                                    name_en: data.name_en,
                                }
                            }
                        }

                        render() {
                            let data = this.props.data;
                            if ($name === 'image' || $name === 'images')
                                return ''
                            /**
                             * @type {AdminBase}
                             */
                            let $app = this.context
                            let val = (this.props.translate ? data.name_en : data.name)
                            let title = this.props.title ? this.props.title : 'Название'
                            return (
                                <>
                                    <span>{title}</span>
                                    <Input
                                        placeholder={title} defaultValue={val}

                                        onChange={(value) => {
                                            val = value.target.value
                                            return val
                                        }}
                                        addonAfter={
                                            <a className={'rename-file'} onClick={() => {
                                                let files = this.props.component.files;
                                                files.map((item, index) => {
                                                    if (item.uid === data.uid) {
                                                        if (this.props.translate) {
                                                            files[index].name_en = val
                                                        } else {
                                                            files[index].name = val
                                                        }
                                                    }
                                                })
                                                this.props.component.callerSet({
                                                    files: files
                                                })
                                                $app.api.post('/file/upload', {
                                                    name: val,
                                                    translate: this.props.translate,
                                                    update: data.uid,
                                                })
                                            }
                                            }>
                                                OK
                                            </a>
                                        }/>
                                </>
                            )
                        }
                    }
                    return data.isImageUrl ? (
                        <div>
                            {data.thumbSize ? (
                                <div>
                                    <CropperWidget caller={this.caller} data={
                                        {
                                            id: data.uid,
                                            img: data.url_origin,
                                            thumb: data.thumb,
                                            width: (data.thumbSize ? data.thumbSize.width : 100),
                                            height: (data.thumbSize ? data.thumbSize.height : 100),
                                        }
                                    }/>
                                    <Button type={'danger'} block onClick={() => this.deletedFile(data)}>
                                        Удалить
                                    </Button>
                                </div>
                            ) : ''}

                        </div>
                    ) : (
                        <div className={'file-form-view'}>
                            {view}
                            <br/>
                            <FileInputs data={data} component={this}/>
                            <FileInputs data={data} title={'Название EN'} translate={true} component={this}/>
                        </div>
                    )
                },
                listType: ($name === 'image' || $name === 'images' ? 'picture-card' : 'text'), //picture, picture-card, text
                onPreview: ($data) => {
                    let $link = '';
                    if ($data.response) {
                        $link = $data.response.item.link
                    } else {
                        $link = $data.link
                    }
                    var win = window.open($link, '_blank');
                    win.focus();
                },
                onChange: ($data) => {
                    if ($data.file && $data.file.response && $data.file.response.item && $data.file.response.item.uid) {
                        if (this.files) {
                            let $isImg = $data.file.response.item.isImageUrl;
                            let $item = {
                                uid: $data.file.response.item.uid,
                                name: $data.file.response.item.name,
                                url_origin: $data.file.response.item.url_origin,
                                status: 'done',
                                isImageUrl: $isImg,
                            }
                            if ($isImg) {
                                $item['url'] = $data.file.response.item.url;
                                $item['thumbUrl'] = $data.file.response.item.thumb;
                                $item['thumbSize'] = $data.file.response.item.thumbSize;
                                $item['link'] = $data.file.response.item.link;
                            }

                            if (!this.files.includes($item.uid) && !this.deletedFiles.includes($item.uid)) {
                                this.files = this.files.concat([$item])
                                this.syncFiles();
                            }
                        }
                    }
                },
                onRemove: ($item) => this.deleteFile($item),
                defaultFileList: [...this.files]
            }


            return (
                <div>
                    <div className={'ant-col ant-form-item-label'}>

                        <label className={'ant-form-item-required'}>
                            <b>
                                {this.getLabel($name)}
                            </b>
                        </label>
                        <br/>
                        <Upload {...$upConfig} >
                            {($name === 'file' || $name === 'image') && this.files.length > 0 ? '' :
                                (
                                    ($name === 'files' || $name === 'images') || this.files.length === 0 ?
                                        ($name !== 'images' && $name !== 'image') ? (

                                            <Button disabled={this.caller.state.loading} icon={<UploadOutlined/>}>
                                                Загрузить файл ({accept.split(', ')})
                                            </Button>
                                        ) : (
                                            <>
                                                Загрузить картинку
                                                <br/>
                                                {accept.split(', ')}
                                            </>
                                        )
                                        :
                                        ''
                                )
                            }
                        </Upload>
                    </div>
                </div>
            )
        }

        return <FormUploader/>
    }

    /**
     * text input
     * @param $name
     * @param $inputParams
     * @param $itemParams
     * @returns {JSX.Element}
     */
    this.text = ($name = '', $inputParams = {}, $itemParams = {}) => {
        let $params = this.defaultInputConfig($name, $inputParams)
        return this.item($name, <Input {...$params} />, $itemParams)
    };

    /**
     * checkbox input
     * @param $name
     * @param $inputParams
     * @param $itemParams
     * @returns {JSX.Element}
     */
    this.checkbox = ($name = '', $inputParams = {}, $itemParams = {}) => {
        let $params = this.defaultInputConfig($name, $inputParams)
        delete $params['value'];
        $params['onChange'] = (e, v) => {
            this.setAttr($name, e.target.checked)
        }
        return this.item($name, <Checkbox {...$params} />, $itemParams)
    };

    /**
     * datepicker input
     * @param $name
     * @param $inputParams
     * @param $itemParams
     * @returns {JSX.Element}
     */
    this.datePicker = ($name = '', $inputParams = {}, $itemParams = {}) => {
        let val = this.attributes[$name];
        if (typeof this.attributes[$name] === 'string') {
            val = moment(val, 'YYYY-MM-DD', 'ru', true);
            this.setAttr($name, val)
        }

        let $params = this.defaultInputConfig($name, $inputParams);

        return this.item(
            $name,
            <DatePicker showNow={false} showToday={false} format={'YYYY-MM-DD'} dateFormat="YYYY-DD-MMM"
                        value={val}  {...$params} />
            , $itemParams
        )

    }

    /**
     * text input
     * @param $name
     * @param $inputParams
     * @param $itemParams
     * @returns {JSX.Element}
     */
    this.textArea = ($name = '', $inputParams = {}, $itemParams = {}) => {

        let $params = Object.assign(this.defaultInputConfig($name, {
            showCount: true,
            // autoSize: true,
        }), $inputParams)

        return this.item($name, <Input.TextArea {...$params} />, $itemParams)
    };

    /**
     * password input
     * @param $name
     * @param $inputParams
     * @param $itemParams
     * @returns {JSX.Element}
     */
    this.password = ($name = '', $inputParams = {}, $itemParams = {}) => {
        let $params = this.defaultInputConfig($name, $inputParams)
        return this.item($name, <Input.Password {...$params} />, $itemParams);
    };

    /**
     * text input
     * @param $name
     * @param $options
     * @param $inputParams
     * @param $itemParams
     * @returns {JSX.Element}
     */
    this.select = ($name = '', $options = {}, $inputParams = {}, $itemParams = {}) => {
        if (this.caller && this.caller.state && this.caller.state.options && this.caller.state.options[$name])
            $options = this.caller.state.options[$name];
        if (this.options !== undefined && this.options[$name] !== undefined)
            $options = Object.assign(this.options[$name], $options);
        let $o = [];
        Object.keys($options).map(($k) => {
            let $val = (isNaN(parseInt($k)) ? $k : parseInt($k));
            if (!($inputParams['exclude'] && $inputParams['exclude'].includes($val))) {
                $o.push({
                    label: $options[$k],
                    value: $val
                })
            }
            return []
        });
        let $defaultParams = {
            placeholder: 'Выбрaть значение',
            options: $o,
            showSearch: ($o.length > 0),
            optionFilterProp: "label",
        }
        let $dropDownParams = Object.assign(this.defaultInputConfig($name, $defaultParams), $inputParams)
        const $select = <Select {...$dropDownParams}/>
        return this.item($name, $select, $itemParams)
    };


    /**
     * text input
     * @param $name
     * @param $options
     * @param $inputParams
     * @param $itemParams
     * @returns {JSX.Element}
     */
    this.autoComplete = ($name = '', $options = {}, $inputParams = {}, $itemParams = {}) => {

        if (this.caller && this.caller.state && this.caller.state.options && this.caller.state.options[$name])
            $options = this.caller.state.options[$name];
        if (this.options !== undefined && this.options[$name] !== undefined)
            $options = Object.assign(this.options[$name], $options);
        let $o = [];
        Object.keys($options).map(($k) => {
                $o.push({label: $k, value: $k})
                return []
            }
        );
        let $defaultParams = {
            placeholder: 'Выбрaть значение',
            options: $o,
            showSearch: ($o.length > 0),
            optionFilterProp: "value",
            filterOption: (inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        let $dropDownParams = Object.assign(this.defaultInputConfig($name, $defaultParams), $inputParams)
        const $select = <AutoComplete {...$dropDownParams}/>
        return this.item($name, $select, $itemParams)
    };

    /**
     * text input
     * @param $name
     * @param $inputParams
     * @param $itemParams
     * @returns {JSX.Element}
     */
    this.number = ($name = '', $inputParams = {}, $itemParams = {}) => {
        let $params = this.defaultInputConfig($name, $inputParams)
        let $endVal = null;
        let filter = (value, prev) => {
            const reg = /^-?\d*(\.\d*)?$/;
            if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-' || value === null) {
                $endVal = value;
            } else {
                $endVal = prev
            }
            return $endVal;
        }
        $params['onChange'] = () => false
        $itemParams['normalize'] = (val, prev) => {
            let $filtered = filter(val, prev);
            this.setAttr($name, $filtered)
            return $filtered
        }
        return this.item($name, <Input {...$params} />, $itemParams)
    };

    /**
     * submit button
     * @param $inputParams
     * @param $title
     * @param $itemParams
     * @returns {JSX.Element}
     */
    this.submitButton = ($inputParams = {}, $title = '', $itemParams = {}) => {
        $inputParams = Object.assign({type: "primary", htmlType: "submit"}, $inputParams)
        $itemParams.rules = false;
        $itemParams.label = false;

        const FormSubmitButton = () => {
            let $defaultParams = {
                loading: false,
            }
            let $params = Object.assign($defaultParams, $inputParams)
            return <Button {...$params} >{($title === '' ? 'Сохранить изменения' : $title)}</Button>
        }

        return this.item('submit_button', <FormSubmitButton/>, $itemParams)

    };

    /**
     * item
     * @param $name
     * @param $input
     * @param $params
     * @returns {JSX.Element}
     */
    this.item = ($name, $input = '', $params = {}) => {
        if ($params.label === undefined)
            $params.label = this.getLabel($name)
        if ($params.name === undefined)
            $params.name = $name
        if ($params.rules === undefined) {
            $params.rules = [{required: true, 'message': ''}]
        }

        let $defaultLocal = {}
        if ($name !== 'submit_button') {
            $defaultLocal['hasFeedback'] = false
            $defaultLocal['extra'] = ''
        }

        $params = Object.assign($defaultLocal, $params)
        return $params.button ? (
            <Form.Item name={$name} {...$params}>
                <Input.Group compact>
                    {$input}
                    {$params.button}
                </Input.Group>
            </Form.Item>
        ) : (
            <Form.Item name={$name} {...$params}>
                {$input}
            </Form.Item>
        )


    };

    /**
     * render form
     * @param items
     * @param params
     * @return {JSX.Element}
     */
    this.render = (items, params) => {
        let $p = Object.assign({
            // component: false,
            // colon: true,
            preserve: false,
            labelAlign: 'right',
            name: "form",
            layout: "vertical",
            requiredMark: false,
            size: this.app.state.siteSize,
            initialValues: this.caller.state.attributes,
        }, params)


        if ($p.onFinish) {
            let $callback = $p.onFinish
            delete $p.onFinish
            $p['onFinish'] = ($data) => {
                delete $data.submit_button
                $callback($data)
            }
        }

        const FormInstanceInit = () => {
            const form = Form.useForm()[0]
            React.useEffect(() => {
                if (!this.caller.state.formInstance) {
                    this.caller.setState({formInstance: form})
                    this.assignData()
                } else {
                    let parentCaller = this.caller.props.caller && this.caller.props.caller.state && this.caller.props.caller.state.res;
                    if (!this.caller.state.formInstanceInitialValues && (parentCaller || this.caller.state.res)) {
                        this.caller.setState({formInstanceInitialValues: true})
                        this.assignData(false)
                    }
                }
            }, [form])
            return ''
        }


        let formRender = ($p) => {
            if (!this.caller.state.formInstance)
                return '';
            return (
                <Form form={this.caller.state.formInstance} {...$p} >
                    {items}
                </Form>
            )
        }


        return (
            <>
                <FormInstanceInit/>
                {formRender($p)}
            </>
        )
    }

    /**
     * set fields data on load
     */
    this.assignData = ($updateState = true) => {
        let $caller = this.caller;
        if (!$caller)
            return
        let res = null
        if ($caller.state.res || ($caller.props.caller && $caller.props.caller.state))
            res = $caller.state.res ? $caller.state.res : $caller.props.caller.state.res

        if (!$caller.state.parsed && res) {
            const {response} = res
            if (response) {

                let $labels = (res.labels ? res.labels : {});
                let $item = (response.item ? response.item : {});
                let $options = (response.options ? response.options : {});
                let $files = (response.files ? response.files : []);
                let $translates = (response.translates ? response.translates : {});

                Object.keys($translates).map((entryKey) => {
                    $item['translates[' + entryKey + ']'] = $translates[entryKey]
                })

                let $stateParams = {
                    parsed: true,
                    options: $options,
                    attributes: $item,
                    labels: $labels,
                    files: $files,
                };

                $caller.setState($stateParams)

                if ($caller.state.formInstance)
                    $caller.state.formInstance.setFieldsValue($stateParams.attributes)
            }
        }
    }


    this.getFormInstance = ($caller = false) => {
        $caller = $caller === false ? this.caller : $caller;
        if ($caller && $caller.state.formInstance)
            return $caller.state.formInstance;
        return false;
    }

    /**
     * assign attribute errors from request response
     */
    this.setRequiestErrors = ($errors) => {
        let $fi = this.getFormInstance()
        if ($fi) {
            return Object.keys($errors).map((k) => {
                return $fi.setFields([{name: k, errors: $errors[k]}])
            })
        }
    }


    /**
     * assign attribute errors from request response
     */
    this.requestHasErrors = (res) => {
        if (res.response && res.response.errors && res.response.errors.length !== 0)
            return this.setRequiestErrors(res.response.errors)
        return false;
    }


    /**
     * set form fields values
     * @param $values
     * @return {boolean}
     */
    this.setFormValues = ($values) => {
        let $caller = this.caller
        if ($caller && $caller.state.formInstance) {
            $caller.state.formInstance.setFieldsValue($values)
            return true;
        }
    }

    /**
     * set certain form field value
     * @param $attribute
     * @param $value
     * @return {boolean}
     */
    this.setFormValue = ($attribute, $value) => {
        let $data = {}
        $data[$attribute] = $value;
        return this.setFormValues($data)
    }


    /**
     * render form content according to grid system
     * @param $top
     * @param $left
     * @param $right
     * @param $bottom
     * @param $submitButton
     * @return {JSX.Element}
     */
    this.renderFieldsByPositions = ($top, $left, $right, $bottom = '', $submitButton = null) => {

        let $grid0 = {
            lg: {span: 24, offset: 0},
            md: {span: 24, offset: 0},
            sm: {span: 24, offset: 0, push: 0},
            xs: {span: 24, offset: 0, push: 0},
        }
        let $grid1 = {
            lg: {span: 12, offset: 0},
            md: {span: 12, offset: 0},
            sm: {span: 24, offset: 0},
            xs: {span: 24, offset: 0},
        }
        let $grid2 = {
            lg: {span: 11, offset: 1},
            md: {span: 11, offset: 1},
            sm: {span: 24, offset: 0},
            xs: {span: 24, offset: 0},
        }


        if ($submitButton === null || typeof $submitButton === 'string') {
            let $title = typeof $submitButton === 'string' ? $submitButton : ''
            let $sbtn = this.submitButton({loading: this.caller.state.loading}, $title);
            $submitButton = (
                <>
                    {$sbtn}
                </>
            )
        }

        return (
            <div>
                <Row>
                    <Col {...$grid0} >
                        {$top}
                    </Col>
                </Row>
                <Row>
                    <Col {...$grid1} >
                        {$left}
                    </Col>
                    <Col {...$grid2} >
                        {$right}
                    </Col>
                </Row>

                <Row>
                    <Col {...$grid0} >
                        {$bottom}
                    </Col>
                </Row>
                <Row>
                    <Col {...$grid0}>
                        <br/>
                        {$submitButton}
                    </Col>
                </Row>
            </div>
        )

    }
}

export default (new InputHelper());
