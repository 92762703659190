/**
 * user helper
 * @property RecordHelper this.app.api
 */
const RecordHelper = function () {

    /**
     * @type {App}
     */
    this.app = null;

    /**
     * set app
     * @param $a
     * @returns {RecordHelper}
     */
    this.setApp = ($a) => {
        this.app = $a;
        return this;
    }

    /**
     * get items list
     * @param $callback
     * @param $headers
     * @returns {Promise<void>}
     */
    this.getList = ($callback, $headers) => {
        let $url = '/' + this.app.route.controller + window.location.search;
        return this.app.api.get($url, ($res) => {
            if (typeof $callback === 'function')
                return $callback($res);
            return $res
        }, $headers)
    }


    /**
     * delete record
     * @param $id
     * @param $callback
     */
    this.delete = ($id, $callback, $customPath = null, $redirect = true) => {

        this.app.confirm('Удаление', 'Удалить запись?', (modal) => {


            var $path = '/' + this.app.route.controller + '/' + $id;

            if ($customPath !== null) {
                $path = $customPath + '/' + $id;
            }

            this.app.api.request('DELETE', $path, {id: $id}, ($res) => {
                if (typeof $callback === 'function') {
                    $callback($res)
                }
                if ($redirect) {
                    if (this.app.route.action === 'index') {
                        if (this.app.state.table) {
                            this.app.state.table.refresh()
                        }
                    } else {
                        this.app.route.toPage()
                    }
                }
            });
        })
    }

    /**
     * create record
     * @param $data
     * @param $callback
     * @param $path
     * @return {*}
     */
    this.create = ($data = {}, $callback, $path = false) => {
        return this.save($data, $callback, {
            path: $path,
            redirect: $path === false,
        })
    }

    /**
     * save record
     * @param $data
     * @param $callback
     * @param $params
     */
    this.save = ($data = {}, $callback, $params = {redirect: true, path: false}) => {
        const {path} = $params;

        let $method = (this.app.route.action === 'create' || path) ? 'POST' : 'PUT';


        let $path = path ? path : '/' + this.app.route.controller;

        if ($method === 'PUT' && !path) {
            $path = $path + '/' + this.app.route.recordId
        }

        return this.app.api.request($method, $path, $data, ($res) => {
            if (typeof $callback === 'function') {
                if ($callback($res) && $params.redirect) {
                    if ($res.response && $res.response.item && $res.response.item.id && typeof $params === "object") {
                        this.app.route.toPage('view', $res.response.item.id)
                    }
                }
            }
            if ($res.response && $res.response.item && $res.response.item.id && typeof $params === "object" && $params.redirect) {
                this.app.route.toPage('view', $res.response.item.id)
            }
        });
    }

    /**
     * send data by post request
     * @param $data
     * @param $callback
     * @param $headers
     * @returns {Promise<void>}
     */
    this.postUpdate = ($data, $callback, $headers) => {
        let $id = this.app.route.recordId
        let $url = '/' + this.app.route.controller + '/' + this.app.route.action + '?id=' + $id;
        return this.app.api.post($url, $data, ($res) => {
            if (typeof $callback === 'function')
                return $callback($res);
            return $res
        }, $headers)
    }


    /**
     * get data
     * @param $callback
     * @returns {Promise<void>}
     */
    this.getDataByAction = ($callback) => {
        let $id = this.app.route.recordId
        let $url = '/' + this.app.route.controller + '/' + this.app.route.action + '?id=' + $id;
        return this.app.api.get($url, ($res) => {
            let $item = null;
            if ($res) {
                $item = $res;
            }
            if (typeof $callback === 'function')
                return $callback($item);
            return $item
        })
    }

    /**
     * get item
     * @param $callback
     * @param $id
     * @param $path
     * @returns {Promise<void>}
     */
    this.getRecord = ($callback = null, $id = null, $path = null) => {
        if ($id === null)
            $id = this.app.route.recordId
        if (!$path)
            $path = '/' + this.app.route.controller + '/' + $id;
        return this.app.api.get($path, ($res) => {
            let $response = null;
            if ($res.response && $res.response.item) {
                $response = $res;
            }
            if (typeof $callback === 'function')
                return $callback($response);
            return $response
        })
    }

    /**
     * get item defaults
     * @param $callback
     * @returns {Promise<void>}
     */
    this.getRecordDefaultOptions = ($callback) => {
        return this.app.api.get('/' + this.app.route.controller + '/default-options', ($res) => {
            let $response = null;
            if ($res.response && $res.response.item) {
                $response = $res;
            }
            if (typeof $callback === 'function')
                return $callback($response);
            return $response
        })
    }

    /**
     * get model attributes labels
     * @returns {*}
     */
    this.getLabels = () => {
        try {
            let $controller = this.app.route.controller;
            if ($controller === 'profile')
                $controller = 'user'
            if (this.app.user && this.app.user.attributes && this.app.user.attributes['compartments'] &&
                this.app.user.attributes['compartments'][$controller] &&
                this.app.user.attributes['compartments'][$controller]['attributeLabels'])
                return this.app.user.attributes['compartments'][$controller]['attributeLabels']
            return {};
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * get model attributes labels
     * @returns {*}
     */
    this.getActions = () => {
        try {
            return this.app.user.attributes['actions'][this.app.route.controller]
        } catch (e) {
            console.log(e)
        }
    }

}

export default (new RecordHelper());
