import Component from "@base/Component";
import {Row, Col, Button} from "antd";
import NewsItem from "./NewsItem";

class News extends Component {

    allNewsBtn = (params) => {
        return <Button onClick={()=>{
            this.to('/blog/n')
        }} {...params} type="ghost" shape="round" size={'large'}>
            {this.t('Все новости')}
        </Button>
    }

    renderHeader = () => {
        let conf = {
            md: {span: 12, offset: 0},
        };
        return (
            <div className={'header'}>
                <Row justify={'center'}>
                    <Col {...conf} xs={{span: 24, offset: 0}} sm={{span: 17, offset: 0}} lg={{span: 14, offset: 0}}>
                        <Row className={'left'}>
                            <Col xs={{span: 24}} sm={{span: 10}} md={{span: 10}} lg={{span: 6}}>
                                <h2>
                                    {this.t('Новости')}
                                </h2>
                            </Col>
                            <Col xs={{span: 24}} sm={{span: 13, offest: 1}} md={{span: 14, offest: 0}} lg={{span: 18}}>
                                <p className={'font-slim'}>
                                    {this.t('Самые важные события')}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col {...conf} xs={{span: 0, offset: 0}} sm={{span: 7, offset: 0}} lg={{span: 10, offset: 0}} className={'more-button right'}>
                        {this.allNewsBtn()}
                    </Col>
                </Row>
            </div>
        )
    }

    render() {

        return (
            <div className={'home-page-news'}>
                {this.renderHeader()}

                <NewsItem data={this.props.data} limit={4} />

                <Row gutter={35} align={'middle'} justify={'center'}>
                    <Col xs={{span: 24, offset: 0}} sm={{span: 0, offset: 0}} className={'more-button'}>
                        {this.allNewsBtn({block: true})}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default News;