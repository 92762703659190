import Component from "@base/Component";
import Partners from "@pages/home/Partners";
import {Row, Col} from "antd";

class About extends Component {

    getContent = () => {
        let text = 'Благотворительный фонд содействия укреплению здоровья «Мир Здоровых Людей» создан 7 декабря 2018 г. по инициативе Директора Фонда Натальи Хитровой. ' +
            '\n ' +
            '\n ' +
            'Наталья Хитрова, мама 4 детей, и как никто другой знает, сколько любви и заботы необходимо каждом ребенку для счастливой жизни, как сохранить в доме тепло и уют. Ведь счастливая и здоровая семья делает каждого человека по-настоящему счастливым. ' +
            '\n' +
            '\n' +
            'Но, к сожалению, в наше время для многих семей счастье и благополучие остается мечтой. Ведь если в семье болен ребенок или кто-то из близких, то страдает вся семья. А человек может быть счастлив, когда счастлива вся семья и близкие люди здоровы. Постоянно видя по телевидению запросы о помощи в оплате дорогостоящих операций, невозможно пройти мимо такой трагедии. ' +
            '\n' +
            '\n' +
            'Именно поэтому и возникла идея создания благотворительного фонда, который бы помогал семьям, попавшим в тяжелую жизненную ситуацию, и которые по тем или иным причинам не могут сами оплатить дорогостоящие операции или лечение. ' +
            '\n' +
            '\n' +
            'Каждый человек на определенном этапе своей жизни понимает – наступил тот самый момент, когда надо что-то сделать не только для себя, но и для тех, кто в этом действительно нуждается. Каждый из нас хоть раз в жизни задумывается о том, что он оставит после себя, что он сделал, чтобы помочь другим, что он сделал для будущего. И это важно прежде всего не только для самого себя, но и для наших детей, которые берут с нас пример, учатся у нас, как жить и правильно поступать. ' +
            '\n' +
            '\n' +
            'Именно поэтому и появился наш Благотворительный фонд содействия укреплению здоровья «Мир Здоровых Людей», чтобы помогать семьям в тяжелой жизненной ситуации помочь сохранить семью, не смотря на все трудности. ' +
            '\n' +
            '\n' +
            'Создание Фонда нацелено на достижение конкретных задач, за которые мы несем ответственность. Мы – это команда энергичных единомышленников.' +
            '\n' +
            '\n' +
            'Каждый из нас хоть раз в жизни задумывался о том, что он сделал, чтобы помочь другим, что он сделал для будущего. И это важно не только для нас, но и для наших детей, которые берут с нас пример!'


        return (
            <>
                <div className="gray-block tac">
                    {this.t('Наша самая большая сила заключается в доброте и любви нашего сердца,\n и тогда возможности добра станут безграничными…')}
                </div>
                <br/>
                <Row>
                    <Col xs={{span: 24}} xl={{span: 14}} className={'font-slim'}>
                        {this.t(text)}
                    </Col>
                    <Col xs={{span: 0}} xl={{span: 10}}>
                        {this.renderImage('/about/about-big.png', {width: '100%', className: 'about-img-lg'})}
                    </Col>
                    <Col xs={24} xl={{span: 0}}>
                        <br/>
                        <br/>
                        {this.renderImage('/about/about-sm.png', {width: '100%', className: 'about-img-sm'})}
                    </Col>
                </Row>

                <Partners/>
            </>
        )

    }

    render() {
        return this.getContent()
    }
}

export default About;