import Component from "@base/Component";

import {Swiper, SwiperSlide} from 'swiper/react';

import SwiperCore, {
    Virtual,
    Keyboard,
    Mousewheel,
    Navigation,
    Pagination,
    Scrollbar,
    Parallax,
    Zoom,
    Lazy,
    Controller,
    A11y,
    History,
    HashNavigation,
    Autoplay,
    EffectFade,
    EffectCube,
    EffectFlip,
    EffectCoverflow,
    Thumbs
} from "swiper"

SwiperCore.use([Virtual, Keyboard, Mousewheel, Navigation, Pagination, Scrollbar, Parallax, Zoom, Lazy, Controller, A11y, History, HashNavigation, Autoplay, EffectFade, EffectCube, EffectFlip, EffectCoverflow, Thumbs])


// https://swiperjs.com/swiper-api


const Carousel = class C extends Component {

    renderItem = (nr, item) => {
        return (
            <SwiperSlide key={nr}>
                {item}
            </SwiperSlide>
        )
    }

    initSlider = (conf = {
        effect: 'slide', //'slide' | 'fade' | 'cube' | 'coverflow' | 'flip',
        slidesPerView: 1,
        slidesPerColumn: 1,
        slidesPerGroup: 1,
        spaceBetween: 20,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: false,
        scrollbar: false,
    }) => {

        let items = [];

        this.props.items.map((c, i) => {
            items.push(this.renderItem(i, c))
        })

        this.content = (
            <div className={'news-page-carousel'}>
                <Swiper {...conf}>
                    {items}
                    <div className={'navigation-container'}>
                        <div className={'navigation-block'}>
                            {/*<div className={'swiper-pagination'}></div>*/}
                            <div className={'swiper-button-prev'}></div>
                            <div className={'swiper-button-next'}></div>
                        </div>
                    </div>
                </Swiper>
            </div>
        );
    }

    content = ''

    render() {
        this.initSlider()
        return this.content;
    }
}
export default Carousel;