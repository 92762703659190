import {Row, Col,Button} from 'antd';
import {Component} from "@base/Base";
import SocialLinks from "@base/widgets/SocialLinks";
import {Link} from 'react-router-dom'

class BaseFooter extends Component {

    t1 = '©{year} Благотворительный фонд содействия укреплению здоровья «Мир здоровых людей». Все права защищены'
    t2 = 'Политика конфиденциальности'

    title1 = 'Контакты'
    title2 = 'Фонд Мир здоровых людей'
    title3 = 'Присоединиться к\n Миру здоровых людей'
    nko = 'Свидетельство о регистрации НКО №1187700021442'

    render = () => this.getContent()


    renderSocialIcons = () => {
        return <SocialLinks/>
    }

    /**
     * get content
     */
    getContent = () => {
        let conf = {
            sm: {span: 24},
            md: {span: 10, offset: 2},
        };
        let conf2 = {
            sm: {span: 24},
            md: {span: 9, pull: 2}
        }

        let confX = {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 8}
        }

        let common = {offset: 2, span: 20};
        let param = {
            xs: common, sm: common, md: common, lg: common, xl: common, xxl: common
        }

        let $menuItems = this.app().getMenuItems();


        return (
            <div style={{textAlign: 'center'}}>

                <Button href={'/files/offers/Оферта.pdf'}  target={'_blank'} className={'grayBt'}>
                    ПУБЛИЧНАЯ ОФЕРТА
                </Button>

                <div className={'top-footer'}>
                    {this.renderImage('/default/bg-footer.svg', {className: 'round-bg'})}
                    <div className={'page-container'}>
                        <Row>
                            <Col {...param}>
                                <Row className={'footer-menu-container'}>
                                    <Col {...confX}>
                                        <h3>
                                            {this.t(this.title1)}
                                        </h3>
                                        <span>
                                    <b>
                                        {this.t(this.app().data.text.contact.address)} &nbsp;
                                    </b>

                                    <span className={'fsmd fontSlim'}>
                                        {this.t(this.app().data.text.contact.addressV)}
                                    </span>

                                    <br/>
                                    <br/>

                                    <b>
                                        {this.t(this.app().data.text.contact.tel)} &nbsp;
                                    </b>

                                    <span className={'fsmd fontSlim'}>
                                        {this.t(this.app().data.text.contact.telV)}
                                    </span>

                                    <br/>

                                    <b>
                                        {this.t(this.app().data.text.contact.email)} &nbsp;
                                    </b>

                                    <span className={'fsmd fontSlim'}>
                                        {this.t(this.app().data.text.contact.emailV)}
                                    </span>

                                    <br/>
                                    <br/>
                                    <br/>

                                    <p className={'fssm'}>
                                        {this.t(this.nko)}
                                    </p>

                                </span>
                                    </Col>
                                    <Col {...confX}>
                                        <h3>
                                            {this.t(this.title2)}
                                        </h3>
                                        {$menuItems.map((item, key) => {
                                            return <Link key={key} to={item.url}
                                                         className={'ant-btn ant-btn-link ant-btn-block'}>
                                                {this.t(item.label)}
                                            </Link>
                                        })}
                                    </Col>
                                    <Col {...confX}>
                                        <h3>
                                            {this.t(this.title3)}
                                            <br/>
                                            {this.renderSocialIcons()}
                                            <br/>
                                            <div className={'logo-img'}>
                                                {this.renderImage('/default/white-logo.svg', {png: 0})}
                                                <div className={'logo-text'}>
                                                    {this.t(this.app().data.logoText)}
                                                </div>
                                            </div>
                                        </h3>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className={'bottom-footer'}>
                    <div className={'page-container'}>
                        <Row>
                            <Col {...conf}>
                                {this.t(this.t1, {year: new Date().getFullYear()})}
                            </Col>

                            <Col className={'rules'} {...conf2}>
                                <a href={
                                    (this.app().data.lang === 'en' ? '/files/rules_en.pdf' : '/files/rules.pdf')
                                } target={'_blank'}>
                                    {this.t(this.t2)}
                                </a>
                            </Col>

                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}

export default BaseFooter
