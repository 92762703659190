import React, {useState, useEffect, useContext} from 'react';
import {Form, Input, Button, Select, Upload, Tooltip} from 'antd';
import {
    FileTwoTone,
    UploadOutlined,
    EllipsisOutlined,
} from '@ant-design/icons';

/**
 * FormHelper
 * @property FormHelper this.app.form
 */
const FormHelper = function () {

    /**
     * @type {App}
     */
    this.app = null;

    /**
     * set app
     * @param $a App
     * @returns {FormHelper}
     */
    this.setApp = ($a) => {
        this.app = $a;
        return this;
    }


    /**
     * set app
     * @returns {FormHelper}
     */
    this.new = () => {
        let $f = (new FormHelper());
        $f.app = this.app;
        return $f;
    }


    this.data = {};
    this.states = {};
    this.contextName = null;
    this.contextComponent = ($state) => {
        const FormHelperStateComponent = ($props) => {
            const context = useContext(this.contextName)
            this.states = context;
            return '';
        }
        return <FormHelperStateComponent {...$state} />
    }

    /**
     * get label by name
     * @param $name
     * @param $alternative
     * @returns {string|*}
     */
    this.getLabel = ($name, $alternative) => {
        let $label = this.states.labels[$name]

        if ($label === '' || $label === undefined) {
            return ($alternative === undefined ? $name.toUpperCase() : $alternative)
        }
        return $label;
    }

    /**
     * should input be disabled
     * @returns {*}
     */
    this.getDisabled = () => {
        // return this.states.loading
        return false
    }

    /**
     * default input config
     * @param $name
     * @param $localParams
     * @returns {{autoSize: boolean, showCount: boolean, prefix: JSX.Element, disabled: *, placeholder: (string|*), allowClear: boolean, suffix: string}}
     */
    this.defaultInputConfig = ($name, $localParams = {}) => {

        return Object.assign({
            //placeholder: this.getLabel($name),
            allowClear: true,
            disabled: this.getDisabled(),
            suffix: (true ? '' : (<EllipsisOutlined style={{display: 'none'}}/>)),
            prefix: '',
            onChange: (e, f) => {
                if (!e && !f)
                    return;
                let $value = (e || e.target) ?
                    (e.target ? e.target.value : e) :
                    (f || (f && f.target) ? (f.target ? f.target.value : f)
                        : undefined);

                if ($value !== undefined) {
                    this.data.attributes[$name] = $value
                    this.states.setAttributes(this.data.attributes)
                }
            }
        }, $localParams)
    }

    this.files = [];
    this.deletedFiles = [];

    /**
     * text input
     * @param $name
     * @param $files
     * @returns {JSX.Element}
     */
    this.formUploader = ($name = '', $files = []) => {
        const FormUploader = () => {
            this.files = this.files.concat($files)

            let $headers = {};

            if (this.app.api.getToken())
                $headers = Object.assign($headers, this.app.api.getTokenHeader())

            let $upConfig = {
                accept: '.pdf',
                disabled: this.getDisabled(),
                action: this.app.api.url() + '/file/upload',
                headers: $headers,
                listType: "picture", //picture, picture-card, text
                onPreview: ($data) => {
                    let $link = '';
                    if ($data.response) {
                        $link = $data.response.item.link
                    } else {
                        $link = $data.link
                    }
                    var win = window.open($link, '_blank');
                    win.focus();
                },
                onChange: ($data) => {
                    if ($data.file && $data.file.response && $data.file.response.item && $data.file.response.item.uid) {
                        if (this.files) {
                            let $isImg = $data.file.response.item.isImageUrl;
                            let $item = {
                                uid: $data.file.response.item.uid,
                                name: $data.file.response.item.name,
                                status: 'done',
                                isImageUrl: $isImg,
                            }
                            if ($isImg) {
                                $item['url'] = $data.file.response.item.url;
                                $item['thumbUrl'] = $data.file.response.item.url;
                            }

                            if (!this.files.includes($item.uid) && !this.deletedFiles.includes($item.uid))
                                this.files = this.files.concat([$item])
                        }
                    }
                },
                onRemove: ($item) => {
                    let $t = this

                    return new Promise((resolve, reject) => {
                        let $id = $item.response ? $item.response.item.uid : $item.uid;
                        this.app.record.delete($id, () => {
                            $t.files = $t.files.filter((item, pos) => {
                                let $p = $t.files.indexOf(item);
                                let $uid = $t.files[$p]['uid'];
                                return $uid !== $id
                            })
                            this.deletedFiles.push($id)
                            resolve(true)
                        }, '/file', false);
                    })
                },
                defaultFileList: [...this.files]
            }


            return (
                <div>
                    <Upload {...$upConfig} iconRender={() => <FileTwoTone/>}>
                        <Button disabled={this.getDisabled()} icon={<UploadOutlined/>}>
                            {this.getLabel($name)}
                        </Button>
                    </Upload>
                </div>
            )
        }

        return <FormUploader/>
    };

    /**
     * number input
     * @param $name
     * @param $inputParams
     * @param $itemParams
     * @returns {JSX.Element}
     */
    this.formNumberInput = ($name = '', $inputParams = {}, $itemParams) => {

        let $formClass = this

        const FormNumberInput = () => {
            let $params = Object.assign(this.defaultInputConfig($name), $inputParams)

            function formatNumber(value) {
                value += '';
                const list = value.split('.');
                const prefix = list[0].charAt(0) === '-' ? '-' : '';
                let num = prefix ? list[0].slice(1) : list[0];
                let result = '';
                while (num.length > 3) {
                    result = `,${num.slice(-3)}${result}`;
                    num = num.slice(0, num.length - 3);
                }
                if (num) {
                    result = num + result;
                }
                return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
            }

            class NumericInput extends React.Component {
                onChange = e => {
                    const {value} = e.target;
                    const reg = /^-?\d*(\.\d*)?$/;
                    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
                        this.props.onChange(value);
                    }
                };

                // '.' at the end or only '-' in the input box.
                onBlur = () => {
                    const {value, onBlur, onChange} = this.props;
                    let valueTemp = value;
                    if (value) {
                        if (value.charAt(value.length - 1) === '.' || value === '-') {
                            valueTemp = value.slice(0, -1);
                        }
                        onChange(valueTemp.replace(/0*(\d+)/, '$1'));
                        if (onBlur) {
                            onBlur();
                        }
                    }
                };

                render() {
                    const {value} = this.props;
                    const title = value ? (
                        <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
                    ) : (
                        '0.00'
                    );
                    return (
                        <Tooltip
                            trigger={['focus']}
                            title={title}
                            placement="topLeft"
                            overlayClassName="numeric-input"
                        >
                            <Input
                                {...this.props}
                                onChange={this.onChange}
                                onBlur={this.onBlur}
                                placeholder="0.00"
                                maxLength={25}
                            />
                        </Tooltip>
                    );
                }
            }

            class NumericInputForceNumber extends React.Component {
                constructor(props) {
                    super(props);
                    this.state = {value: ''};
                }

                onChange = value => {
                    this.setState({value});
                    $formClass.data.attributes[$name] = value;
                    setTimeout(() => {
                        $formClass.states.setAttributes($formClass.data.attributes)
                    }, 2000)
                };

                componentDidMount() {
                    let $val = ($formClass.data && $formClass.data.attributes && $formClass.data.attributes[$name] ?
                        $formClass.data.attributes[$name] : this.state ? this.state.value : '');
                    this.setState({
                        value: $val
                    })
                }

                render() {
                    return (
                        <NumericInput {...$params} name={$name} value={this.state.value} onChange={this.onChange}/>
                    );
                }
            }


            return this.formItem($name, <NumericInputForceNumber {...$params} />, $itemParams)
        }

        return <FormNumberInput/>
    };

    /**
     * text input
     * @param $name
     * @param $inputParams
     * @param $itemParams
     * @returns {JSX.Element}
     */
    this.formTextArea = ($name = '', $inputParams = {}, $itemParams) => {
        const FormTextArea = () => {
            let $params = Object.assign(this.defaultInputConfig($name, {
                showCount: true,
                autoSize: true,
            }), $inputParams)
            return this.formItem($name, <Input.TextArea {...$params}/>, $itemParams)
        }
        return <FormTextArea/>
    };

    /**
     * form Block
     * @param $block
     * @returns {JSX.Element}
     */
    this.formContentBlock = ($block) => {

        let FormContentBlock = (props) => {

            const [state, setState] = useState()

            this.contentBlockStates = {
                setState: (v) => setState(v)
            }

            if (state !== undefined && typeof state === 'object' && Object.keys(state).length > 0) {
                return props.call(state)
            }

            return '';
        }

        return <FormContentBlock call={$block}/>

        // const FormContentBlockMemo = React.memo(() => {
        //     return <FormContentBlock/>
        // })
        // return <FormContentBlockMemo/>
    };

    /**
     * dropdown input
     * @param $name
     * @param $inputParams
     * @param $itemParams
     * @returns {JSX.Element}
     */
    this.formDropDown = ($name = '', $options = [], $inputParams = {}, $itemParams = {}) => {
        const FormDropDown = () => {
            if (this.states.options !== undefined && this.states.options[$name] !== undefined) {
                $options = Object.assign(this.states.options[$name], $options);
            }

            let $o = [];

            Object.keys($options).map(($k) => $o.push({
                label: $options[$k],
                value: (isNaN(parseInt($k)) ? $k : parseInt($k))
            }));

            let $defaultParams = {
                options: $o,
                showSearch: true,
                optionFilterProp: "label",
            }

            let $dropDownParams = Object.assign(this.defaultInputConfig($name, $defaultParams), $inputParams)

            const $select = <Select {...$dropDownParams}/>

            return this.formItem($name, $select, $itemParams)
        }
        return <FormDropDown/>
    };

    /**
     * text input
     * @param $name
     * @param $inputParams
     * @param $itemParams
     * @returns {JSX.Element}
     */
    this.formTextInput = ($name = '', $inputParams = {}, $itemParams) => {
        const FormTextInput = () => {
            let $params = Object.assign(this.defaultInputConfig($name), $inputParams)
            return this.formItem($name, <Input {...$params} />, $itemParams)
        }
        return <FormTextInput/>
    };

    /**
     * submit button
     * @param $title
     * @param $inputParams
     * @param $itemParams
     * @returns {JSX.Element}
     */
    this.formSubmitButton = ($title = '', $inputParams = {}, $itemParams = {}) => {
        $inputParams = Object.assign({type: "primary", htmlType: "submit"}, $inputParams)
        $itemParams.rules = false;
        $itemParams.label = false;

        const FormSubmitButton = () => {
            let $defaultParams = {
                loading: this.states.loading,
            }
            let $params = Object.assign($defaultParams, $inputParams)
            const $bt = <Button {...$params} >{$title}</Button>
            return this.formItem('submit_button', $bt, $itemParams)
        }

        return <FormSubmitButton/>

    };

    /**
     * item
     * @param $name
     * @param $input
     * @param $itemParams
     * @returns {JSX.Element}
     */
    this.formItem = ($name, $input = '', $itemParams = {}) => {
        if ($itemParams.label === undefined)
            $itemParams.label = (<b>{this.getLabel($name)}</b>)
        if ($itemParams.name === undefined)
            $itemParams.name = $name
        if ($itemParams.rules === undefined || $name !== 'submit_button')
            $itemParams.rules = [{required: true, message: this.getLabel($name) + " - обязательно для заполнения"}]

        return (
            <Form.Item {...$itemParams}>
                {$input}
            </Form.Item>
        )
    };

    /**
     * render base form with its items
     * @param $options
     * @returns {JSX.Element}
     */
    this.renderForm = ($options = {}, $content = '') => {
        const BaseForm = () => {
            if (!$options.onFinish) {
                $options['onFinish'] = () => {
                    let $data = this.data.attributes;
                    let $files = []

                    if (this.files) {
                        this.files.map((k) => {
                            if (k.uid)
                                $files.push(k.uid)
                            return false;
                        })
                    }

                    $data['files'] = $files

                    setLoading(true)
                    this.app.record.save($data, () => {
                        setLoading(false)
                    })
                }
            }
            const formInstance = Form.useForm()[0];
            const FormHelperContext = React.createContext()
            this.contextName = FormHelperContext

            const [files] = useState([])
            const [labels, setLabels] = useState({})
            const [options, setOptions] = useState({})
            const [ready, setReady] = useState(true)
            const [loading, setLoading] = useState(false)
            const [attributes, setAttributes] = useState({})
            const [formContent, setFormContent] = useState('')

            const $states = {
                //inputs bunch
                formContent: formContent,
                setFormContent: (v) => setFormContent(v),

                //attributes & their values
                attributes: this.objectGetObjectFromString(attributes),
                setAttributes: (v) => {
                    if (v !== false) {
                        const $attributes = this.objectAssignAsString(this.objectGetObjectFromString(attributes), v);
                        this.data['attributes'] = this.objectGetObjectFromString($attributes)
                        setAttributes($attributes)
                        setLoading(false)
                    }
                },

                //options for selects
                options: this.objectGetObjectFromString(options),
                setOptions: (v) => {
                    if (v !== false) {
                        const $options = this.objectAssignAsString(this.objectGetObjectFromString(options), v);
                        this.data['options'] = $options
                        setOptions($options)
                    }
                },

                //files
                files: files,
                setFiles: (v) => {
                    if (v) {
                        this.files = v
                    }
                },

                //loading state
                loading: loading,
                setLoading: (v) => setLoading(v),

                //ready state
                ready: ready,
                setReady: (v) => setReady(v),

                //attribute labels & their values
                labels: labels,
                setLabels: (v) => setLabels(v),
            }


            useEffect(() => {

                const $attributes = this.objectGetObjectFromString(attributes);
                formInstance.setFieldsValue($attributes)
                if (this.contentBlockStates)
                    this.contentBlockStates.setState($attributes)
            }, [formInstance, attributes])

            var $formOptions = Object.assign({
                layout: "vertical",
                requiredMark: false,
            }, $options)

            return (
                <div>
                    <FormHelperContext.Provider value={$states}>
                        {this.contextComponent()}
                    </FormHelperContext.Provider>
                    <Form form={formInstance} {...$formOptions} >
                        {$content === '' ? formContent : $content}
                    </Form>
                </div>
            )
        }
        return <BaseForm {...$options} />
    }

    /**
     * @param $v
     * @returns {string}
     */
    this.objectGetObjectFromString = ($v, $alternative = null) => {
        return (typeof $v === 'string' ? JSON.parse($v) : ($alternative ? $alternative : $v))
    }

    /**
     * @param $1
     * @param $2
     * @returns {string}
     */
    this.objectAssignAsString = ($1, $2) => {
        return JSON.stringify(Object.assign($1, $2))
    }

}

export default (new FormHelper());
