import React from "react";
import AppContext from "@base/AppContext"
import {Link} from "react-router-dom"
import ApiHelper from "../admin/app/ApiHelper"
import {Image, Spin} from "antd";
import {
    EditOutlined
} from '@ant-design/icons';

/**
 * Base component
 */
class Component extends React.Component {

    static contextType = AppContext;

    /**
     * @type {ApiHelper}
     */
    API = ApiHelper;

    /**
     * default state data
     * @type {Readonly<S>}
     */
    data = {
        loaded: false,
        loadedImg: [],
    }

    /** @type {Component} */
    parent = this

    /**
     * translate
     * @param message
     * @param asString
     * @return {*}
     */
    t = (message, params = {}, asString = false, componentParams = false) => {
        if (message === undefined || message === '' || !message || typeof message !== 'string')
            return '';

        const Message = class T extends Component {

            parse = (message, params) => {

                Object.keys(params).map((k, v) => {
                    let r = params[k];
                    if (typeof message === 'object' && message.props.dangerouslySetInnerHTML)
                        message = message.props.dangerouslySetInnerHTML.__html

                    message = typeof message === 'string' ? message.replace('{' + k + '}', r) : message
                })

                message = typeof message === 'string' ? message.replace(new RegExp('\\n', 'g'), '<br/>') : message;

                if (typeof message === 'object' && message.dangerouslySetInnerHTML)
                    return this.renderHtml(message.dangerouslySetInnerHTML.__html);

                return (componentParams && !asString) ? (
                    <>
                        <span style={{display: 'contents'}} dangerouslySetInnerHTML={{__html: message}}/>
                    </>
                ) : message
            }

            render() {

                let textMessage = this.stripTags(this.props.text).replace(/(\r\n|\n|\r)/gm, "").toLowerCase();

                let endText = this.props.text;


                let app = this.app();
                if (app) {
                    let trans = app.getBulkData('translation', false)

                    if (trans) {
                        if (trans[textMessage]) {
                            endText = this.renderHtml(trans[textMessage])
                        }
                    }
                }


                return this.parse(endText, this.props.params)
            }
        }

        let c = <Message text={message} params={params}/>

        if (asString) {
            Object.keys(params).map((k, v) => {
                let r = params[k];
                message = message.replace('{' + k + '}', r)
            })

            c = message.replace(new RegExp('\\n', 'g'), '<br/>');
        }

        return c
    }

    /**
     * replace param names with values from object
     * @param string
     * @param params
     * @return {*}
     */
    parseParams = (string, params) => {
        Object.keys(params).map((k, v) => {
            let r = params[k];
            string = string.replace('{' + k + '}', r)
        })

        return string;
    }

    /** @return {AppBase} */
    app = () => this.context

    /**
     * get storage data by key
     * @param key
     * @param alternative
     * @return {null|*}
     */
    appGet = (key, alternative = null) => {
        let $st = this.app();
        if ($st && $st.state[key] !== undefined)
            return $st.state[key]
        return alternative;
    }

    /**
     * set storage data by key
     * @param {key:value}
     * @return {*}
     */
    appSet = (kv) => {
        let $st = this.app();
        if ($st && $st.state) {
            let $data = Object.assign($st.state, kv)
            return $st.set($data)
        }
    }

    /**
     * constructor function
     * @param props
     */
    constructor(props) {
        super(props);
        this.to = this.to.bind(this);
        this.style('domain', window.location.protocol + '//' + window.location.hostname)
        this.state = this.data
    }

    /**
     * component did update
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setPathName()
        this.data = this.state
        if (!this.data.loaded)
            this.setState({
                loaded: true
            })
    }

    setPathName = () => {
        if (!this.app())
            return;
        let pathname = this.app().data.pathname

        if (pathname !== window.location.pathname) {
            pathname = window.location.pathname
            this.app().set({
                pathname: pathname
            })
            window.scrollTo(0, 0)
            if (this.app().data.headerMenu) {
                this.app().data.headerMenu.set({
                    burgMenuActive: false
                })
            }
        }
    }

    onMount = (component) => {

    }

    /**
     * component did mount
     */
    componentDidMount() {
        this.setPathName()
        this.setState({loaded: true})
        this.set(this.state)
        this.onMount(this)
    }

    /**
     * set state method
     * @param kv
     * @param remember
     */
    set = (kv = {}, remember = false) => {
        this.data = Object.assign(this.data, kv)
        this.setState(this.data)
    }

    /**
     * render image
     * @param path
     * @param params
     * @param local
     * @return {JSX.Element}
     */
    renderImage = (path, params = {png: true}, local = true, bg = false, asSlide = true) => {
        let $host = window.location.protocol + '//' + window.location.host

        let $nl = false
        if (local)
            $nl = path !== '' && path !== undefined ? path.split('.svg') : [];
        let folder = 'img';
        if ($nl.length > 1 && params.png === true && local) {
            path = path + '.png'
        }
        let img = $host + '/' + folder + '/' + path;
        delete params.png

        return this.getImgUrl((local ? img : path), (base64) => {

            let exclude = [
                '/news/7.jpg',
                '/news/7_1.jpg',
            ];

            if (bg && !exclude.includes(path)) {
                let loaded = this.data.loadedImg
                let id = (local ? img : path)
                return (
                    <div style={{
                        backgroundImage: "url(" + (local ? img : path) + ")",
                        backgroundSize: (bg.backgroundSize ? bg.backgroundSize : '100%'),
                        backgroundRepeat: 'no-repeat',
                        height: bg.height,
                        width: bg.width,
                        backgroundPosition: 'center',
                        backgroundColor: loaded.includes(id) ? '' : 'rgba(189,189,189,0.62)',
                        borderRadius: '15px',
                    }}>
                        {asSlide ?
                            <Image
                                // width={'auto'}
                                // height={125}
                                src={base64.replace('&thumb=1', '')}
                                style={{opacity: 0, width: bg.width, height: bg.height}}
                                onLoad={() => {
                                    loaded.push(id)
                                    this.set({loadedImg: loaded})
                                }}
                            /> :
                            <img onLoad={() => {
                                loaded.push(id)
                                this.set({loadedImg: loaded})
                            }} src={base64} style={{opacity: 0, width: bg.width, height: bg.height}}/>
                        }
                    </div>
                )
            }

            return <img src={base64} {...params} />
        })
    }

    getImgUrl = (url, callback) => {
        return callback(url);
    }

    /**
     * strip tags
     * @param string
     * @return {*}
     */
    stripTags = (string) => {
        return string.replace(/(<([^>]+)>)/gi, "")
    }

    /**
     * render page title
     * @param $title
     * @param $info
     * @param $hint
     * @param $wrap
     * @return {JSX.Element}
     */
    renderTitle = ($title = '', $info = '', $hint = '', $wrap = false) => {
        let title = <h1>{this.t($title)}</h1>
        if ($wrap) {
            title = $wrap
        }
        return (
            <div className={'page-title-container'}>
                {title}
                <p>{this.t($info)}</p>
                <span>{this.t($hint)}</span>
            </div>
        )
    }

    /**
     * set css var value
     */
    style = (param, value) => {
        document.documentElement.style.setProperty(
            '--' + param, value
        )
    }

    to = (url = '/') => {
        if (url !== window.location.pathname) {

            class To extends Component {

                componentDidMount() {
                    let l = document.getElementById('route-app-link-to')
                    if (l)
                        l.click()
                }

                render() {
                    let url = this.props.url
                    let l = <Link className={'hidden'} id={'route-app-link-to'} from={window.location.pathname}
                                  path={url} to={url}>
                        GOTO:{url}
                    </Link>

                    return l
                }
            }

            this.app().set({
                bodyParams: {},
                to: (
                    <>
                        <To url={url}/>
                    </>
                )
            })
        }
    }

    /**
     * rub sign
     * @param params
     * @return {JSX.Element}
     */
    getRubSign = (params = {className: 'rub-sign-svg'}) => {
        return this.renderImage('/rub.svg', Object.assign(params, {
            style: {
                position: 'relative',
                top: '-1px',
                width: '18px',
                display: 'inline-block',
            }
        }))
    }

    /**
     * number format
     * @param num
     * @return {string}
     */
    numberFormat = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }

    /**
     * render string as html
     * @param str
     * @return {JSX.Element}
     */
    renderHtml = (str) => {
        return <span style={{display: 'contents'}} dangerouslySetInnerHTML={{__html: str}}/>;
    }

    /**
     * load news component
     * @param component
     */
    loadNewsData = (component) => {
        let items = this.app().getBulkData('article', false);
        if (items) {
            let news = {}
            let info = {}
            let nk = 1;
            let ik = 1;
            Object.entries(items).map((item, key) => {
                item = item[1]
                let imgs = []

                Object.entries(item.images).map((e, k) => {
                    return imgs.push(item.images[k].url)
                })
                key = (item.type === 1 ? nk : ik)

                let data = {
                    // id: item.id,
                    id: key,
                    title: item.title,
                    date: item.date,
                    text: item.text,
                    images: imgs,
                }
                if (item.type === 1) {
                    news[key] = data
                } else {
                    info[key] = data
                }
                if (item.type === 1) {
                    nk = nk + 1;
                } else {
                    ik = ik + 1;
                }
            })

            let d = {
                news: news,
                info: info,
            };

            component.set(d)
        }

        return [];
    }
}

export default Component;