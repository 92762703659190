/**
 * user helper
 * @property UserHelper this.app.api
 */
const UserHelper = function () {

    /**
     * @type {App}
     */
    this.app = null;

    /**
     * set app
     * @param $a
     * @returns {UserHelper}
     */
    this.setApp = ($a, $callback) => {
        this.app = $a;
        return this;
    }

    this.attributes = {
        id: null,
        email: null,
        first_name: null,
        last_name: null,
        fullName: null,
    };

    this.id = this.attributes.id;
    this.isGuest = true;

    /**
     * get user fullname
     * @returns {string}
     */
    this.getFullName = () => {
        if (this.app.state && this.app.state.user) {
            return (this.app.state.user.fullName !== null ? this.app.state.user.fullName : 'Guest')
        }
        return 'Guest';
    };

    /**
     * set helper attributes
     * @param $data
     * @returns {UserHelper}
     */
    this.setAttributes = ($data = {}) => {
        if (Object.keys($data).length !== 0) {
            this.attributes = $data;
        }
        this.id = this.attributes.id;

        this.isGuest = !(this.id && this.id > 0);

        return this;
    }

    /**
     * check user auth
     * @param $callback
     */
    this.checkUser = ($callback) => {
        if (!this.attributes.id) {
            return this.app.api.get('/user/info', ($response) => {
                this.setAttributes($response)
                this.app.api.setLoadingState(false)
                if ($response.id) {
                    this.app.setState({user: $response, refresh: true});
                }
                if (typeof $callback === 'function')
                    return $callback($response)
            });
        }
        return false;
    }

    /**
     * auth user bu username, password and remember status
     * @param {username,password,remember} $data
     * @param $callback
     */
    this.auth = ($data, $callback) => {
        this.app.api.removeToken();
        $data = Object.assign($data, {v: this.app.api.uniqueId()})
        this.app.api.request('post', '/user/login', $data, ($res) => {
            this.app.api.setToken($res.access_token, $data.remember);
            delete $res.access_token;
            this.app.user.setAttributes($res)
            this.app.setState({user: $res});
            if (typeof $callback === 'function') {
                $callback($res)
            }
        })
    }

    /**
     * logout
     * @param $callback
     */
    this.logOut = ($callback) => {
        this.app.setState({user: false});
        this.app.api.get('/user/logout?t=' + this.app.api.getToken(), ($result) => {
            if ($result.success) {
                this.setAttributes({id: false})
                this.app.api.removeToken();
                this.app.setState({user: false});
            }
            this.app.setState({loading: false});
            if (typeof $callback === 'function')
                $callback($result)
        })
    }

}

export default (new UserHelper());
