import Component from "@base/Component";
import {Row, Col, Button} from "antd";
import NewsItem from "./NewsItem";


class Info extends Component {


    allNewsBtn = (params) => {
        return <Button onClick={()=>{
            this.to('/blog')
        }}  {...params} type="ghost" shape="round" size={'large'}>
            {this.t('Все статьи')}
        </Button>
    }

    renderHeader = () => {
        let conf = {
            md: {span: 12, offset: 0},
            className: 'left'
        };
        return (
            <div className={'header'}>
                <Row justify={'center'}>
                    <Col {...conf} xs={{span: 24, offset: 0}} sm={{span: 17, offset: 0}} lg={{span: 14, offset: 0}}>

                        <h2>
                            {this.t('Полезная информация')}
                        </h2>

                    </Col>
                    <Col {...conf} xs={{span: 0, offset: 0}} sm={{span: 7, offset: 0}} lg={{span: 10, offset: 0}}
                         className={'more-button right'}>
                        {this.allNewsBtn()}
                    </Col>
                </Row>
            </div>
        )
    }

    render() {

        return (
            <div className={'home-page-info'}>
                {this.renderHeader()}

                <NewsItem  data={this.props.data} limit={4} type={'info'} />

                <Row gutter={35} align={'middle'} justify={'center'}>
                    <Col xs={{span: 24, offset: 0}} sm={{span: 0, offset: 0}} className={'more-button'}>
                        {this.allNewsBtn({block: true})}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Info;