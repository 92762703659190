import Component from "@base/Component";
import {Button, Col, Modal, Row, Space} from "antd";
import CustomTabs from "@base/widgets/CustomTabs";

import {CheckOutlined} from "@ant-design/icons";

class OurWards extends Component {

    data = {
        text: {
            title: 'Наши подопечные'
        },
        tabMenu: "",
        tabs: false,
        items: null
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let items = {}
        if (!this.data.items) {
            let data = this.app().getBulkData('ourWard',false)
                if (data) {
                    Object.entries(data).map((r) => {
                        let e = r[1]
                        if (!items[e.year])
                            items[e.year] = []
                        items[e.year].push({
                            img: (e.image && e.image[0] ? e.image[0].thumb : ''),
                            name: e.name,
                            date: e.date,
                            city: e.region,
                            diagnosis: e.diagnosis,
                            cost: e.cost,
                            story: e.history,
                            price: e.cost,
                            cost_ue: e.cost_eu,
                        })
                    });
                }
                this.set({
                    items: items
                })

        }
    }

    /**
     * render item
     * @type item {OurWards}
     * @param index
     * @return {JSX.Element}
     */
    renderItem = (item, index) => {

        let gridConf = {
            xs: {span: 20, offset: 2},
            sm: {span: 12, offset: 0},
            md: {span: 12, offset: 0},
            lg: {span: 8, offset: 0},
            xl: {span: 6, offset: 0}
        }

        return <Col {...gridConf} key={index}>
            <div className={'item'} onClick={() => {
                this.modal(index, item)
            }}>
                <div className={'image-container'}>
                    {this.renderImage(item.img, {}, false, {
                        backgroundSize: 'cover'
                    }, false)}
                </div>
                <h2>
                    {item.name}
                </h2>
                <div>
                    {/*<div>*/}
                    {/*    <b>{this.t('Диагноз')}</b> : {item.diagnosis}--*/}
                    {/*</div>*/}
                    <div className={'gathering-closed'}>
                        <div className={'check'}>
                            <CheckOutlined/>
                        </div>
                         {this.t('Сбор закрыт')}
                    </div>
                    <p>
                        {this.numberFormat(item.cost)} {this.getRubSign()}
                    </p>
                </div>
            </div>
        </Col>
    }

    initContent = (d) => {

        let years = [];
        let data = d;
        let tabs = [];

        Object.keys(data).map((key, index) => {
            years.push(key)
        })

        years.map((year, i) => {

            let content = []

            data[year].map((e, k) => {
                content.push(this.renderItem(e, k))
            })

            tabs.push({
                label: year,
                content: <Row gutter={{
                    xs: 20,
                    sm: 20,
                    md: 40,
                    lg: 40,
                    xl: 40,
                    xxl: 40,
                }} key={year} className={'tab-items-container'}>
                    {content}
                </Row>
            })
        })

        let params = {
            title: false,
            menuClass: 'our-wards custom-tabs',
            containerClass: '',
            titleClass: 'tar',
            extractMenu: {
                component: this,
                state: 'tabMenu',
            },
            onChangeTab: (tab, component) => true,
            onMount: (component) => true
        }

        let tab = <CustomTabs data={tabs} {...params}/>

        let titleRowConf = {
            1: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24},
                lg: {span: 24},
                xl: {span: 12},
            },
            2: {
                xs: {span: 24},
                sm: {span: 24},
                md: {span: 24},
                lg: {span: 24},
                xl: {span: 12},
            }
        }

        let c = (
            <div className={'our-wards-page'}>
                {
                    this.renderTitle('', '', '', (
                        <Row align={'middle'} justify={'left'}>
                            <Col {...titleRowConf[1]}>
                                <h1>{this.t(this.data.text.title)}</h1>
                            </Col>
                            <Col {...titleRowConf[2]}>
                                {this.data.tabMenu.menu}
                            </Col>
                        </Row>
                    ))
                }
                <div className={'wth-sep'}></div>
                {tabs.length === 0 ? (
                    <div className={'text-muted'}>
                        {this.t('Нет данных')}
                    </div>
                ) : tab}
            </div>
        )

        return c
    }

    render() {
        if (!this.data.items)
            return ''
        return this.initContent(this.data.items)
    }


    modal = (id, data) => {

        const M = class X extends Component {

            data = {visible: true}

            render() {


                let data = this.props.d

                let id = this.props.id;

                if (!data)
                    return ''

                let mc = {
                    footer: false,
                    title: false,
                    visible: this.data.visible,
                    onOk: () => this.set({visible: false}),
                    onCancel: () => {
                        this.set({visible: false})
                        this.app().set({modal: ''})
                    },
                    closeIcon: <div className={'close-modal-x'}>×</div>,
                    className: 'main-carousel-modal',
                    destroyOnHide: true
                }

                let helpBt = <Button block className={'yellowBt'} onClick={() => {
                    this.set({visible: false})
                }}>
                    {this.t('OK')}
                </Button>

                let gridConf = {
                    1: {xs: {span: 24}, sm: {span: 24}, md: {span: 24}, lg: {span: 6}, xl: {span: 5}},
                    2: {
                        xs: {span: 24},
                        sm: {span: 24},
                        md: {span: 24},
                        lg: {span: 12, offset: 1},
                        xl: {span: 14, offset: 0}
                    },
                    3: {xs: {span: 24}, sm: {span: 24}, md: {span: 24}, lg: {span: 5}, xl: {span: 5}},
                }

                return (
                    <Modal {...mc} >

                        <Row align={'center'} justify={'middle'}>
                            <Col {...gridConf[1]}>
                                <div className={'round-img'} style={{background: "url(" + data.img + ")"}}></div>
                            </Col>
                            <Col {...gridConf[2]} className={'info'}>
                                <h1>
                                    {data.name}
                                </h1>
                                <p className="font-slim">
                                    {data.date} - {data.city}
                                </p>
                                <span className="font-slim">
                                    {this.t('Диагноз:')} &nbsp;
                                </span>

                                <b>{data.diagnosis}</b>

                            </Col>
                            <Col {...gridConf[3]} className={'info tac'}>
                                <div>
                                    {this.t('Стоимость')}
                                </div>
                                <h2>
                                    {this.numberFormat(data.price)} {this.getRubSign()}
                                </h2>
                            </Col>
                        </Row>
                        <br/>
                        <div className="modal-separator"></div>
                        <br/>
                        <div className="font-slim fs-md">
                            {this.renderHtml(data.story)}
                        </div>
                        <br/>
                        <br/>

                        <Row>
                            <Col xs={{span: 14, offset: 5}} lg={{span: 14, offset: 5}}>
                                {helpBt}
                            </Col>
                        </Row>
                    </Modal>
                )
            }
        }

        this.app().set({
            modal: <M id={id} d={data}/>
        })
    }

}

export default OurWards;
