import Component from "@base/Component";
import Logo from "../../../app/widgets/Logo";
import {Row, Col, Button, Space} from "antd";
import {Link} from "react-router-dom";


import {EyeOutlined} from '@ant-design/icons';

class BaseHeader extends Component {

    data = {
        burgMenuActive: false,
        menu: "",
        toggleLangDropdown: false,
    }


    toggleLangDropdown = () => {
        this.set({
            toggleLangDropdown: !this.data.toggleLangDropdown
        })
    }

    initMenu = () => {
        if (!this.data)
            return '';
        let spanConf = {
            1: {xxl: {span: 4}, xl: {span: 12}, lg: {span: 12}, md: {span: 12}, sm: {span: 12}, xs: {span: 12}},
            2: {xxl: {span: 19, offset: 0}, xl: {span: 0}, lg: {span: 0}, md: {span: 0}, sm: {span: 0}, xs: {span: 0}},
            3: {
                className: 'switchLanguageContainer',
                xxl: {span: 1}, xl: {span: 12}, lg: {span: 12}, md: {span: 12}, sm: {span: 12}, xs: {span: 12}
            },
            4: {xxl: {span: 0}, xl: {span: 24}, lg: {span: 24}, md: {span: 0}, sm: {span: 0}, xs: {span: 0}}
        }


        let logo = (
            <Link to={'/'} className={'header-logo'}>
                {/*{this.renderImage('logo.svg', {className: 'logo-image'})}*/}
                {<Logo text={this.t(this.app().data.logoText)}/>}
            </Link>
        )

        let menus = {
            big: (
                <Row className={'menu-lg-container'}>
                    <Col {...spanConf['1']}>
                        <Row>
                            <Col xs={{span: 4}} sm={{span: 4, offset: 1}} md={{span: 5}} lg={{span: 0}}>
                                <div className={'burg-container ' + (this.data.burgMenuActive ? 'active' : '')}
                                     onClick={() => this.toggleBurg()}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </Col>
                            <Col xs={{span: 20}} sm={{span: 13, pull: 1}} md={{span: 10}} lg={{span: 0}}>
                                {logo}
                            </Col>
                            <Col xs={{span: 0}} sm={{span: 0}} md={{span: 0}} lg={{span: 8}} xl={{span: 7}}
                                 xxl={{span: 20}}>
                                {logo}
                            </Col>
                        </Row>
                    </Col>
                    <Col {...spanConf['2']}>
                        {this.renderMenuItems()}
                    </Col>
                    <Col {...spanConf['3']} style={{display: 'inline-block'}}>

                        <Row className={'opacity'} style={{display: 'none'}}>
                            <Col xs={{span: 0}} md={{span: 24}}>
                                <Button type={'link'} icon={<EyeOutlined className={'eye-btn-ico'}/>}
                                        className={'eye-btn'}>
                                    {this.t('Версия для слабовидящих')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className={'opacity'} style={{display: 'none'}}>
                            <Col xs={{span: 24}} md={{span: 0}}>
                                <Button type={'link'} icon={<EyeOutlined className={'eye-btn-ico-small'}/>}
                                        className={'eye-btn-small'}>
                                </Button>
                            </Col>
                        </Row>

                        <Button onClick={() => this.toggleLangDropdown()} type={'link'}
                                style={{backgroundImage: 'url(/img/' + (this.app().data.lang === 'ru' ? 'ru.png' : 'en.svg')}}
                                title={this.app().data.languages[this.app().data.lang]}
                                className={'lang-switch-btn ru ' + this.app().data.lang}>
                            &nbsp;
                        </Button>
                        <div className={'toggleLangDropdown-container ' + (this.data.toggleLangDropdown ? 'open' : '')}>
                            <Button onClick={() => {
                                this.app().switchLanguage()
                                this.toggleLangDropdown()
                            }} type={'link'}
                                    style={{backgroundImage: 'url(/img/en.svg)'}}
                                    title={this.app().data.languages.en}
                                    className={'lang-switch-btn ' + (this.app().data.lang === 'en' ? 'active' : '')}>
                                &nbsp;
                            </Button>

                            <Button onClick={() => {
                                this.app().switchLanguage()
                                this.toggleLangDropdown()
                            }} type={'link'}
                                    style={{backgroundImage: 'url(/img/ru.png)'}}
                                    title={this.app().data.languages.ru}
                                    className={'lang-switch-btn ru ' + (this.app().data.lang === 'ru' ? 'active' : '')}>
                                &nbsp;
                            </Button>
                        </div>
                    </Col>
                </Row>
            ),
            md: (
                <Row>
                    <Col {...spanConf['4']} className={'menu-md-container tac'}>
                        <Space size={25}>
                            {
                                this.menuButtons.map((value, index) => {
                                    return (
                                        <div key={index}>
                                            {value}
                                        </div>
                                    )
                                })
                            }
                        </Space>
                    </Col>
                </Row>
            ),
            sm: (
                <div className={'burg-menu-links'}>
                    {
                        this.menuButtons.map((value, index) => {
                            return (
                                <div key={index}>
                                    {value}
                                </div>
                            )
                        })
                    }
                </div>
            )
        }


        return (
            <div className={'app-header'}>
                <div style={{position: 'relative', top: '-22px', width: '95%', marginLeft: '2.5%'}}>
                    {menus.big}
                </div>
                {menus.md}
                <Row>
                    <Col style={{textAlign: 'center', position: 'relative', zIndex: 4}} xs={{span: 24}}
                         md={{span: 24}} lg={{span: 0}}>
                        {this.data.burgMenuActive ? (
                            menus.sm
                        ) : ''}
                    </Col>
                </Row>
            </div>
        )
    }

    toggleBurg = () => this.set({burgMenuActive: !this.data.burgMenuActive})

    renderMenuItems = () => {
        this.menuButtons = []
        return (
            <Row gutter={[50, 0]}>
                {
                    this.app().getMenuItems().map((item, key) => {
                        if (this.app().data.lang !== 'ru' && item.url === this.app().data.menuLinks.needHelp)
                            return ''

                        let uclass = item.url.replace('/', '_')
                        if (this.app().data.pathname === item.url)
                            uclass = uclass + ' active';

                        let btn = <Link to={item.url} key={key} type={'link'}
                                        className={'ant-btn ant-btn-link ant-btn-block btn-link btn-link ' + uclass}
                        >
                            <span>
                                {this.t(item.label)}
                            </span>
                        </Link>;
                        if (this.menuButtons.length < 7)
                            this.menuButtons.push(btn)

                        return (
                            <div key={key} className={'header-link-item-big'}>
                                {btn}
                            </div>
                        )
                    })
                }
            </Row>
        )

    }

    onMount = () => {
        this.app().set({
            headerMenu: this,
        })
    }

    render() {

        return this.initMenu()
    }

    btnGridConf = {
        span: {0: 1, 1: 4, 2: 2, 3: 1, 4: 3, 5: 2, 6: 2},
        offset: {0: 1, 1: 1, 2: 0, 3: 2, 4: 3, 5: 0, 6: 0}
    }

    menuButtons = [];
}

export default BaseHeader