import Component from "@base/Component";
import NEWS_DATA from "@base/data/News";
import {Row, Col, Card} from "antd";


class NewsItem extends Component {

    renderItem = (data, type, index = false) => {
        let conf = {
            xs: {span: 24, offset: 0},
            sm: {span: 12, offset: 0},
            md: {span: 12, offset: 0},
            lg: {span: 8, offset: 0},
            xl: {span: 6, offset: 0},
            key: data.id
        };

        let url = type === 'news' ? '/news/' : '/info/';
        let id = (index !== false ? index : data.id)


        let totalChars = parseInt(this.stripTags(data.title).length / 20)
        let title = data.title;

        if (data.text === null || data.text === undefined) {
            data.text = ''
        }

        return (
            <Col {...conf}>
                <Card onClick={() => this.to(url + id)}>
                    <div className={'item item-' + id + ' ' + type + '-id-' + id}>
                        <div className={'image-contain'}>
                            {this.renderImage(data.images[0], {png: true, width: '100%'}, false, {
                                width: '100%', backgroundSize: '100% 100%'
                            })}
                        </div>

                        {data.date && type === 'news' ? (<div className={'date'}>{data.date}</div>) : <br/>}

                        <h3>
                            {title}
                        </h3>
                        <p>
                            {this.stripTags(data.text?.slice(0, (totalChars > 0 ? 80 : 100))) + ' ...'}
                        </p>
                    </div>
                </Card>
            </Col>
        )
    }


    render() {
        let type = this.props.type ? this.props.type : 'news'
        const ITEMS = this.props.data[type];

        if (!ITEMS)
            return ''

        let x = 0
        let limit = this.props.limit
        let $items = {};

        Object.entries(ITEMS).map((entry, i) => {
            x++;

            let index = entry[0]
            let e = entry[1]
            if (limit !== undefined) {
                if (x <= limit)
                    $items[index] = this.renderItem(e, type, index);
            } else {
                $items[index] = this.renderItem(e, type, index);
            }
            return false;
        })

        return (
            <Row gutter={35} align={'top'} justify={'left'}>
                {Object.keys($items).map((v, i) => {
                    return $items[v]
                })}
            </Row>

        )
    }
}

export default NewsItem;
