import Component from "@base/Component";
import {Row, Col} from "antd";
import {PlayCircleOutlined} from '@ant-design/icons';


class Video extends Component {

    data = {
        ready: false,
        played: false
    }


    render() {
        if (!this.data) {
            return ''
        }


        let $app = this.app();

        if ($app.data.lang !== 'ru') {
            return ''
        }

        let vid = document.getElementById("report-video-ru");

        let conf = {
            xs: {span: 24},
            sm: {span: 12},
            md: {span: 12},
            lg: {span: 12},
        }

        return (
            <Row
                align={'middle'} justify={'center'}
                className={'home-page-report-video ' + (this.data.ready ? '' : 'cover')}>
                <Col {...conf} className={'vid-container'}>
                    {this.data.played ? '' : <div
                        onClick={() => {
                            vid.play()
                            vid.setAttribute("controls", "true")
                            this.set({played: true})
                            this.set({ready: true})
                        }}
                        className={'play-btn'}/>
                    }
                    <video
                        className={(this.data.played ? 'play' : '')}
                        id={'report-video-ru'}
                        // controls
                    >
                        <source src={'/files/report_ru.mp4#t=0'} type="video/mp4"/>
                    </video>
                </Col>
            </Row>
        )
    }

}

export default Video;
