import Component from "@base/Component";
import {Row, Col, Button} from "antd"
import {Link} from "react-router-dom"

class Index extends Component {

    static TYPE_PROGRAM = 1;
    static TYPE_PROJECT = 2;

    /**
     * get type labels
     * @param type
     * @return {{}|*}
     */
    getTypeLabel = (type = false) => {

        let arr = {}

        arr[Index.TYPE_PROGRAM] = this.t('Программа')
        arr[Index.TYPE_PROJECT] = this.t('Национальный проект')

        if (arr[type])
            return arr[type]

        return arr;
    }

    data = this.props.data

    renderContent = () => {

        let items = []

        let grid = {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24},
            lg: {span: 12},
            xl: {span: 8},
        };

        for (let i = 1; i < 8; i++) {

            this.getImgUrl("/img/projects/" + i + ".png", (base64) => {
                let style = {
                    style: {
                        backgroundImage: "url(/img/projects/bg.png), url("+base64+")",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "top right"
                    }
                }

                items.push((
                    <Col {...grid} key={i} className="item-container">
                        <Link {...style} to={'/projects/' + i} className={'content'}>

                            <label className={'type-' + this.data.text[i].type}>
                                {this.getTypeLabel(this.data.text[i].type)}
                            </label>

                            <h2 className={'title'}>
                                {this.data.text[i].title}
                            </h2>
                            <div className={'description'}>
                                {this.data.text[i].description}
                            </div>
                        </Link>
                    </Col>
                ))
            })
        }

        let hgrid = {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24},
            lg: {span: 12},
            xl: {span: 12},
            xxl: {span: 12},
        }

        // let archiveBt = <Button onClick={() => this.to('/projects-archive')} type={'ghost'} shape={'round'}
        //                         size={'large'}>
        //     {this.t('Завершенные программы')}
        // </Button>;

        return (
            <>
                <Row justify={'center'} align={'middle'}>
                    <Col {...hgrid}>
                        {this.renderTitle(this.data.text.title)}
                    </Col>
                    <Col {...hgrid} xs={{span: 0}} sm={{span: 0}} md={{span: 0}} className={'tar'}>
                        {/*{archiveBt}*/}
                    </Col>
                </Row>
                <Row gutter={40} align={'middle'}>
                    {items}
                </Row>

                <Row>
                    <Col {...hgrid} lg={{span: 0}} xl={{span: 0}} xxl={{span: 0}} className={'tac'}>
                        {/*<br/>*/}
                        {/*{archiveBt}*/}
                        {/*<br/>*/}
                        {/*<br/>*/}
                        {/*<br/>*/}
                    </Col>
                </Row>

                <h2 className={'tac'}>
                    {this.t('Вместе помогать — легко!')}
                </h2>
            </>
        )
    }

    render() {
        return this.renderContent()
    }
}

export default Index;