//
import React from "react";
import {Router} from "react-router-dom";
//
import './style.css';
import 'antd/dist/antd.css';
import {Modal, Drawer} from 'antd'
import {ConfigProvider} from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
//
import {AppProvider} from "./context/AppContext";
import UserHelper from "./app/UserHelper";
import ApiHelper from "./app/ApiHelper";
import RouteHelper from "./app/RouteHelper";
import ContentHelper from "./app/ContentHelper";
import RecordHelper from "./app/RecordHelper";
import FormHelper from "./app/FormHelper";
import InputHelper from "./app/InputHelper";
//
import history from "./context/History";
import BaseLayout from "./content/BaseLayout";

/**
 * base Init
 */
class AdminBase extends React.Component {

    /**
     * constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            siteSize: 'large',
            appDrawer: '',
            name: 'WHP',
            loading: true
        };
        this.user = UserHelper.setApp(this, (h) => {
            h.checkUser()
            return h;
        });
        this.route = RouteHelper.setApp(this, history);
        this.content = ContentHelper.setApp(this);
        this.api = ApiHelper.setApp(this);
        this.record = RecordHelper.setApp(this);
        this.form = FormHelper.setApp(this);

        /**
         * @type {InputHelper}
         */
        this.HInput = InputHelper.setApp(this);
    }

    /**
     * ROUTE
     * @type {RouteHelper}
     */
    route;

    /**
     * API
     * @type {ApiHelper}
     */
    api;

    /**
     * FORM
     * @type {FormHelper}
     */
    form;


    /**
     * USER
     * @type {UserHelper}
     */
    user;

    /**
     * CONTENT
     * @type {ContentHelper}
     */
    content;

    /**
     * RECORD
     * @type {RecordHelper}
     */
    record;

    /**
     * system info message
     * @param $m
     */
    mes = ($m = '') => {
        const modal = Modal.info();
        modal.update({
            title: 'INFO',
            content: $m,
        });
    }

    /**
     * system confirm
     * @param $title
     * @param $message
     * @param $callback
     */
    confirm = ($title = '', $message = '', $callback) => {
        const modal = Modal.confirm();
        return modal.update({
            title: $title,
            content: $message,
            okText: 'Ok',
            cancelText: 'Отмена',
            onCancel: modal.hideModal,
            onOk: () => {
                return (typeof $callback === 'function' ? $callback(modal) : modal.hideModal)
            }
        });
    }

    render() {
        return (
            <Router history={history}>
                <AppProvider value={this}>
                    <ConfigProvider locale={ruRU}>
                        <BaseLayout/>
                    </ConfigProvider>
                </AppProvider>
            </Router>
        );
    }

    /**
     * drawer
     * @param $title
     * @param $content
     * @param $show
     * @return {JSX.Element}
     */
    drawer = ($title = '', $content = '', $show = false, $options = {}) => {

        let $defaults = {placement: 'top', closable: true};
        let $config = Object.assign($defaults, $options)
        delete $config.contentOnShow

        const AppDrawer = () => {
            const [visible, setVisible] = React.useState($show);
            const [title] = React.useState($title);
            const [content] = React.useState($content);

            const onClose = () => {
                setVisible(false);
            };

            $config['title'] = title

            return (
                <>
                    <Drawer {...$config} onClose={onClose} visible={visible}>
                        {content}
                    </Drawer>
                </>
            );
        };

        return <AppDrawer/>
    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        let $user = (this && this.user && !this.user.isGuest)
        let $update = (!$user || !(this.state && this.state.user !== this.user.attributes));
        let $do = (!$user || $update || (this.state.loading !== nextState.loading));
        return (($do || nextState.refresh === true) && (nextState !== this.state));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.user !== this.state.user)
            this.user.setAttributes(this.state.user)
        if (this.state.refresh === true) {
            this.setState({refresh: false})
        }
    }

    componentDidMount() {
        this.user.checkUser();
    }
}

export default AdminBase;