import Component from "@base/Component";
import {Popover, Button, Col, Row, InputNumber, Input} from "antd";

class Step2 extends Component {

    caller = this.props.caller

    data = {
        emailReq: null,
        amount: 0,
        amounts: {
            1: 1000,
            2: 3000,
            3: 5000,
            4: 10000,
        },
        step: this.props.caller.data.step,
        text: {
            desc: this.t('Выберите или введите сумму, которую вы готовы пожертвовать'),
            desc2: this.t('Изменить сумму, которую вы готовы пожертвовать'),
            rulesBtn: this.t('Правила политики безопасности и возврата'),
            info: this.t('Ежемесячное пожертвование можно совершить только с банковской карты. Сумма будет списываться автоматически один раз в месяц. Вы можете в любой момент отключить автоматическое списание, нажав на ссылку «Отключить» в письме, подтверждающем пожертвование.'),
            customAmount: this.t('Другая сумма'),
            help: this.t('Помочь'),

        }
    }

    getCustomAmountPopover = (defaultActive) => {
        let component = this;

        class CustomAmountPopover extends Component {
            data = {
                visible: false,
                val: component.data.amount
            };

            hide = () => {
                this.set({
                    visible: false,
                });
            };

            handleVisibleChange = visible => {
                this.set({visible});
            };

            setValue = (val, update = false) => {
                val = parseInt(val)
                if (!val)
                    val = 0
                this.set({val: val})
                if (update) {
                    this.handleVisibleChange(false)
                    if (component.data.emailReq === null)
                        component.set({emailReq: true})
                    component.set({
                        amount: this.data.val
                    })
                }
            }

            render() {
                let defaultActive = this.props.defaultActive
                let active = (component.data.amount > 0 && defaultActive === false ? 'active' : '');
                let change = (this.data.visible ? 'change' : '')

                let btConf = {
                    className: active + ' change-default-amount ' + change,
                    onClick: () => this.handleVisibleChange(true),
                }

                return (
                    <Button {...btConf}>
                        {component.data.text.customAmount} {(component.data.amount > 0 && defaultActive === false) ? (
                        <span>
                            &nbsp;{!this.data.visible ? component.data.amount : ''}
                        </span>
                    ) : ''}
                        {this.data.visible ? (<InputNumber autoFocus={true} selected={true}
                                                           defaultValue={component.data.amount} min={1}
                                                           onChange={(value) => this.setValue(value)}
                                                           onBlur={(value) => this.setValue(value.target.value, true)}
                                                           onPressEnter={(value) => this.setValue(value.target.value, true)}
                        />) : ''}
                    </Button>
                )
            }
        }

        return <CustomAmountPopover defaultActive={defaultActive}/>
    }

    getAmountButtons = () => {
        let items = []
        let defaultActive = false;
        let fIsSet = false;
        for (let i = 1; i < 5; i++) {
            let btGridConf = {
                className: 'amount-bt-container',
                xs: {span: 12},
                sm: {span: 12},
                md: {span: 8},
                lg: {span: (i < 3 ? 2 : (i === 4 ? 4 : 3))}
            }
            if (!defaultActive && this.data.amounts[i] === this.data.amount) {
                defaultActive = true;
            }
            let k = items.length + 1;
            items.push((
                <Col key={k} {...btGridConf}>
                    <Button className={(this.data.amounts[i] === this.data.amount) ? 'active' : ''} onClick={() => {
                        this.set({amount: this.data.amounts[i]})
                        if (this.data.emailReq === null)
                            this.set({emailReq: true})
                    }}>
                        {this.data.amounts[i]}
                    </Button>
                </Col>

            ))
            if (i === 4)
                items.push(
                    <Col key={k + 1} {...btGridConf}>
                        {this.getCustomAmountPopover(defaultActive)}
                    </Col>
                )

        }
        let btGridConf2 = {
            className: 'amount-bt-container',
            xs: {span: 12},
            sm: {span: 12},
            md: {span: 8},
            lg: {span: 5}
        }

        return <Row gutter={{xs: 15, sm: 20, md: 20, lg: 25, xl: 30, xxl: 30}}>
            {items}
        </Row>
    }


    render() {

        let
            show = this.caller.data.step === this.props.step ? true : false

        return (

            <div>
                <div className={(show ? '' : 'hidden')}>
                    <p className={'font-slim hint'}>
                        {this.data.text.desc}
                    </p>

                    <br/>
                    {
                        this.getAmountButtons()
                    }
                    <br/>
                    {
                        this.caller.data.payment.method === 2 ? (
                            <Popover title={false}
                                     visible={this.data.emailReq}
                                     content={
                                         (
                                             <div className={'text-warning'}>
                                                 {this.t('введите email')}
                                             </div>
                                         )
                                     }>
                                <Input
                                    placeholder={'my@email.com'}
                                    className={'email-input hidden'}
                                    defaultValue={this.caller.data.payment.email}
                                    type={'email'} onChange={(e) => {
                                    let email = e.target.value

                                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                    let isEmail = re.test(String(email).toLowerCase());

                                    if (!isEmail) {
                                        this.set({
                                            emailReq: true
                                        })
                                        this.caller.setEmail(null)
                                        e.target.classList.add("error");
                                    } else {
                                        this.set({
                                            emailReq: false
                                        })
                                        this.caller.setEmail(email)
                                        e.target.classList.remove("error");
                                    }
                                }}/>
                            </Popover>
                        ) : ''
                    }


                    <Button
                        disabled={(this.data.amount <= 0 || (this.caller.data.payment.method === 2 && !this.caller.data.payment.email))}
                        className={'yellowBt'}
                        onClick={() => {
                            this.caller.setAmount(this.data.amount)
                            this.caller.setStep(this.caller.data.step + 1)
                            this.caller.goToPaymentSystem()
                        }}>
                        {this.data.text.help}
                    </Button>

                    <br/>
                    <br/>
                    <div className={'gray-block font-slim fs-md'}>
                        {this.data.text.info}
                        <Button href={
                            (this.app().data.lang === 'en' ? '/files/security-rules_en.docx' : '/files/security-rules.docx')
                        } target={'_blank'} className={'dashedBtn'}>
                            {this.data.text.rulesBtn}
                        </Button>
                    </div>

                </div>
                <div className={'tac sm-tal'}>
                    {!show && this.caller.data.step > this.props.step ?
                        <p onClick={() => this.caller.setStep(this.props.step)} className={'pointer hint font-slim'}>
                            {this.data.text.desc2}
                        </p> : ''}
                </div>
            </div>
        )
    }

}

export default Step2;