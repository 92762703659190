//
import React, {useContext, useEffect} from 'react'
import AppContext from "../../context/AppContext";
//
import Index from "./user/Index";
import View from "./user/View";
import Create from "./user/Create";
import Update from "./user/Update";
import Permissions from "./user/Permissions";
import NoPage from "../NoPage";

const User = () => {

    const $app = useContext(AppContext);


    useEffect(() => {
        $app.content.setTitle(true, false)
        $app.content.setTitle(true, true)
    }, [$app.content])


    let $pages = []

    $pages.push($app.content.showPage(<Index/>, 'User', 'Index'))
    $pages.push($app.content.showPage(<Create/>, 'User', 'Create'))
    $pages.push($app.content.showPage(<Update/>, 'User', 'Update'))
    $pages.push($app.content.showPage(<View/>, 'User', 'View'))
    $pages.push($app.content.showPage(<Permissions/>, 'User', 'change-permission'))

    return ($pages.join('') === '' ? <NoPage/> : $pages);
}

export default User;