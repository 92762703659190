import Component from "@base/Component";
import Partners from "@pages/home/Partners";
import {Row, Col} from "antd";

class Structure extends Component {

    /**
     * render image
     * @param i
     * @param params
     * @return {JSX.Element}
     */
    imgContent = (i, params = {}) => {
        let img = '/about/structure/' + i + '.png'
        let st = {
            style: {
                backgroundImage: 'url("/img/' + img + '")'
            },
            className: 'bg-img'
        }
        return (
            <div className={'img-container'}>
                <div {...st}>
                    {this.renderImage(img, params)}
                </div>
            </div>
        )
    }

    /**
     * @param name
     * @param post 1,2,3
     * @return {JSX.Element}
     */
    renderNamePost = (name, post) => {
        let pn = {
            1: 'Член Совета Фонда',
            2: 'Директор Фонда',
            3: 'Руководитель программы',
        }
        return (
            <div className={'name-post'}>
                <h2>
                    {this.t(name)}
                </h2>
                <p className="font-slim">
                    {this.t(pn[post])}
                </p>
                <br/>
            </div>
        )
    }

    getContent = () => {

        let cGrid1 = {
            span: 7,
            offset: 0,
            lg: {span: 5, offset: 0},
            md: {span: 7, offset: 0},
            sm: {span: 10, offset: 1},
            xs: {span: 18, offset: 0}
        };
        let cGrid2 = {
            span: 7,
            offset: 2,
            lg: {span: 5, offset: 2},
            md: {span: 7, offset: 3},
            sm: {span: 10, offset: 1},
            xs: {span: 18, offset: 0}
        };
        let cGrid3 = {
            span: 7,
            offset: 2,
            lg: {span: 5, offset: 2},
            md: {span: 7, offset: 1},
            sm: {span: 10, offset: 0},
            xs: {span: 18, offset: 0}
        };

        let c = (
            <>
                <Col {...cGrid1}>
                    {this.imgContent(1)}
                    {this.renderNamePost('Анатолий Хитров', 1)}
                </Col>
                <Col {...cGrid2}>
                    {this.imgContent(2)}
                    {this.renderNamePost('Наталья Хитрова', 1)}
                </Col>
                <Col {...cGrid3}>
                    {this.imgContent(3)}
                    {this.renderNamePost('Артем Басарыгин', 1)}
                </Col>
            </>
        )

        let tGrid1 = {
            span: 7,
            offset: 0,
            lg: {span: 5, offset: 0},
            md: {span: 7, offset: 0},
            sm: {span: 10, offset: 1},
            xs: {span: 18, offset: 0}
        };
        let tGrid2 = {
            span: 7,
            offset: 2,
            lg: {span: 5, offset: 2},
            md: {span: 7, offset: 3},
            sm: {span: 10, offset: 1},
            xs: {span: 18, offset: 0}
        };

        let t = (
            <>
                <Col {...tGrid1}>
                    {this.imgContent(2)}
                    {this.renderNamePost('Наталья Хитрова', 2)}
                </Col>
                <Col {...tGrid2}>
                    {this.imgContent(4, {
                        style: {
                            display: 'block',
                            width: '90%'
                        }
                    })}
                    {this.renderNamePost('Елена Гелескул', 3)}
                </Col>
            </>
        );

        return (
            <>
                <Row justify={'center'} align={'middle'} className={'tac'}>
                    {c}
                </Row>
                <br/>
                <br/>
                <div className="tac">
                    {this.renderTitle('Команда Фонда')}
                </div>
                <br/>
                <Row justify={'center'} align={'middle'} className={'tac'}>
                    {t}
                </Row>
                <Partners/>
            </>
        )

    }

    render() {
        return this.getContent()
    }
}

export default Structure;