import Component from "@base/Component";
import {Button} from "antd"

import {ArrowLeftOutlined} from '@ant-design/icons';

class View extends Component {

    data = this.props.data;

    onMount = () => {
        let id = this.props.id
        let sizes = {
            1: {
                top: 350,
            },
            2: {
                top: 350,
            },
            3: {
                top: 420,
            },
            4: {
                top: 350,
            },
            5: {
                top: 220,
            },
            6: {
                top: 400,
            },
            7: {
                top: 180,
            },
        }
        this.getImgUrl('/img/projects/view/' + id + '.png', (base64) => {
            this.app().set({
                bodyParams: {
                    className: 'projects-view-container',
                    style: {
                        backgroundImage: 'url(' + base64 + ')',
                        backgroundPosition: 'right ' + sizes[id].top + 'px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '350px',
                    }
                }
            })
        });
    }

    componentWillUnmount() {
        this.app().set({
            bodyParams: {
                style:{background:'none'}
            }
        })
    }

    renderContent = () => {
        let id = this.props.id

        let title = this.renderTitle('','','',(
            <h1>
                {this.data.text[id].title}
            </h1>
        ));

        if (id === '2')
            id = 3
        if (id === '3')
            id = 2

        return (
            <div className={'view-container'}>
                <Button type={'link'}
                        className={'text-muted p0 go-back-link'}
                        onClick={() => this.to(this.app().data.menuLinks.projects)}>
                    <ArrowLeftOutlined/> {this.t('Назад ко всем программам')}
                </Button>
                {title}
                {this.data.text[id].content.info}
                <br/>
                <br/>
                {this.data.text[id].content.title}
                {this.data.text[id].content.hint}
                {this.data.text[id].content.h}
                {typeof this.data.text[id].content.last === 'function' ? this.data.text[id].content.last() : ''}
            </div>
        )
    }

    render() {
        return this.renderContent()
    }

}

export default View;