import Component from "@base/Component";
import Partners from "@pages/home/Partners";
import {Row, Col} from "antd";

class Tasks extends Component {

    data = {
        text: {
            1: this.t('Оказание всесторонней помощи людям, борющимся с тяжелым недугом'),
            2: this.t('Привлечение внимания общества к профилактике и предотвращения опасных для жизни заболеваний'),
            3: this.t('Сокращение задержек, повышение доступности и упрощение оказания медицинской помощи'),
            4: this.t('Оказание натуральной помощи семьям и детям из малообеспеченных семей, семей, попавших в трудную жизненную ситуацию (многодетных, воспитывающих детей-инвалидов, детей-сирот и детей, оставшихся без попечения родителей, а также семей, нуждающихся в социальной помощи)'),
            5: this.t('Расстановка правильных приоритетов роли крепкой, счастливой и здоровой семьи в обществе с традициями и культурой посредством социально-значимых проектов'),
            6: this.t('Улучшение качества жизни человека'),
        }
    }

    getContent = () => {

        let items = [];
        let grid1 = {
            xs: {
                span: 24
            },
            sm: {
                span: 24
            },
            lg: {
                span: 24
            },
            xl: {
                span: 8
            },
        }
        let grid2 = {
            xs: {
                span: 24
            },
            sm: {
                span: 12
            },
            lg: {
                span: 24
            },
            xl: {
                span: 24
            },
        }
        for (let i = 1; i < 7; i++) {
            items.push((
                <Col className={'task-item'} key={i} {...grid1}>
                    <Row justify={'center'} align={'middle'}>
                        <Col className={'header'} {...grid2} sm={{span: 1}} md={{span: 2}} lg={{span: 2, offset: 0}}>
                            <h1>
                                {i}
                            </h1>
                        </Col>
                        <Col className={'content'} {...grid2} sm={{span: 21, offset: 2}} md={{span: 22, offset: 0}}
                             lg={{span: 22, offset: 0}}>
                            {this.data.text[i]}
                        </Col>
                    </Row>
                </Col>
            ))
        }

        return (
            <>
                <Row>
                    {items}
                </Row>
                <Partners/>
            </>
        )
    }

    render() {
        return this.getContent()
    }
}

export default Tasks;