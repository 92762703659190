import Component from "@base/Component";
import {Col, Row, Button} from "antd";
import TableInfo from "@base/widgets/TableInfo";
import Logo from "@base/widgets/Logo";

const providerVersion = 'new'

class Step3 extends Component {

    caller = this.props.caller

    data = {
        provider: '1',
        step: this.props.caller.data.step,
        text: {
            rules: this.t('Условия оказания услуги'),
            report: this.t('Отчет о сумме пожертвований'),
            pTitle: this.t('Требования для абонентов'),
            change: this.t('Изменить способ внесения пожертвования'),
            sample: (
                <>
                    {this.t('Например :')}&nbsp;
                    <b class='color-yellow'>{
                        this.t('Здоровье 100', {}, true)
                    }</b>
                </>
            ),
            grayBlockText: this.t('Отправьте SMS на короткий номер 3443 со словом Здоровье/Zdorovie и суммой цифрами.')
        },
        providers: {
            1: {
                title: this.t('Мегафон'),
                link: `/files/providers/${providerVersion}/Мегафон.pdf`,
                info: this.t('Минимальная сумма одного Платежа — 1 руб.;\n' +
                    '\n' +
                    'Минимальная сумма остатка денежных средств на Лицевом счете Абонента после совершения Платежа — 0 руб.;\n' +
                    '\n' +
                    'Максимальная сумма единовременного Платежа — 15 000 руб.;\n' +
                    '\n' +
                    'Максимальная сумма Платежей в сутки — 40 000 руб.;\n' +
                    '\n' +
                    'Максимальная сумма Платежей в месяц — 40 000 руб.\n' +
                    '\n' +
                    '«Мобильные платежи» доступны всем абонентам МегаФона – физическим лицам, а также сотрудникам корпоративных клиентов, у которых подключена услуга «Персональный бюджет». Подробности подключения Мобильных платежей на персональном счете. Услугой не могут воспользоваться абоненты, обслуживающиеся по кредитной системе расчетов, а также при финансовой блокировке номера. За исключением сервиса «Парковки», им могут воспользоваться все абоненты, в том числе и кредитные. Платеж невозможен за счет денежных средств, зачисленных на Лицевой счет в виде скидок на услуги связи Оператора, средств, полученных в рамках услуги «Мобильный перевод», а также за счет авансового платежа, вносимого Абонентом при заключении договора об оказании услуг связи с Оператором.\n' +
                    '\n' +
                    'МК становится невозможной при обращении абонента в КЦ МегаФон с просьбой об отключении услуги либо с помощью USSD команды *191#\n' +
                    '\n' +
                    'Комиссии с абонента — 0%\n'
                ),
            },
            2: {
                title: this.t('Beeline'),
                link: `/files/providers/${providerVersion}/Beeline.pdf`,
                info: this.t('Минимальная сумма одного Платежа — 10\n' +
                    '\n' +
                    'Максимальная сумма одного Платежа — 5 000\n' +
                    '\n' +
                    'Максимальная сумма платежей за день — 15 000\n' +
                    '\n' +
                    'Максимальная сумма платежей в неделю — 40 000\n' +
                    '\n' +
                    'Максимальная сумма платежей в месяц 40’000 руб., максимум 50 транзакций\n' +
                    '\n' +
                    'После списания суммы покупки на счете должно остаться не менее 50 руб. (для абонентов предоплатной системы расчетов).\n' +
                    '\n' +
                    'Комиссии с абонента — 0%'),
            },
            3: {
                title: this.t('MTC'),
                badge: '16+',
                link: `/files/providers/${providerVersion}/МТС.pdf`,
                info: this.t('Минимальная сумма одного Платежа — 10 руб.\n' +
                    '\n' +
                    'С каждого успешного платежа МТС взимает с абонента комиссию в размере 10 рублей (в том числе НДС)\n' +
                    '\n' +
                    'Неснижаемый остаток на лицевом счете после совершения оплаты — 10 руб.\n' +
                    '\n' +
                    'Для абонентов Санкт-Петербурга и Ленинградской области — 20 руб.\n' +
                    '\n' +
                    'Максимальная сумма платежа:\n' +
                    '\n' +
                    '1 000 — для услуг мобильной связи и Yota\n' +
                    '\n' +
                    '5 000 — для остальных услуг и категорий\n' +
                    '\n' +
                    'Максимальное число платежей в сутки: 10 или не более 50 месяц.\n' +
                    '\n' +
                    'Комиссии с абонента — 0%\n' +
                    '\n' +
                    'Максимальная сумма платежей в сутки: 5000 руб. или не более 40 000 руб. в месяц.')
            },
            4: {
                title: this.t('Тмобаил'),
                link: `/files/providers/${providerVersion}/Тмобаил.pdf`,
                info: this.t(
                    'Минимальная сумма одного платежа – 1 рубль \n\n' +
                    'Допустимый баланс после платежа – 0 рублей \n\n' +
                    'Максимальная сумма единовременного платежа, а также лимит платежей абонента в сутки/месяц устанавливается банком. \n\n' +
                    'Комиссия с абонента — 0%'
                    , {}, false, true)
            },
            5: {
                title: this.t('YOTA'),
                link: `/files/providers/${providerVersion}/Yota.pdf`,
                info: this.t('Минимальная сумма одного Платежа — 1 руб.\n' +
                    '\n' +
                    'Максимальная сумма одного Платежа — 15 000 руб.\n' +
                    '\n' +
                    'Максимальная сумма Платежей в сутки — 40 000 руб.\n' +
                    '\n' +
                    'Максимальная сумма Платежей в месяц — 40 000 руб.\n' +
                    '\n' +
                    'Комиссии с абонента — 0%\n' +
                    '\n' +
                    'Максимальная сумма платежей в сутки: 5000 руб. или не более 40 000 руб. в месяц.')
            },
        }
    }

    render() {

        let show = this.caller.data.step === this.props.step ? true : false

        let prConf = {
            1: {
                xxl: {span: 9},
                xl: {span: 9},
                lg: {span: 24},
            },
            2: {
                xxl: {span: 14, offset: 1},
                xl: {span: 14, offset: 1},
                lg: {span: 24},
            },
        }

        let current = this.data.providers[this.data.provider];

        return (
            <div className={'providers-info-container'}>
                <div className={(show ? '' : 'pointer hidden')}>
                    <Row>
                        <Col xs={{span: 0}} sm={{span: 24}}>
                            {this.caller.data.selectedPaymentBg}
                        </Col>
                    </Row>
                    <div onClick={() => this.caller.setStep(0)}>
                        <Row>
                            <Col xs={{span: 7}} sm={{span: 0}}>
                                {this.caller.data.selectedPaymentBg}
                            </Col>
                            <Col xs={{span: 15, offset: 2}} sm={{span: 24, offset: 0}} className={'tal pointer'}>
                                <span className={'pointer hint font-slim l'}>
                                    {this.data.text.change}
                                </span>
                            </Col>
                        </Row>
                    </div>


                    <br/>
                    <br/>
                    <div className={'gray-block color-black tac'}>
                        {this.data.text.grayBlockText}
                    </div>
                    <br/>
                    <br/>
                    <h2 className={'tac font-slim fs-xl'}>
                        {this.data.text.sample}
                    </h2>

                    <br/>
                    <Row gutter={[20, 20]} justify={'center_'} align={'middle'}>
                        {this.renderProviders()}
                    </Row>
                    <br/>
                    <br/>
                    <h2 className={'tac'}>
                        {this.data.text.pTitle}
                        {/*{current.title} :*/}
                    </h2>

                    <div className="font-slim color-black">
                        {/*{current.info}*/}
                        <div>
                            Услуга доступна для абонентов МТС, билайн, Мегафон, Тинькофф Мобайл, Yota.
                        </div>
                        <div>
                            Допустимый размер платежа — от 10 до 15000 рублей.
                        </div>
                        <div>
                            Стоимость отправки SMS на короткий номер – бесплатно.
                        </div>
                        <div>
                            Комиссия с абонента – 0%. Возрастное ограничение: 16+.
                        </div>
                    </div>
                    <br/>

                    <a href={current.link} target={'_blank'} className={'yellowLink'}>
                        {current.title} - {this.data.text.rules}
                    </a>
                    <br/>
                    <br/>
                    <br/>

                    <b style={{color: 'black'}}>
                        {
                            this.t('Мобильные платежи осуществляются через платёжный сервис Миксплат. Совершая платёж, вы принимаете условия Оферты сервиса и политики обработки персональных данных.\n' +
                                '\n' +
                                'Информацию о порядке и периодичности оказания услуг и условиях возврата вы можете получить по телефону +7 495 775 06 00 или почте {email}', {
                                email: '<a href="mailto:support@mixplat.ru" className="color-yellow">support@mixplat.ru</a>'
                            }, false, true)
                        }
                    </b>

                    <br/>
                    <br/>
                    <br/>
                    <Button href={'/files/providers/otchet.pdf'} download={'otchet.pdf'} className={'grayBt'}>
                        {this.data.text.report}
                    </Button>

                </div>
            </div>
        )
    }


    renderProviders = () => {
        let providers = this.data.providers;
        return Object.keys(providers).map((k, i) => {
            let conf = {
                style: {
                    backgroundImage: 'url(/img/want-to-help/providers/new/' + k + '.jpeg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: '60%'
                },
                onClick: () => {
                    this.setProvider(k)
                }
            }
            let grid = {
                xs: {span: 12},
                sm: {span: 12},
                md: {span: 8},
                lg: {span: 5},
                xl: {span: 4},
            }
            return (
                <Col key={i} {...grid} className={'tac provider-logo-container'}>
                    <div {...conf} className={"provider-logo" + (this.data.provider === k ? ' active' : '')}>
                        {providers[k].badge ? <div className={'badge'}>
                            {providers[k].badge}
                        </div> : ''}
                    </div>
                </Col>
            )
        })
    }


    setProvider = (key) => {
        this.set({
            provider: key
        })
    }
}

export default Step3;
