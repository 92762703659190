//
import React from 'react'
import AppContext from "../../../context/AppContext";

class Form extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {}
    }

    /**
     * toggle loading state
     */
    loadToggle = () => this.setState({loading: !this.state.loading})

    /**
     * render content
     * @return {JSX.Element}
     */
    content = () => {

        /** @type {AdminBase} */
        const $app = this.context

        /** @type {InputHelper} */
        const $form = this.context.HInput.new(this)

        let $left = (
            <>
                {$form.text('first_name')}
                {$form.text('last_name')}
            </>
        )

        let $right = (
            <>
                {$form.text('email', {}, {rules: [{type: 'email', required: true, message: ''}]})}
                {$form.password('tmpPassword', {}, {
                    rules: [
                        {required: $app.route.action === 'create', message: ''},
                        {min: 6}
                    ]
                })}
            </>
        )

        let $items = $form.renderFieldsByPositions('', $left, $right)

        return $form.render($items, {
            onFinish: () => {
                this.loadToggle()
                $app.record.save(this.state.attributes, (res) => {
                    this.loadToggle()
                    if (!$form.requestHasErrors(res))
                        return $form.setAttr('tmpPassword', '', true)
                })

            }
        })
    }

    render = () => this.content()
}

export default Form;