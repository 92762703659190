import Component from "@base/Component";
import Step1 from "@pages/wantToHelp/Step1";
import Step2 from "@pages/wantToHelp/Step2";
import Step3 from "@pages/wantToHelp/Step3";
import {Steps, Statistic, Button} from 'antd';
import {
    LoadingOutlined
} from '@ant-design/icons';

const {Step} = Steps;
const {Countdown} = Statistic;

class Index extends Component {

    data = {
        step: 10,
        text: {
            warning: {
                title: this.t('Внимание!'),
                text: this.t('Для рассмотрения обращения Фонд вправе запросить дополнительные необходимые документы')
            },
            step1: {
                title: (
                    <span>
                        {this.t('Чтобы ваше обращение было обработано, просим собрать пакет документов, согласно списку:')}
                    </span>
                ),
                text: (
                    <ul className={'gray-dots'} style={{position: 'relative', top: '-15px'}}>
                        <li>
                            {this.t('Заявление (письмо-обращение) по утвержденной форме. Если помощь требуется ребенку, обращение ' +
                                'должно быть подписано\n одним из его родителей (законным представителем);')}
                        </li>

                        <br/>

                        <Button
                            // type={'link'}
                            href={'/files/assistance_application.doc'}
                            download={'заявление об адресной помощи.doc'}
                            className={'grayBt'}>
                            {this.t('Скачать шаблон заявления')}
                        </Button>

                        <li>

                            {this.t('Фотография (цветное любительское фото хорошего качества, крупный план, неформальный кадр, формат ' +
                                'фотографии jpg);')}
                        </li>

                        <li>
                            {this.t('Скан-копия паспорта и/или свидетельства о рождении для детей до 14 лет;')}
                        </li>

                        <li>
                            {this.t('Скан-копия паспорта одного из родителей (законного представителя) (страницы с фотографией и ' +
                                'пропиской);')}
                        </li>

                        <li>
                            {this.t('Справка о доходах семьи по форме 2-НДФЛ за полный предшествующий год на момент подачи документов ' +
                                'и за настоящий;')}
                        </li>

                        <li>
                            {this.t('Копия справки, подтверждающей факт установления инвалидности, если имеется (по запросу);')}
                        </li>

                        <li>
                            {this.t('Копия индивидуальной программы реабилитации и абилитации (ИПРА) (по запросу);')}
                        </li>

                        <li>
                            {this.t('Копия последнего медицинского заключения с диагнозом, на бланке клиники, с подписью врача и ' +
                                'печатью;')}
                        </li>

                        <li>
                            {this.t('Счет за лечение (реабилитацию), выписанный клиникой (реабилитационным центром), с банковскими ' +
                                'реквизитам')}
                        </li>

                    </ul>
                )
            },
            step2: {
                title: (
                    <span>
                        {
                            this.t('Сделать скан-копии и направить их нам вместе с обращением по адресу: {email}', {
                                email: '<a href="mailto:help@whp.ru" className="color-yellow">help@whp.ru</a>'
                            }, false, true)
                        }
                    </span>
                ),
                text: (
                    <div className={'font-slim fs-md color-black'}>
                        {this.t('<b>Обязательно</b> укажите в теме письма Фамилию и Имя того, кому нужна помощь')}
                    </div>
                )
            },
            step3: {
                title: (
                    <span>
                        {this.t('После получения фондом документов, Вам приходит ответ от Фонда')}
                    </span>
                ),
                text: (
                    <div className={'font-slim fs-md color-black'}>
                        {
                            this.t('Если ответ не пришел в течение недели после отправки документов, свяжитесь, пожалуйста, с нами по телефону: \n {phone}', {
                                phone: '<b>8 (930) 152 97 42</b>'
                            }, false, true)
                        }
                    </div>
                )

            },
        }
    }

    /**
     * set step
     * @param step
     */
    setStep = (step) => this.set({step: step})

    stepIcon = (n) => {
        return <div className="ant-steps-item-icon">
            <span className="ant-steps-icon color-white">
                {n}
            </span>
        </div>
    }

    render() {
        return (
            <>
                {
                    <Steps direction="vertical" size="large" current={this.data.step}>
                        <Step icon={this.stepIcon(1)}
                              title={this.data.text.step1.title}
                              description={this.data.text.step1.text}
                        />

                        <Step icon={this.stepIcon(2)}
                              title={this.data.text.step2.title}
                              description={this.data.text.step2.text}
                        />


                        <Step icon={this.stepIcon(3)}
                              title={this.data.text.step3.title}
                              description={this.data.text.step3.text}
                        />

                    </Steps>
                }
                <br/>
                <br/>
                <div className="gray-block">
                    <b className={'ttu'}>
                        {this.data.text.warning.title}
                    </b>
                    <div className={'font-slim fs-xs'}>
                        {this.data.text.warning.text}
                    </div>
                </div>
            </>
        )

    }
}

export default Index;