import Component from "@base/Component";
import Partners from "@pages/home/Partners";
import {Row, Col} from "antd";

class Vlaues extends Component {

    data = {
        text: {
            1: {
                title: this.t('Открытость'),
                text: this.t('Мы открыты к сотрудничеству со всеми желающими — от волонтеров до компаний и экспертов в различных направлениях медицины, реабилитации, образования. Отчетность и прозрачность во всем. Непрерывный диалог и сотрудничество с партнерами и благотворителями'),
            },
            2: {
                title: this.t('Ответственность\n за результаты своей работы'),
                text: this.t('Мы создали Фонд для достижения конкретных целей и несём полную ответственность за методы и результаты его работы'),
            },
            3: {
                title: this.t('Вера в людей'),
                text: this.t('Мы ценим в людях ценность, доброту и отзывчивость. Мы верим, что в мире есть множество неравнодушных людей, которым под силу объединиться и помочь тем, кто в этом действительно нуждается.'),
            },
            4: {
                title: this.t('Мгновенный отклик'),
                text: this.t('Одна из основных особенностей Благотворительного фонда – практически мгновенная помощь семьям, воспитывающих детей с тяжелыми заболеваниями. Сбор средств идет постоянно для того, чтобы экстренно отреагировать нуждающимся в помощи, для тех, кто не может ждать'),
            },
            5: {
                title: this.t('Помощь донорам'),
                text: this.t('В любой момент Фонд «Мир здоровых людей» позаботится и поможет своим донорам за внесенный вклад в наше общее дело'),
            },
            x: this.t('Двери Фонда всегда открыты для всех людей с чутким сердцем, желающих делать добрые дела!')
        }
    }

    getContent = () => {

        let items = [];
        let grid1 = {
            xs: {
                span: 24
            },
            sm: {
                span: 24
            },
            lg: {
                span: 24
            },
            xl: {
                span: 7,
                offset: 1
            },
        }
        let grid2 = {
            xs: {
                span: 24
            },
            sm: {
                span: 12
            },
            lg: {
                span: 24
            },
            xl: {
                span: 24
            },
        }
        for (let i = 1; i < 6; i++) {
            items.push((
                <Col className={'task-item'} key={i} {...grid1}>
                    <Row justify={'center'} align={'middle'}>
                        <Col className={'header'} {...grid2} sm={{span: 4}} md={{span: 4}} lg={{span: 3, offset: 0}}>
                            {this.renderImage('/about/values/' + i + '.png')}
                        </Col>
                        <Col className={'content'} {...grid2} sm={{span: 18, offset: 2}} md={{span: 19, offset: 1}}
                             lg={{span: 20, offset: 1}}>
                            <h2>
                                {this.data.text[i].title}
                            </h2>
                            {this.data.text[i].text}
                        </Col>
                    </Row>
                </Col>
            ))
            if (i === 3) {
                items.push((
                    <Col key={6} {...grid1} xl={{span: 4, offset: 0}}></Col>
                ))
            }
        }

        return (
            <>
                <Row>
                    {items}
                </Row>
                <br/>
                <br/>
                <h2 className={'tac'}>
                    {this.data.text.x}
                </h2>
                <Partners/>
            </>
        )
    }

    render() {
        return this.getContent()
    }
}

export default Vlaues;