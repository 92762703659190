//
import React, {useContext, useEffect} from 'react'
import AppContext from "../../context/AppContext";
//
import Index from "./default/Index";
import View from "./default/View";
import Create from "./default/Create";
import Update from "./default/Update";
import NoPage from "../NoPage";

const Default = () => {
    /**
     * @type {AdminBase}
     */
    const $app = useContext(AppContext);


    useEffect(() => {
        $app.content.setTitle(true, false)
        $app.content.setTitle(true, true)
    }, [$app.content])


    let $pages = []

    if ($app.route.action === 'index')
        $pages.push($app.content.showPage(<Index/>, $app.route.controller, 'Index'))
    if ($app.route.action === 'create')
        $pages.push($app.content.showPage(<Create/>, $app.route.controller, 'Create'))
    if ($app.route.action === 'update')
        $pages.push($app.content.showPage(<Update/>, $app.route.controller, 'Update'))
    if ($app.route.action === 'view')
        $pages.push($app.content.showPage(<View/>, $app.route.controller, 'View'))

    return (
        <>
            {($pages.join('') === '' ? '' : $pages)}
        </>
    )
}

export default Default;