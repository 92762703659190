import Component from "@base/Component";
import Index from "@pages/projects/Index"
import View from "@pages/projects/View"
import Carousel from "@pages/report/Carousel"
import {Route} from "react-router-dom";


class Projects extends Component {

    data = {
        text: {
            title: 'Программы и проекты',
            1: {
                type: Index.TYPE_PROGRAM,
                title: this.t('Адресная помощь'),
                description: this.t('Cвоевременное оказание медицинской помощи в диагностике и последующем лечении семьям, воспитывающих детей с тяжелыми заболеваниями'),
                content: {
                    info: (
                        <div className="white-container">
                            {this.t('Суть благотворительной программы: своевременная медицинская помощь в диагностике и последующем лечении семьям, воспитывающих детей с тяжелыми заболеваниями, детей-инвалидов, детей с ограниченными возможностями здоровья.')}
                            <br/>
                            <br/>
                            {this.t('Эффективное использование потенциала фонда поможет в реализации адресной помощи и осуществлению социально значимых программ семьям, которые находятся в тяжелой жизненной ситуации и не могут самостоятельно справиться с ней.')}
                        </div>
                    ),
                    title: (
                        <>
                            <br/>
                            <h3>
                                {this.t('Цели и задачи программы:')}
                            </h3>
                        </>
                    ),
                    hint: (
                        <ul>
                            <li>
                                {this.t('Оказание срочной адресной медицинской помощи в ситуациях, когда нет возможности ждать осуществления ее за счет бюджетных средств.')}
                            </li>
                            <li>
                                {this.t('Приобретение необходимых лекарственных препаратов, технических средств реабилитации, оборудования и расходных медицинских материалов.')}
                            </li>
                            <li>
                                {this.t('Привлечение внимания общества к благотворительности побуждение к бескорыстным благородным поступкам.')}
                            </li>
                            <li>
                                {this.t('Дать людям возможность вернуть счастье, обрести веру в людей и будущее.')}
                            </li>
                            <li>
                                {this.t('Поддержка ценностей детства и семьи.')}
                            </li>
                            <li>
                                {this.t('Адресная помощь семьям оказавшимися в тяжелой жизненной ситуации.')}
                            </li>
                            <li>
                                {this.t('Повышение доступности реабилитационных услуг детям-инвалидам и детям с ограниченными возможностями здоровья.')}
                            </li>
                        </ul>
                    ),
                    h: (
                        <>
                            <br/>
                            <br/>
                            <div className="gray-block tac">
                                {this.t('Дать человеку шанс сохранить самое ценное – это крепкая семья и здоровье! ')}
                            </div>
                        </>
                    ),
                    last: function () {
                        return (
                            <>
                                {/*<br/>*/}
                                {/*<br/>*/}
                                {/*<Carousel/>*/}
                            </>
                        )
                    }
                }
            },
            2: {
                type: Index.TYPE_PROJECT,
                title: this.t('Долголетие старшего поколения'),
                description: this.t('Помощь старшему поколения ощутить себя любимыми, нужными, а это самое важное для тех, кого когда-то предали или кто-то просто остался один'),
                content: {
                    info: (
                        <div className="white-container">
                            {this.t('Суть программы состоит в том, что благодаря лошади, сильному и доброму животному, дети с различными недугами значительно улучшают свое здоровье. В ходе лечения «иппотерапией» результаты наступают быстрее и эффективнее.')}
                            <br/>
                            <br/>
                            {this.t('В этот проект так же будут включены дети, стоящие на учете по делам несовершеннолетних и их родители. Для преодоления проблем в отношениях между детьми и родителями послужит общение с психологом, забота и совместный уход за лошадьми.')}
                        </div>
                    ),
                    title: (
                        <>
                            <br/>
                            <h3>
                                {this.t('Цели и задачи программы:')}
                            </h3>
                        </>
                    ),
                    hint: (
                        <ul>
                            <li>
                                {this.t('Оказание помощи в восстановлении физического здоровья детей с ОВЗ.')}
                            </li>
                            <li>
                                {this.t('Помощь в создании психоэмоционального настроя между животным и ребенком.')}
                            </li>
                            <li>
                                {this.t('Помощь в налаживании отношений между родителями и детьми ради сохранения доверительных отношений между друг другом.')}
                            </li>
                            <li>
                                {this.t('Расстановка правильных приоритетов и определение семейных ценностей в каждой отдельной семье.')}
                            </li>
                        </ul>
                    ),
                    h: (
                        <>
                            <br/>
                            <br/>
                            <div className="gray-block tac">
                                {this.t('Здоровое поколение – добрыми делами!')}
                            </div>
                        </>
                    )
                }
            },
            3: {
                type: Index.TYPE_PROGRAM,
                title: this.t('Ценности и традиции русской культуры в Галопе'),
                description: this.t('Благодаря лошади, сильному и доброму животному, дети с различными недугами значительно улучшают свое здоровье'),
                content: {
                    info: (
                        <div className="white-container">
                            {this.t('Суть программы заключается в том, что мамы-волонтеры АНО ЦПМ «МАМИНА ПОМОЩЬ» и их дети посещают каждый месяц одиноких пожилых людей в доме-интернате. Помогают им ощутить себя любимыми, нужными, а это самое важное для тех, кого когда-то предали или кто-то просто остался один. Бабушки и дедушки порой плачут от радости только от того, что у них есть возможность взять за руку ребенка, тем самым вернуть нежные, добрые воспоминания о счастливой жизни в семье.')}
                            <br/>
                            <br/>
                            {this.t('Для всех нас огромное значение имеет дружная, полноценная и крепкая семья, и очень важно научить ребенка любить, почитать, уважать старшее поколение. Гордиться, заботиться о них, ведь благодаря им он часть этой семьи. Цепочка поколений тем и крепка, что каждое звено ценно!')}
                        </div>
                    ),
                    title: (
                        <>
                            <br/>
                            <h3>
                                {this.t('Цели и задачи программы:')}
                            </h3>
                        </>
                    ),
                    hint: (
                        <ul>
                            <li>
                                {this.t('Создание моментов радости жизни и семейной заботы для старшего поколения.')}
                            </li>
                            <li>
                                {this.t('Реализация мероприятий, благотворно влияющих на самочувствие и долголетие пожилых людей.')}
                            </li>
                            <li>
                                {this.t('Раскрыть понятие «семейные традиции» нашим детям, показать, как мы связаны с прошлым и традициями своих предков.')}
                            </li>
                            <li>
                                {this.t('Взаимосвязь и сплочение поколений.')}
                            </li>
                        </ul>
                    ),
                    h: (
                        <>
                            <br/>
                            <br/>
                            <div className="gray-block tac">
                                {this.t('Подарим вместе одиноким старикам веру в чудо!\nМир меняют поколения!')}
                            </div>
                        </>
                    )
                }
            },
            4: {
                type: Index.TYPE_PROGRAM,
                title: this.t('Мир Здоровых Людей'),
                description: this.t('Комплексная поддержка одиноких, пожилых людей, заболевших COVID-19 и оказавшихся на стационарном лечении в Одинцовской центральной больнице без поддержки близких людей'),
                content: {
                    info: (
                        <div className="white-container">
                            {this.t('Суть программы заключается в комплексной поддержке одиноких, пожилых людей, заболевших COVID-19 и оказавшихся на стационарном лечении в Одинцовской центральной больнице без поддержки близких людей. В этом году пандемия новой коронавирусной инфекции в той или иной степени, к сожалению, коснулась каждого из нас. Фонд «Мир Здоровых Людей» совместно с Центром поддержки материнства «Мамина помощь» содействует привычному образу жизни и быстрейшей адаптации морального и эмоционального состояния во время госпитализации в стационаре.')}
                        </div>
                    ),
                    title: (
                        <>
                            <br/>
                            <h3>
                                {this.t('Цели и задачи программы:')}
                            </h3>
                        </>
                    ),
                    hint: (
                        <ul>
                            <li>
                                {this.t('Оказание помощи заболевшим коронавирусной инфекцией и проходящим лечение в стационаре.')}
                            </li>
                            <li>
                                {this.t('Поддержание психоэмоционального состояния заболевших коронавирусной инфекцией и проходящих лечение в стационаре.')}
                            </li>
                            <li>
                                {this.t('Улучшение условий пребывания в стационаре, заболевших коронавирусной инфекцией.')}
                            </li>
                            <li>
                                {this.t('Вовлечение волонтеров и добровольцев для реализации мероприятий проекта с целью оказания индивидуальной помощи людям, оказавшимся в трудной жизненной ситуации в условиях пандемии коронавируса.')}
                            </li>
                        </ul>
                    ),
                }
            },
            5: {
                type: Index.TYPE_PROGRAM,
                title: this.t('Наше духовное наследие'),
                description: this.t('Духовное наследие России – это традиции народа и воспитание нравственности!'),
                content: {
                    info: (
                        <div className="white-container">
                            {this.t('Порой, читая добрые духовные книги, написанные для детей, сами родители меняют свое мировоззрение, формируя духовную личность не только ребенка, но и корректируя свою. Новый проект станет доступной литературой в помощь родителям, которые заботятся о формировании нравственных качеств человека. Ведь именно такая литература воспитывает и взращивает огромную значимость семейных ценностей. ')}
                        </div>
                    ),
                    h: (
                        <h3 className={'tac'}>
                            {this.t('Духовное наследие России – это традиции народа и воспитание нравственности!')}
                        </h3>
                    )
                }
            },
            6: {
                type: Index.TYPE_PROGRAM,
                title: this.t('Помощь детским домам'),
                description: this.t('Систематическая помощь для полноценного и разностороннего развития воспитанников-сирот в школе-интернате'),
                content: {
                    info: (
                        <div className="white-container">
                            {this.t('В санаторной школе-интернате (с. Медное, Тверская область) давний уклад и множество добрых, полезных для детей традиций. На первом месте здесь стоит индивидуальный подход и развитие ребенка. Прививают любовь к своему краю и Родине. Здесь формируются гармоничные личности, и ведут активный здоровый образ жизни. Но в последнее время руководству интерната все сложнее становится оставаться на плаву. Благодаря фонду налажена систематическая помощь для полноценного и разностороннего развития 120 воспитанников-сирот в школе-интернате.')}
                        </div>
                    ),
                    title: (
                        <>
                            <br/>
                            <h3>
                                {this.t('Цели и задачи программы:')}
                            </h3>
                        </>
                    ),
                    hint: (
                        <ul>
                            <li>
                                {this.t('Содействие деятельности в сфере профилактики и охраны здоровья детей, а также пропаганды здорового образа жизни, улучшения морально-психологического состояния детей.')}
                            </li>
                            <li>
                                {this.t('Улучшение условий и уровня жизни детей-сирот и детей, которые остались без опеки родителей.')}
                            </li>
                            <li>
                                {this.t('Разработка и реализация мероприятий, направленных на содействие социальной, интеллектуальной, физической и психологической реабилитации и адаптации детей-сирот, детей, которые остались без заботы родителей, лиц из числа детей-сирот, детей с особенностями в развитии, детей из малообеспеченных семей, детей групп риска.')}
                            </li>
                        </ul>
                    ),
                    h: (
                        <>
                            <br/>
                            <br/>
                            <div className="gray-block tac">
                                {this.t('У них нет близких или родственников!\n Вы – их единственная надежда на помощь.')}
                            </div>
                        </>
                    )
                }
            },
            7: {
                type: Index.TYPE_PROGRAM,
                title: this.t('Волонтёрство'),
                description: this.t('Мы будем рады видеть вас в нашей команде волонтеров!'),
                content: {
                    info: (
                        <div className="white-container">
                            <h3 className={'font-default'}>
                                {this.t('Если вы:')}
                            </h3>

                            <ul>
                                <li>
                                    {this.t('готовы активно менять этот мир к лучшему;')}
                                </li>
                                <li>
                                    {this.t('умеете сочувствовать и поддерживать;')}
                                </li>
                                <li>
                                    {this.t('способны работать в команде единомышленников;')}
                                </li>
                                <li>
                                    {this.t('готовы участвовать безвозмездно в социально значимых проектах.')}
                                </li>
                            </ul>
                            <br/>
                            <p className={'font-md'}>
                                {this.t('Мы будем рады видеть вас в нашей команде волонтеров!')}
                            </p>
                        </div>
                    ),
                    title: (
                        <>
                            <h2>
                                {this.t('Как можно помочь?')}
                            </h2>
                            <div style={{padding: '20px 30px'}}>

                                <h3>
                                    {this.t('Рассказать о Фонде')}
                                </h3>
                                <p className={'font-slim'}>
                                    {this.t('Чем больше людей узнают о нашем Фонде, тем большее количество поддержки смогут получить наши подопечные. Расскажите родным и друзьям о деятельности Фонда, сделайте пост в соцсетях, отправьте ссылки на истории пациентов знакомым, делитесь нашими новостями.\n \n Необходимо рассказывать о своих добрых поступках тем, кто хочет делать добрые дела, но не может на это решиться или не знает, как это своё желание он может реализовать. А вы рассказали о своём поступке (нет, не хвастались, а просто так, в качестве информации), кто-то услышал и решил, что он тоже так будет делать!')}
                                </p>
                                <br/>
                                <h3>
                                    {this.t('Стать интеллектуальным волонтёром')}
                                </h3>
                                <p className={'font-slim'}>
                                    {this.t('Если вы умете писать тексты, рисовать, верстать, программировать, фотографировать, снимать видео — в общем, помогать Фонду своими профессиональными знаниями — мы с радостью примем вашу помощь. Также мы с интересом рассмотрим любую идею, связанную с популяризацией Фонда и помощью взрослым.')}
                                </p>
                                <br/>
                                <h3>
                                    {this.t('Помочь на благотворительном мероприятии')}
                                </h3>
                                <p className={'font-slim'}>
                                    {this.t('Фонд часто участвует в благотворительных мероприятиях. Нам всегда нужна помощь в организации: привезти и выгрузить оборудование, встретить гостей и участников, организовать питание, следить за сувенирной продукцией и реквизитом, собирать пожертвования в боксы.')}
                                </p>
                                <br/>
                                <h3>
                                    {this.t('Провести мастер-класс')}
                                </h3>
                                <p className={'font-slim'}>
                                    {this.t('Если вы умеете что-то особенное: лепить, рисовать, проводить мастер-классы, занимаетесь арт-терапией, мы обязательно пригласим вас к нашим подопечным.')}
                                </p>
                                <br/>
                            </div>
                        </>
                    ),
                    h: (
                        <h3 className={'gray-block tac'}>
                            {this.t('Мы даем Вам возможность проявить себя в истинном милосердии — желании приносить пользу\n другим людям, не думая о вознаграждении!')}
                        </h3>
                    )
                }
            }
        }
    }

    render() {
        let $app = this.app();
        return (
            <div className={'projects-page'}>
                <Route path={$app.data.menuLinks.projects} exact>
                    <Index data={this.data}/>
                </Route>
                <Route path={$app.data.menuLinks.projects + '/:id'} render={(r) => {
                    return (
                        <div className="view">
                            <View id={r.match.params.id} data={this.data}/>
                        </div>
                    )
                }}/>

            </div>
        )
    }

}

export default Projects;