import Component from "@base/Component";
import {Row, Col} from "antd";


class Report extends Component {


    data = {
        items: null,
    }

    onMount = () => {
        let items = this.app().getBulkData('report', false)
        if (!this.data.items && items)
            this.set({items: items})
    }

    renderItem = (nr, title, text) => {
        return (

            <div className={'item item-' + nr}>
                <div className={'image-contain'}>
                    {this.renderImage('/report/' + nr + '.svg')}
                    <br/>
                    <br/>
                </div>
                <h3>
                    {this.numberFormat(title)} {nr < 3 ? this.getRubSign() : ''}
                </h3>
                <div className={'font-slim'}>
                    {text}
                </div>
            </div>
        )
    }

    render() {
        if (!this.data.items) {
            return ''
        }

        let items = []
        let conf = {
            xs: {span: 24},
            sm: {span: 12},
            md: {span: 12},
            lg: {span: 6},
        };

        for (let i = 1; i < 5; i++) {
            let $data = this.data.items[4 - i];

            let q = this.renderHtml($data.title);

            items.push(
                <Col key={i} {...conf}>
                    {this.renderItem(i, $data.amount, q)}
                </Col>
            )
        }

        return (
            <Row align={'middle'} justify={'center'} className={'home-page-report'}>
                {items}
            </Row>
        )
    }

}

export default Report;