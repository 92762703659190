import React from 'react';
//
import {Image, Table} from 'antd';
//
import AppContext from "../../context/AppContext";


/**
 * ViewTable component
 */
class ViewTable extends React.Component {

    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            options: {},
            ready: false,
            related: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.caller && this.props.caller.state && !this.state.ready) {
            let $state = this.props.caller.state.response;
            $state['ready'] = true
            this.setState($state)
        }
    }

    getMainContentData = () => {
        if (!this.state.ready) {
            return ''
        }
        const state = this.state;

        /** @type {AdminBase} */
        const $app = this.context

        const $labels = $app.record.getLabels();
        const dataSource = [];
        let imgs = [];
        let files = [];
        Object.keys(state.item).map((k) => {
            let $val = (state.options[k] ? state.options[k][state.item[k]] : state.item[k])

            if ((k === 'image' || k === 'images') && imgs.length === 0) {
                Object.keys($val).map((k, i) => {
                    imgs.push(
                        <Image
                            key={i}
                            width={'auto'}
                            height={125}
                            src={$val[k].url}
                        />
                    )
                    return imgs
                });
                $val = imgs
            }

            if ((k === 'file' || k === 'files') && $val && typeof $val === 'object') {
                if (Object.keys($val).length > 0) {
                    Object.keys($val).map((k, i) => {
                        files.push(
                            <div key={$val[k].uid}>
                                <a href={$val[k].link}>
                                    {$val[k].name}
                                </a>
                                <br/>
                            </div>
                        )
                        return files
                    });
                    $val = files
                }
            }

            if (k === 'created_at' || k === 'updated_at')
                $val = this.timeConverter($val)

            if (!state.options[k] && typeof $val !== 'object')
                $val = <div dangerouslySetInnerHTML={{__html: $val}}/>

            if ($val === undefined)
                $val = state.item[k]

            if ($val === '' || $val === undefined || $val === null)
                $val = '-'

            let $exclude = this.props.exclude && this.props.exclude.includes(k);
            if (!$exclude) {
                dataSource.push({
                    key: k,
                    label: $labels[k],
                    value: $val,
                })
            }
            return [];
        });

        const columns = [
            {
                title: 'Название',
                dataIndex: 'label',
                key: 'label',
            },
            {
                title: 'Значение',
                dataIndex: 'value',
                key: 'value',
            },
        ];

        return <Table
            showHeader={false}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            size={$app.state.siteSize}
            bordered={true}/>
    }


    timeConverter = (UNIX_timestamp) => {
        if (typeof UNIX_timestamp !== 'number')
            return UNIX_timestamp;
        let a = new Date(UNIX_timestamp * 1000);
        //let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let year = a.getFullYear();
        // let month = months[a.getMonth()];
        let month = a.getMonth() + 1;
        let date = a.getDate();
        let hour = a.getHours();
        let min = a.getMinutes();
        let sec = a.getSeconds();
        if (month < 10)
            month = '0' + month
        if (date < 10)
            date = '0' + date
        let time = date + '-' + month + '-' + year + ' ' + hour + ':' + min + ':' + sec;
        return time;
    }

    renderRelatedContentData = ($name, $title = '', $labels = {}) => {

        /** @type {AdminBase} */
        const $app = this.context
        const state = this.state;
        const $rel = state['related']

        const $h = $rel[$name]['data'];
        if (!$h || $h.length === 0) {
            return '';
        }

        let n = 0;

        let columnsRelated = [];
        let dataRelatedEnd = [];
        Object.keys($h).map((k) => {
            let x = 0;
            let dataRelated = {};
            const $record = $h[k];
            n = n + 1;
            let $items = Object.keys($record).map((i) => {
                x = x + 1;
                dataRelated['key'] = n
                let $val = (i === 'created_at' || i === 'updated_at' ? this.timeConverter($record[i]) : $record[i])
                $val = <div dangerouslySetInnerHTML={{__html: $val}}/>
                if ($rel[$name]['summary'] && n === $h.length) {
                    $val = (
                        <strong>
                            {$val}
                        </strong>
                    )
                }

                dataRelated[x] = $val
                if (n === 1) {
                    columnsRelated.push({
                        title: ($labels[i] ? $labels[i] : i),
                        dataIndex: x,
                        key: x,
                    })
                }
                return []
            });
            dataRelatedEnd.push(dataRelated)
            return $items;
        });

        return (
            <>
                <br/>
                <h3>
                    {$title}
                </h3>
                <Table dataSource={dataRelatedEnd} columns={columnsRelated}
                       pagination={false}
                       size={$app.state.siteSize}
                       bordered={true}/>
            </>
        )


    }

    getRelatedContentData = () => {

        const state = this.state;
        const $rel = state.related;

        if (!$rel)
            return '';

        return Object.keys($rel).map((k) => {
            return (
                <div key={k}>
                    {this.renderRelatedContentData(k, $rel[k]['title'], $rel[k]['labels'])}
                </div>
            )
        })
    }

    render() {
        return (
            <div>
                {this.getMainContentData()}
                {this.getRelatedContentData()}
            </div>
        )
    }

}

export default ViewTable;
