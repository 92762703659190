import Component from "@base/Component";
import Index from "@pages/report/Index";


class Report extends Component {

    textTitle = 'Отчеты';
    textInfo = 'Иногда цифры говорят больше, чем самые убедительные слова';
    textHint = 'Нам важно рассказать о том, чем мы занимаемся, ' +
        'и каких результатов добились, ' +
        'чтобы люди осознали: вместе мы можем многое! \n ' +
        'Познакомьтесь с результатами работы нашего Фонда.';


    render() {
        return (
            <div className={'report-page'}>
                {this.renderTitle(this.textTitle, this.textInfo, this.textHint)}
                <Index/>
            </div>
        )
    }

}

export default Report;