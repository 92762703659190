import React from 'react'

const Index = () => {

    return (
        <div>
            Dashboard index page
        </div>
    )

}

export default Index;