import React from 'react'
//
import Table from "../../template/Table";

const Index = () => {

    let $conf = {
        optionFields: ['type'],
    }

    return <Table {...$conf}/>

}

export default Index;