import {Component} from "@base/Base";
import {AppProvider} from "@base/AppContext";
import BaseLayout from '@pages/layout/BaseLayout';
import AdminBase from './admin/AdminBase';
import {createBrowserHistory} from "history";
import {Spin} from 'antd';
import {
    ChromeOutlined
} from '@ant-design/icons';

import {
    BrowserRouter as Router, Route,
} from "react-router-dom";

import 'antd/dist/antd.css';
import './style.less';

class AppBase extends Component {

    data = {
        history: createBrowserHistory(),
        isAdminPanel: window.location.pathname.startsWith('/admin'),
        loaded: false,
        to: '',
        lang: (localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ru'),
        languages: {
            ru: 'Руский',
            en: 'English',
        },
        siteUrl: window.location.hostname,
        pathname: window.location.pathname,
        headerMenu: false,
        bodyParams: {},
        modal: '',
        logoText: 'Мир\n здоровых\n людей',
        beforeFooter: '',
        menuLinks: {
            home: '/',
            wantToHelp: '/want-to-help',
            needHelp: '/need-help',
            projects: '/projects',
            report: '/report',
            about: '/about',
            blog: '/blog',
            ourWards: '/our-wards',
        },
        text: {
            contact: {
                address: 'Адрес:',
                addressV: 'main_address',
                tel: 'Телефон:',
                telV: '+7-930-152-97-42',
                email: 'Почта:',
                emailV: 'help@whp.ru'
            }
        }
    }

    getApiData = () => {
        this.API.get('/site/data', (res) => {
            this.set({
                bulkData: res.bulkData,
                loading: true
            })
        })
    }

    onMount = () => {
        if (!this.data.isAdminPanel && !this.data.loading)
            this.getApiData()
    }

    /**
     * get bulk data
     * @param name
     * @param alternative
     * @return {*[]|*}
     */
    getBulkData = (name, alternative = []) => {

        if (this.data.bulkData && this.data.bulkData[name] && this.data.bulkData[name].items && this.data.bulkData[name].items[this.data.lang]) {

            return this.data.bulkData[name].items[this.data.lang];
        }

        return alternative
    }

    /**
     * get menu items
     * @param $url
     * @return {unknown[]|[{label: *, url: string}, {label: *, url: string}, {label: *, url: string}, {label: *, url: string}, {label: *, url: string}, null, null]}
     */
    getMenuItems = ($url = null) => {

        let items = [
            {
                label: 'Хочу помочь',
                url: this.data.menuLinks.wantToHelp,
            },
            {
                label: 'Нужна помощь',
                url: this.data.menuLinks.needHelp,
            },
            {
                label: 'Программы и проекты',
                url: this.data.menuLinks.projects,
            },
            {
                label: 'Отчеты',
                url: this.data.menuLinks.report,
            },
            {
                label: 'Наши подопечные',
                url: this.data.menuLinks.ourWards,
            },
            {
                label: 'О фонде',
                url: this.data.menuLinks.about,
            },
            {
                label: 'Блог',
                url: this.data.menuLinks.blog,
            }
        ]

        if ($url !== null) {
            return Object.keys(items).map((v, k) => {
                if (items[k].url === $url) {
                    return items[k];
                }
            })
        }

        return items;
    }

    /**
     * switch system language
     */
    switchLanguage = () => {
        this.set({
            lang: (this.data.lang === 'ru' ? 'en' : 'ru'),
        })
        localStorage.setItem('lang', this.data.lang)
    }


    render() {

        const antIcon = <ChromeOutlined style={{fontSize: 34, color: '#e3e3e3'}} spin/>;
        return (
            <>
                <Router>
                    {this.state.isAdminPanel ? <Route path={'/admin'} component={AdminBase}/> :
                        (
                            <div className={(this.data.loaded ? 'fadeIn' : 'fadeOut')}>
                                {this.data.loaded ?
                                    <AppProvider value={this}>
                                        {this.data.to}
                                        {this.data.modal}
                                        <BaseLayout/>
                                    </AppProvider>
                                    : (
                                        <div style={{margin: '0 auto', textAlign: 'center', paddingTop: '15%'}}>
                                            <Spin indicator={antIcon}/>
                                        </div>
                                    )}
                            </div>
                        )
                    }
                </Router>
            </>
        )
    }
}

export default AppBase;