//
import React, {useContext, useState, useEffect} from 'react'
import AppContext from "../../../context/AppContext";
//
import {Switch, Row, Col, Spin} from 'antd';
import {SettingFilled} from '@ant-design/icons';

const Permissions = () => {

    const antIcon = <SettingFilled spin style={{fontSize: '1em', color: '#999'}}/>;
    const $app = useContext(AppContext);
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()


    useEffect(() => {


        $app.content.setTitle(true, true)

        $app.record.getDataByAction(($res) => {
            setLoading(false)
            setData($res)
        })
    }, [$app.record,$app.content])

    if (loading || !data)
        return <Spin spinning={loading} indicator={antIcon}/>;

    const onChange = ($add, $key) => {
        $app.record.postUpdate({
            toggle: ($add ? 1 : 2),
            name: $key,
        })
    }

    let $title = '';

    let $items = Object.keys(data['permissions']['all']).map(($k) => {
        let $checked = (data['permissions']['set'][$k]);
        let $label = data['permissions']['all'][$k]['data']['controllerLabel'];
        let $showTitle = false;
        let $disabled = ($app.user.id === $app.route.recordId || $app.route.recordId === 1);
        if ($title !== $label) {
            $title = $label
            $showTitle = true;
        }
        return (
            <div key={$k}>
                {($showTitle ? (<h4><br/>{$title}
                    <hr/>
                </h4>) : '')}
                <Row>
                    <Col lg={6} xs={24} style={{color: ($checked ? 'green' : 'gray')}}>
                        {data['permissions']['all'][$k]['description']}
                    </Col>
                    <Col lg={6} xs={24}>
                        <Switch disabled={$disabled} defaultChecked={$checked} onChange={(e, c) => onChange(e, $k)}/>
                    </Col>
                </Row>
                <br/>
            </div>
        )
    })

    let $user = (
        <h3 key={'user_info'}>
            Пользователь : {data.user.fullName} [{data.user.id}]
        </h3>
    )

    let $note = (
        <div key={'note'} className=''>
            Разделы и права для кaждого раздела:
        </div>
    )

    return [$user, $note, $items];


}

export default Permissions;