//
import React from 'react'
import AppContext from "../context/AppContext";
//
import {Row, Col, Typography} from 'antd';

// import {FundProjectionScreenOutlined} from '@ant-design/icons';

class BaseLogin extends React.Component {

    static contextType = AppContext

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            remember: true,
            attributes: {email: '', password: ''},
            labels: {
                email: 'Email',
                password: 'Пароль',
            }
        }
    }


    /**
     * @type {function(): AdminBase}
     */
    app = () => {
        return this.context
    }

    /**
     * change loading status
     * @param $loading
     */
    changeLoadingStatus = ($loading) => {
        this.setState({loading: $loading})
    }

    /**
     * process on fail
     * @param $data
     */
    onFinishFailed = ($data) => {
        console.log($data)
    }

    /**
     * render login form
     */

    render = () => {
        let $app = this.app();

        /** @type {InputHelper} */
        const $form = $app.HInput.new(this)

        let $top = (
            <>
                {$form.text('email', {}, {
                    rules: [
                        {required: true, type: 'email', message: ''}
                    ]
                })}
                {$form.password('password')}
            </>
        )

        let $items = $form.renderFieldsByPositions($top, '', '', '', 'Вход')

        let $content = $form.render($items, {
            onFinish: ($data) => {
                this.changeLoadingStatus(true)
                $app.user.auth(Object.assign($data, {remember: this.state.remember}), ($res) => {
                    if (!$res.success) {
                        $app.mes($res.message)
                        this.changeLoadingStatus(false)
                    } else {
                        $app.setState({refresh: true})
                    }
                })
            }
        })

        let $grid = {
            lg: {span: 10, offset: 7},
            md: {span: 12, offset: 6},
            sm: {span: 24},
            xs: {span: 24},
        }

        return (
            <div style={{padding: 20}}>
                <Row>
                    <Col {...$grid}>
                        <br/>
                        <br/>
                        <div className={'content-block-gray'}>
                            <br/>
                            <h1 style={{textAlign: 'center'}}>
                                Авторизация
                            </h1>
                            {$content}
                        </div>
                        <br/>
                        <Typography.Text type={'secondary'}>
                            <div style={{textAlign: 'center'}}>
                                {$app.state.name} ©{new Date().getFullYear()}
                            </div>
                        </Typography.Text>
                    </Col>
                </Row>
            </div>
        )
    }
}


export default BaseLogin;