import Component from "@base/Component";
import CustomTabs from "@base/widgets/CustomTabs";
import {Tabs, Row, Col, Card, Button} from "antd";
import Carousel from "@pages/report/Carousel"

const {TabPane} = Tabs;

class Index extends Component {

    data = {
        tabMenu: '',
        text: {
            view: 'Просмотр',
            download: 'Скачать {size}'
        },
        items: false
    }

    changeCallback = (e) => {

    }

    accessFile = (file) => {
        let f = file;
        window.open(f, '_blank').focus();
    }

    renderItem = (data) => {
        let title = (this.app().data.lang !== 'ru' && data.name_en ? data.name_en : data.title);
        return (
            <Col key={data.id} span={24} md={{span: 24}} lg={{span: 12}}>
                <Card>
                    <Row>
                        <Col xs={{span: 8}} sm={{span: 8}} md={{span: 4}} lg={{span: 6}}>
                            {this.renderImage('/default/pdf.svg')}
                        </Col>
                        <Col xs={{span: 16}} sm={{span: 16}} md={{span: 18}} lg={{span: 18}}>
                            <h3>
                                {title}
                            </h3>
                            <Row>
                                <Col xs={{span: 12}} sm={{span: 12}} md={{span: 6}} lg={{span: 8}}>
                                    <Button
                                        type={'link'}
                                        href={`${data.file}&type=view`}
                                        target={'_blank'}
                                        className={'info-link'}
                                    >
                                        {this.t(this.data.text.view)}
                                    </Button>
                                </Col>
                                <Col xs={{span: 12}} sm={{span: 12}} md={{span: 6}} lg={{span: 6}}>
                                    <Button
                                        type={'link'}
                                        className={'info-link'}
                                        href={data.file + '&title=' + title}
                                        download={title}
                                        target={'_blank'}
                                    >
                                        {this.t(this.data.text.download, {size: data.size})}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Col>
        )
    }

    render() {

        let data = this.data.items

        if (!data)
            return '';

        let years = {};
        data.map((v, k) => {
            if (years[v.year] !== undefined)
                return years[v.year].push(v);
            return years[v.year] = [v];
        })

        let items = [];
        let i = 0;
        if (Object.keys(years).length > 0) {
            Object.keys(years).reverse().map((year, k) => {
                i = i + 1;

                let c = years[year].map((data, index) => this.renderItem(data))

                return items.push({
                    label: year,
                    content: (<Row gutter={35}> {c} </Row>)
                })

            })
        }

        let params = {
            titleClass: 'tac',
            extractMenu: {
                component: this,
                state: 'tabMenu',
            }
        }

        let tab = <CustomTabs data={items} {...params}/>

        return (
            <>
                <br/>
                <br/>
                <div className={'tac'}>
                    {this.data.tabMenu.menu}
                </div>
                <br/>

                {tab}
                {/*<br/>*/}
                {/*<br/>*/}
                {/*<Carousel/>*/}
            </>
        )

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.data.tabMenu === '')
            this.loadData()
    }

    onMount = () => {
        this.loadData();
    }

    loadData = () => {
        let items = this.app().getBulkData('articleYear', false);
        if (items) {
            let data = []
            items.map((v, k) => {
                if (v.files && v.files.length > 0) {
                    v.files.map((file, index) => {
                        if (this.app().data.lang === 'en') {
                            if (!file.name_en || file.name_en.toString().trim() === '')
                                return null
                        }
                        data.push({
                            id: v.id,
                            title: file.name,
                            name_en: file.name_en,
                            size: file.size,
                            year: v.year,
                            file: file.link,
                        })
                    })
                }
            })
            this.set({items: data})
        }
    }
}

export default Index;
