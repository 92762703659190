import React from "react";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {Button, Modal, Spin} from "antd";
import AppContext from "../context/AppContext";

// see installation section above for versions of NPM older than 3.0.0
// If you choose not to use import, you need to assign Cropper to default
// var Cropper = require('react-cropper').default

/**
 https://react-cropper.github.io/react-cropper/?ref=madewithreactjs.com
 */

class CropperWidget extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            cropping: null,
            loaded: false,
            preview: this.props.data.thumb,
            img: '',
            width: this.props.data.width,
            height: this.props.data.height,
            thumb: this.props.data.thumb,
        }
    }

    _crop = () => {
        if (this.state.cropend === null && this.state.cropend === true)
            this.syncPreview()
    }

    onCropend = (cropper) => {
        this.syncPreview()
    }

    /**
     * @param cropper {Cropper}
     */
    onCropperInit = (cropper) => {
        this.cropper = cropper;

        setTimeout(() => {
            this.cropper.setCropBoxData({
                width: this.props.data.width,
                height: this.props.data.height,
            })
            this.setState({loaded: true})
        }, 1500)
    }

    componentDidMount() {
        setTimeout(() => {
            let data = this.props.data;
            if (data && data.img) {
                this.setState({
                    img: data.img
                })
            }
        }, 500)
    }

    syncPreview = () => {
        this.setState({
            preview: this.cropper.getCroppedCanvas().toDataURL(),
            width: this.cropper.getCropBoxData().width,
            height: this.cropper.getCropBoxData().height
        })
    }

    zoomOut = () => {
        this.cropper.zoom(0.05)
        this.syncPreview()
    }
    zoomIn = () => {
        this.cropper.zoom(-0.05)
        this.syncPreview()
    }

    render() {
        let cropper = (
            <div>
                {!this.state.loaded ? <Spin style={{position: 'absolute'}}/> : ''}
                <div style={{opacity: (!this.state.loaded ? 0 : 1)}}>
                    <Button onClick={() => this.zoomOut()}>
                        +
                    </Button>
                    <Button onClick={() => this.zoomIn()}>
                        -
                    </Button>
                    <Cropper
                        src={this.state.img}
                        style={{
                            width: this.props.data.width + 200,
                            height: this.props.data.height + 200
                        }}
                        // fillColor={'#fff'}
                        responsive={true}
                        guides={false}
                        movable={true}
                        scalable={false}
                        zoomable={true}
                        zoomOnWheel={false}
                        zoomOnTouch={false}
                        cropBoxResizable={false}
                        strict={false}
                        dragCrop={true}
                        crop={this._crop.bind(this)}
                        onInitialized={this.onCropperInit.bind(this)}
                        cropend={this.onCropend.bind(this)}
                        // viewMode={3}
                        dragMode={'move'}
                    />
                    <br/>
                    {this.state.width}
                    x
                    {this.state.height}
                    <br/>
                    <img
                        style={{width: this.state.width, height: this.state.height}}
                        src={this.state.preview}/>
                    <br/>
                    <br/>
                    <Button loading={this.state.cropping === true} onClick={() => {
                        this.save()
                    }}>
                        OK
                    </Button>

                    <br/>
                    URL:
                    <br/>
                    {this.props.data.img}
                    <br/>
                    ID:
                    <br/>
                    {this.props.data.id}
                    <br/>
                </div>
            </div>)


        return (
            <div style={{background: '#dedede', border: '1px solid #eee'}}>
                <img
                    src={(this.props.caller.state.imageWasCropped && this.props.caller.state.imageWasCropped[this.state.img] ?
                        this.props.caller.state.imageWasCropped[this.state.img] : (this.state.thumb ? this.state.thumb : this.state.img))}
                    width={this.props.data.width}
                    height={this.props.data.height}
                    style={{
                        width: 'auto',
                        display: 'block',
                        margin: '0 auto',
                    }}
                />
                <br/>
                <br/>
                <Button block type="primary" onClick={() => this.setState({isModalVisible: true})}>
                    Редактирование картинки
                </Button>
                <Modal
                    maskClosable={false}
                    footer={false}
                    title="Редактирование картинки"
                    width={'100%'}
                    visible={this.state.isModalVisible}
                    onCancel={() => this.setState({isModalVisible: false})}
                    onOk={() => false}
                >
                    {cropper}
                </Modal>
            </div>
        )
    }

    save = () => {
        let app = this.context;
        this.setState({cropping: true})
        app.api.post('/file/upload', {
            update: this.props.data.id,
            crop: this.state.preview,
            width: this.props.data.width,
            height: this.props.data.height,
        }, (res) => {
            this.setState({
                cropping: false,
                thumb: res.info.thumb + '&l=' + new Date().getTime(),
            })
            if (this.props.caller) {
                let d = {imageWasCropped: {}}
                d['imageWasCropped'][this.state.img] = res.info.thumb + '&l=' + new Date().getTime()
                this.props.caller.setState(d)
            }
        })
    }

}

export default CropperWidget;
